import {
  SET_CURRENT_SEASON,
  TOGGLE_SIDEMENU,
  SET_CURRENT_CLASSROOM,
  UPDATE_NOTIFICATIONS,
} from "../actions";

const dashboardReducer = (
  state = {
    currentSeason: localStorage.getItem("currentSeason")
      ? JSON.parse(localStorage.getItem("currentSeason"))
      : null,
    currentClassroom: localStorage.getItem("currentClassroom")
      ? JSON.parse(localStorage.getItem("currentClassroom"))
      : null,
    sidemenu: localStorage.getItem("sidemenu")
      ? JSON.parse(localStorage.getItem("sidemenu"))
      : false,
    notifications: localStorage.getItem("notifications")
      ? JSON.parse(localStorage.getItem("notifications"))
      : null,
  },
  action
) => {
  switch (action.type) {
    case SET_CURRENT_SEASON:
      localStorage.setItem("currentSeason", JSON.stringify(action.payload));
      return { ...state, currentSeason: action.payload };
    case SET_CURRENT_CLASSROOM:
      localStorage.setItem("currentClassroom", JSON.stringify(action.payload));
      return { ...state, currentClassroom: action.payload };
    case TOGGLE_SIDEMENU:
      localStorage.setItem("sidemenu", JSON.stringify(action.payload));
      return { ...state, sidemenu: action.payload };
    case UPDATE_NOTIFICATIONS:
      localStorage.setItem("notifications", JSON.stringify(action.payload));
      return { ...state, notifications: action.payload };
    default:
      return state;
  }
};
export default dashboardReducer;
