import React from "react";
import { Link, useLocation } from "react-router-dom";
import styles from "../styles/student/Dashboard.module.css";
import { useSelector } from "react-redux";
import { ADMIN_MENU_ITEMS, STUDENT_MENU_ITEMS } from "../utils/Constants";

function Menu() {
  const { userInfo } = useSelector((state) => state.auth);
  const location = useLocation();

  const menuItems =
    userInfo.user.role === "ADMIN" || userInfo.user.role === "SUPER-ADMIN"
      ? ADMIN_MENU_ITEMS
      : STUDENT_MENU_ITEMS;

  return (
    <div
      style={{
        transition: "all 0.3s",
        width: "100%",
      }}
      className={styles.menu}
    >
      {menuItems.map((item, index) => (
        <Link
          key={index}
          className={
            location.pathname === item.path ||
            (item.path === "/gestion-scolaire/matiere" &&
              (location.pathname === "/gestion-scolaire" ||
                location.pathname === "/gestion-scolaire/matiere"))
              ? styles.active
              : null
          }
          to={item.path}
        >
          {item.icon}
          &nbsp;<p>{item.title || "test"}</p>
        </Link>
      ))}
    </div>
  );
}

export default Menu;
