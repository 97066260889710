import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Navbar from "./Navbar";
import Sidebar from "./Sidebar";
import { Outlet } from "react-router-dom";
import { useTheme } from "@mui/material/styles";
import { drawerWidth } from "../utils/Constants";
import { useLocation } from "react-router-dom";

const Layout = () => {
  const theme = useTheme();
  const [sidemenu, setSidemenu] = useState(false);
  // path
  const location = useLocation();

  const toggleDrawer = () => {
    setSidemenu(!sidemenu);
  };

  const isMobile = window.innerWidth < theme.breakpoints.values.md;

  return (
    <>
      <Navbar toggleDrawer={toggleDrawer} />
      <div
        style={{
          display: "flex",
          marginTop: "50px",
          width: "100%",
        }}
      >
        <Sidebar
          isDrawerOpen={sidemenu}
          toggleDrawer={toggleDrawer}
          isMobile={isMobile}
        />

        <main
          style={{
            flexGrow: 1,
            padding: isMobile ? "16px" : "24px",
            padding:
              location.pathname === "/messenger"
                ? 0
                : isMobile
                ? "16px"
                : "24px",
            transition: "margin 0.3s ease",
            marginLeft: isMobile
              ? 0
              : sidemenu
              ? `${drawerWidth}px`
              : `${drawerWidth}px`,
            overflowX: "hidden",
            overflowY: "auto",
          }}
        >
          <Outlet />
        </main>
      </div>
    </>
  );
};

export default Layout;
