import React from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  IconButton,
  Tooltip,
} from "@mui/material";
import SettingsIcon from "@mui/icons-material/Settings";
import InputIcon from "@mui/icons-material/Input";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";

const ClassroomTable = ({
  classRooms,
  level,
  group,
  setClassroom,
  setAction,
  setFormData,
}) => {
  const dispatch = useDispatch();
  return (
    <TableContainer component={Paper}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Niveau</TableCell>
            <TableCell>Groupe</TableCell>
            <TableCell>Capacité</TableCell>
            <TableCell>Frais</TableCell>
            <TableCell>Étudiants</TableCell>
            <TableCell>Action</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {classRooms
            .filter((x) => (level !== "" ? x.level.includes(level) : x))
            .filter((x) => (group !== "" ? x.group.includes(group) : x))
            .map((classroom) => (
              <TableRow key={classroom._id}>
                <TableCell>{classroom.level}</TableCell>
                <TableCell>{classroom.group}</TableCell>
                <TableCell>{classroom.capacity}</TableCell>
                <TableCell>{classroom.costs} DT</TableCell>
                <TableCell>{classroom.students}</TableCell>
                <TableCell>
                  <Tooltip title="modifier">
                    <IconButton
                      sx={{ color: "primary.main" }}
                      onClick={() => {
                        setClassroom(classroom);
                        setFormData(classroom);
                        setAction("EDIT");
                      }}
                    >
                      <SettingsIcon />
                    </IconButton>
                  </Tooltip>
                  <Link to="/gestion-scolaire/eleves">
                    <Tooltip title="configurer">
                      <IconButton
                        sx={{ color: "#04AA78" }}
                        onClick={() => {
                          dispatch({
                            type: "SET_CURRENT_CLASSROOM",
                            payload: classroom,
                          });
                        }}
                      >
                        <InputIcon />
                      </IconButton>
                    </Tooltip>
                  </Link>
                </TableCell>
              </TableRow>
            ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default ClassroomTable;
