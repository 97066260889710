import { Button, CircularProgress } from "@mui/material";
import React, { useState } from "react";

function AddWithdrawal(props) {
  const [multiple, setMultiple] = useState(false);
  const {
    addWithdrawal,
    setAction,
    withdrawal,
    setWithdrawal,
    resetWithdrawal,
    loadingForm,
  } = props;
  return (
    <form onSubmit={addWithdrawal}>
      {withdrawal.label === "fourniture" ? (
        <>
          <div className="labeledInput">
            <label>objet</label>
            <input
              className="defaultInput"
              type="text"
              required
              value={withdrawal.object}
              onChange={(e) =>
                setWithdrawal({ ...withdrawal, object: e.target.value })
              }
            />
          </div>
          <br />
          <div className="labeledInput">
            <label>date</label>
            <input
              className="defaultInput"
              type="number"
              min={1}
              max={31}
              required
              value={withdrawal.date}
              onChange={(e) =>
                setWithdrawal({ ...withdrawal, date: e.target.value })
              }
            />
          </div>
          <br />
        </>
      ) : null}
      <div className="labeledInput">
        <label>montant</label>
        <input
          className="defaultInput"
          type="number"
          step="any"
          min={1}
          required
          value={withdrawal.amount}
          onChange={(e) =>
            setWithdrawal({ ...withdrawal, amount: e.target.value })
          }
        />
      </div>
      <br />
      <div className="labeledInput">
        <label>modalité de paiement</label>
        <input
          className="defaultInput"
          type="text"
          required
          value={withdrawal.method}
          onChange={(e) =>
            setWithdrawal({ ...withdrawal, method: e.target.value })
          }
        />
      </div>
      <br />
      {withdrawal.label === "fourniture" ? null : (
        <>
          <div className="labeledInput">
            <div
              style={{
                width: "100%",
                display: "flex",
                alignItems: "center",
                justifyContent: "flex-start",
              }}
            >
              <label>multiple ?</label>
              <input
                style={{ marginBottom: "7px", cursor: "pointer" }}
                type="checkbox"
                checked={multiple}
                onChange={(e) => setMultiple(e.target.checked)}
              />
            </div>
            {multiple ? (
              <input
                className="defaultInput"
                type="number"
                min={1}
                required
                value={withdrawal.multiple}
                onChange={(e) =>
                  setWithdrawal({ ...withdrawal, multiple: e.target.value })
                }
              />
            ) : null}
          </div>
          <br />
        </>
      )}
      {loadingForm ? (
        <CircularProgress size="33px" />
      ) : (
        <div className="btnRow">
          <Button type="submit" variant="contained">
            ajouter
          </Button>
          &nbsp;
          <Button
            type="button"
            variant="outlined"
            onClick={() => {
              setAction("");
              resetWithdrawal();
            }}
          >
            annuler
          </Button>
        </div>
      )}
    </form>
  );
}

export default AddWithdrawal;
