import React, { useRef } from "react";
import {
  Box,
  Typography,
  Grid,
  IconButton,
  Divider,
  Avatar,
} from "@mui/material";
import LocalPrintshopIcon from "@mui/icons-material/LocalPrintshop";
import ReactToPrint from "react-to-print";
import moment from "moment";

const Card = ({ student }) => {
  const printRef = useRef();

  const role = student.user.role;

  return (
    <Box ref={printRef} sx={{ p: 2, borderRadius: 2, boxShadow: 3 }}>
      <Box sx={{ display: "flex", justifyContent: "flex-end", mb: 2 }}>
        <ReactToPrint
          trigger={() => (
            <IconButton color="primary">
              <LocalPrintshopIcon />
            </IconButton>
          )}
          content={() => printRef.current}
        />
      </Box>

      {role === "TEACHER" ? (
        <Grid container spacing={2}>
          <Grid item xs={12} sx={{ display: "flex", alignItems: "center" }}>
            <Avatar
              src={student.user.avatar}
              alt="avatar"
              sx={{ width: 80, height: 80, mr: 2 }}
            />
            <Typography variant="h6" component="div">
              Name: <strong>{student.user.name}</strong>
            </Typography>
          </Grid>

          <Grid item xs={12} sm={6}>
            <Typography variant="h6">
              Email: <strong>{student.user.email}</strong>
            </Typography>
            <Typography variant="h6">
              Phone: <strong>{student.user.phone}</strong>
            </Typography>
          </Grid>

          <Grid item xs={12} sm={6}>
            <Typography variant="h6">
              Hourly Rate: <strong>{student.hourlyRate}</strong>
            </Typography>
            <Typography variant="h6">
              Sex: <strong>{student.sex}</strong>
            </Typography>
            <Typography variant="h6">
              Active: <strong>{student.isActive ? "Oui" : "Non"}</strong>
            </Typography>
          </Grid>
        </Grid>
      ) : (
        <Grid container spacing={2}>
          {/* Current student details */}
          <Grid item xs={12} sx={{ display: "flex", alignItems: "center" }}>
            {student?.user?.avatar !== "" ? (
              <Avatar
                src={student.user.avatar}
                alt="avatar"
                sx={{ width: 80, height: 80, mr: 2 }}
              />
            ) : (
              <Avatar
                sx={{
                  width: 50,
                  height: 50,
                  mr: 2,
                  backgroundColor: "#283487",
                  color: "#fff",
                }}
              />
            )}
            <Typography variant="h6" component="div">
              Nom et Prénom: <strong>{student.user.name}</strong>
            </Typography>
          </Grid>

          <Grid item xs={12} sm={6}>
            <Typography variant="h6">
              Date de naissance: <strong>{student.user.birthday}</strong>
            </Typography>
            <Typography variant="h6">
              Sexe: <strong>{student.user.sex}</strong>
            </Typography>
            <Typography variant="h6">
              Adresse: <strong>{student.user.address}</strong>
            </Typography>
          </Grid>

          <Grid item xs={12} sm={6}>
            <Typography variant="h6">
              Classe:{" "}
              <strong>
                {student?.classroom?.level + " " + student?.classroom?.group}
              </strong>
            </Typography>
            <Typography variant="h6">
              Inscrit le:{" "}
              <strong>
                {moment(student.user.createdAt).format("yyyy-MM-DD")}
              </strong>
            </Typography>
            <Typography variant="h6">
              Tarif scolarité par mois:{" "}
              <strong>{student?.classroom?.costs + " dt"}</strong>
            </Typography>
          </Grid>
        </Grid>
      )}

      <Divider sx={{ my: 2 }} />

      {role === "STUDENT" && (
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            <Typography variant="h6">
              Nom père: <strong>{student?.fatherName}</strong>
            </Typography>
            <Typography variant="h6">
              Profession père: <strong>{student?.fatherProfession}</strong>
            </Typography>
            <Typography variant="h6">
              Téléphone père: <strong>{student?.fatherPhone}</strong>
            </Typography>
            <Typography variant="h6">
              Nom mère: <strong>{student?.motherName}</strong>
            </Typography>
            <Typography variant="h6">
              Profession mère: <strong>{student?.motherProfession}</strong>
            </Typography>
            <Typography variant="h6">
              Téléphone mère: <strong>{student?.motherPhone}</strong>
            </Typography>
          </Grid>

          <Grid item xs={12} sm={6}>
            <Typography variant="h6">
              Nb.frères et soeurs: <strong>{student?.siblings}</strong>
            </Typography>
            <Typography variant="h6">
              Nb.frères et soeurs étudiants:{" "}
              <strong>{student.user.siblingsStudents}</strong>
            </Typography>
            <Typography variant="h6">
              Situation familiale: <strong>{student?.maritalStatus}</strong>
            </Typography>
            <Typography variant="h6">
              Sociétés autorisées:{" "}
              {student?.authorizedCompany?.map((name, i) => (
                <strong key={i}>{name},&nbsp;</strong>
              ))}
            </Typography>
            <Typography variant="h6">
              Orphelin: <strong>{student?.orphin ? "Oui" : "Non"}</strong>
            </Typography>
          </Grid>
        </Grid>
      )}
    </Box>
  );
};

export default Card;
