import React from "react";
import { Button, TextField, Box } from "@mui/material";
import RotateLeftIcon from "@mui/icons-material/RotateLeft";
import { useMediaQuery } from "@mui/material";

export default function StudentFilter({ name, setName, phone, setPhone }) {
  const isSmallScreen = useMediaQuery((theme) => theme.breakpoints.down("sm"));
  const resetFilters = () => {
    setName("");
    setPhone("");
  };

  return (
    <Box
      component="section"
      display="flex"
      flexDirection={isSmallScreen ? "column" : "row"}
      alignItems="center"
      gap={2}
      sx={{ marginBottom: "1em" }}
    >
      <TextField
        label="Nom / Prénom"
        value={name}
        onChange={(e) => setName(e.target.value)}
        variant="outlined"
        size="small"
        sx={{
          width: isSmallScreen ? "100%" : "20%",
        }}
      />
      <TextField
        label="Téléphone"
        value={phone}
        onChange={(e) => setPhone(e.target.value)}
        variant="outlined"
        size="small"
        sx={{
          width: isSmallScreen ? "100%" : "20%",
        }}
      />
      {(name || phone) && (
        <Button onClick={resetFilters} size="large" variant="outlined">
          <RotateLeftIcon />
        </Button>
      )}
    </Box>
  );
}
