/* eslint-disable no-unused-vars */
import { lazy } from "react";

// project imports

import Layout from "../layout/Layout";
import GuestGuard from "../utils/route-guard/GuestGuard";

// ==============================|| AUTH ROUTING ||============================== //

const LoginRoutes = {
  path: "/",
  element: (
    <GuestGuard>
      <Layout />
    </GuestGuard>
  ),
  children: [],
};

export default LoginRoutes;
