import React, { useState } from "react";
import { useSelector } from "react-redux";
import Button from "@mui/material/Button";
import Skeleton from "@mui/material/Skeleton";
import Menu from "../../../components/Menu";
import styles from "../../../styles/admin/Schedule.module.css";
import ScheduleTable from "./Table";
import SessionDialog from "./Dialog";
import useStudySchedule from "./Logic";
import Tooltip from "@mui/material/Tooltip";
import EditIcon from "@mui/icons-material/Edit";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import PrintIcon from "@mui/icons-material/Print";
import ReactToPrint from "react-to-print";
import Pagination from "@mui/material/Pagination";
import { TableCell, Box, CircularProgress } from "@mui/material";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import axios from "axios";

import { useSnackbar } from "notistack";
import { getError } from "../../../config/config";

const StudySchedule = () => {
  const { enqueueSnackbar } = useSnackbar();
  const { userInfo } = useSelector((state) => state.auth);
  const { currentClassroom } = useSelector((state) => state.dashboard);
  const [page, setPage] = useState(1);
  const [count, setCount] = useState(1);
  const isStudent = userInfo.user.role === "STUDENT";
  const classroom = !isStudent ? currentClassroom._id : userInfo.classroom;

  const {
    start,
    setStart,
    end,
    setEnd,
    action,
    setAction,
    subjects,
    rooms,
    loading,
    loadingForm,
    session,
    setSession,
    sessions,
    printRef,
    addRow,
    addSession,
    editSession,
    deleteSession,
    loadSessions,
  } = useStudySchedule(userInfo, classroom);

  const renderRow = (row) => {
    let block = [
      <TableCell key={row.timing} style={{ backgroundColor: "#F5F3F3" }}>
        {row.timing}
      </TableCell>,
    ];
    for (let i = 0; i < 6; i++) {
      const session = row.sessions.find((s) => s.dayIndex === i);
      if (session?.room) {
        block = [
          ...block,
          <Tooltip
            key={row.timing + i}
            title={!session.room.available ? "Salle non disponible" : ""}
          >
            <TableCell
              sx={{
                backgroundColor: "#283487",
                color: "#fff",
              }}
              className={styles.cell}
            >
              <p>
                Matière:&nbsp;
                <strong style={{ textTransform: "capitalize" }}>
                  {session.subject?.name || "Non spécifié"}
                </strong>
              </p>
              <p>
                Salle:&nbsp;
                <strong style={{ textTransform: "capitalize" }}>
                  {session.room?.name || "Non spécifié"}
                </strong>
              </p>
              <p>
                Enseignant
                {session.subject?.teacher.sex === "Féminin" ? "e" : ""}:&nbsp;
                <strong style={{ textTransform: "capitalize" }}>
                  {session.subject?.teacher.user.name || "Non spécifié"}
                </strong>
              </p>
              {!isStudent && (
                <>
                  <div className={styles.edit}>
                    <div
                      onClick={() => {
                        setSession(session);
                        setAction("EDIT_SESSION");
                      }}
                      className={styles.icon}
                    >
                      <Tooltip title="Modifier la séance">
                        <EditIcon
                          sx={{
                            width: "15px",
                            height: "15px",
                            color: "orange",
                          }}
                        />
                      </Tooltip>
                    </div>
                  </div>
                  <div className={styles.delete}>
                    <div
                      onClick={() => {
                        setSession(session);
                        setAction("DELETE_SESSION");
                      }}
                      className={styles.icon}
                    >
                      <Tooltip title="Supprimer la séance">
                        <DeleteOutlineIcon
                          sx={{ width: "15px", height: "15px", color: "red" }}
                        />
                      </Tooltip>
                    </div>
                  </div>
                </>
              )}
            </TableCell>
          </Tooltip>,
        ];
      } else {
        block = [
          ...block,
          <TableCell key={i}>
            {!isStudent && (
              <div className={styles.cellContainer}>
                <div className={styles.add}>
                  <div
                    onClick={() => {
                      setSession({
                        ...session,
                        dayIndex: i,
                        timing: row.timing,
                        classroom: currentClassroom._id,
                      });
                      setAction("ADD_SESSION");
                    }}
                    className={styles.icon}
                  >
                    <Tooltip sx={{ color: "green" }} title="Ajouter une séance">
                      <AddCircleOutlineIcon />
                    </Tooltip>
                  </div>
                </div>
              </div>
            )}
          </TableCell>,
        ];
      }
    }

    return block;
  };

  const handleToggleChange = async (session) => {
    console.log("session", session);
    try {
      const { data } = await axios.put(`/session/publish`, session);
      enqueueSnackbar(data.message, { variant: "success" });
      loadSessions();
    } catch (error) {
      enqueueSnackbar(getError(error), { variant: "error" });
    }
  };
  return (
    <>
      {!isStudent && (
        <SessionDialog
          action={action}
          setAction={setAction}
          start={start}
          setStart={setStart}
          end={end}
          setEnd={setEnd}
          addRow={addRow}
          addSession={addSession}
          editSession={editSession}
          deleteSession={deleteSession}
          session={session}
          setSession={setSession}
          subjects={subjects}
          rooms={rooms}
          loadingForm={loadingForm}
          resetSession={() => setSession({})}
        />
      )}
      <Menu />
      <div className={styles.container}>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <div
            style={{
              display: "flex",
              alignItems: "center",
              gap: "1em",
            }}
          >
            <CalendarMonthIcon
              style={{ fontSize: "2.2rem", color: "#283487" }}
            />
            <h3 style={{ fontSize: "2rem", color: "#283487" }}>
              {" "}
              Emploi du temps
            </h3>
          </div>
          {!isStudent && (
            <Box
              style={{
                display: "flex",
                gap: "1em",
                alignItems: "center",
              }}
            >
              <Button
                variant="contained"
                sx={{
                  borderRadius: "0px",
                  background: sessions[0]?.sessions[0]?.isPublished
                    ? "#43A047"
                    : "#FFCA00",
                  "&:hover": {
                    background: sessions[0]?.sessions[0]?.isPublished
                      ? "#2E7D32"
                      : "#FFB300",
                  },
                }}
                onClick={() => {
                  if (sessions[0]?.sessions[0]) {
                    handleToggleChange({
                      ...sessions[0].sessions[0],
                      isPublished: !sessions[0].sessions[0].isPublished,
                    });
                  }
                }}
              >
                {sessions[0]?.sessions[0]?.isPublished
                  ? "Publié"
                  : "Non publié"}
              </Button>
              <Button
                variant="contained"
                color="primary"
                onClick={() => setAction("ADD_ROW")}
              >
                Ajouter
              </Button>
            </Box>
          )}
        </div>

        {/* {!isStudent && (
          <Button
            variant="contained"
            style={{ margin: "10px 0", color: "white" }}
            onClick={publishSession}
          >
            Publier
          </Button>
        )} */}
        {loading ? (
          <div className="spinner">
            <CircularProgress />
          </div>
        ) : (
          <>
            <ScheduleTable
              sessions={sessions}
              renderRow={renderRow}
              printRef={printRef}
            />
            <section
              style={{
                display: "flex",
                justifyContent: "center",
                marginTop: "1em",
              }}
            >
              <Pagination
                onChange={(e, page) => setPage(page)}
                page={page}
                count={count}
                color="primary"
              />
            </section>
            <div
              style={{
                display: "flex",
                justifyContent: "end",
                marginTop: "2em",
              }}
            >
              <ReactToPrint
                trigger={() => (
                  <Button
                    variant="contained"
                    color="primary"
                    startIcon={<PrintIcon />}
                    sx={{
                      backgroundColor: "#283487",
                      color: "#FFF",
                      padding: "0.5em 1em",
                      borderRadius: "0px",
                      textTransform: "none",
                      fontSize: "1rem",
                      fontWeight: "bold",
                    }}
                  >
                    Imprimer
                  </Button>
                )}
                content={() => printRef.current}
              />
            </div>
          </>
        )}
      </div>
    </>
  );
};

export default StudySchedule;
