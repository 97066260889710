import * as React from "react";
import { Link } from "react-router-dom";
import { Container, Typography, Grid, Button } from "@mui/material";

function NotFoundPage() {
  return (
    <Container sx={{ height: "calc(100vh - 95px)" }} maxWidth="sm">
      <Grid
        container
        direction="column"
        justifyContent="center"
        alignItems="center"
        spacing={2}
        sx={{ height: "100%" }}
      >
        <Grid item>
          <img
            src={"/not-found.svg"}
            alt="Not Found"
            style={{
              maxWidth: "200px",
              height: "auto",
            }}
          />
        </Grid>
        <Grid item>
          <Typography
            variant="body1"
            align="center"
            gutterBottom
            sx={{
              fontFamily: "sans-serif",
              fontSize: "1.5rem",
              fontWeight: "bold",
              color: "text.secondary",
            }}
          >
            Oups ! La page que vous recherchez n'existe pas
          </Typography>
        </Grid>
        <Grid item>
          <Button
            component={Link}
            to="/"
            variant="contained"
            sx={{
              color: "white",
              fontFamily: "sans-serif",
              backgroundColor: "black",
              "&:hover": {
                backgroundColor: "text.secondary",
              },
              textTransform: "none",
            }}
          >
            Retour au tableau de bord
          </Button>
        </Grid>
      </Grid>
    </Container>
  );
}

export default NotFoundPage;
