import {
  Button,
  CircularProgress,
  FormControl,
  Grid,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import { IconButton } from "@mui/material";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import { MuiTelInput } from "mui-tel-input";

function AddTeacher(props) {
  const {
    formData,
    setFormData,
    loadingForm,
    setAction,
    addTeacher,
    resetTeacher,
  } = props;
  const [passwordType, setPasswordType] = useState("password");

  return (
    <>
      <Typography
        variant="h6"
        gutterBottom
        sx={{
          color: "#283487",
          fontWeight: "bold",
          textAlign: "center",
          marginBottom: "20px",
        }}
      >
        Ajouter un enseignant
      </Typography>
      <form onSubmit={addTeacher}>
        <Grid container spacing={2}>
          <Grid item xs={12} md={6}>
            <TextField
              label="Nom et prénom"
              required
              fullWidth
              value={formData.name}
              onChange={(e) =>
                setFormData({ ...formData, name: e.target.value })
              }
              type="text"
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <FormControl fullWidth>
              <InputLabel>Sexe</InputLabel>
              <Select
                label="Sexe"
                value={formData.sex}
                required
                onChange={(e) => {
                  setFormData({ ...formData, sex: e.target.value });
                }}
                fullWidth
              >
                <MenuItem value="Masculin">Masculin</MenuItem>
                <MenuItem value="Féminin">Féminin</MenuItem>
              </Select>
            </FormControl>
          </Grid>

          <Grid item xs={12} md={6}>
            <FormControl fullWidth variant="outlined">
              <MuiTelInput
                id="phone"
                label="Téléphone"
                name="phone"
                className="fullwidth"
                focusOnSelectCountry={true}
                defaultCountry="TN"
                value={formData.phone}
                onChange={(value) => setFormData({ ...formData, phone: value })}
              />
            </FormControl>
          </Grid>

          <Grid item xs={12} md={6}>
            <TextField
              label="Email"
              autoComplete="new-password"
              required
              fullWidth
              value={formData.email}
              onChange={(e) =>
                setFormData({ ...formData, email: e.target.value })
              }
              type="email"
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              label="Mot de passe"
              variant="outlined"
              fullWidth
              required
              value={formData.password}
              type={passwordType}
              onChange={(e) =>
                setFormData({ ...formData, password: e.target.value })
              }
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      onClick={() => {
                        setPasswordType((prevType) =>
                          prevType === "password" ? "text" : "password"
                        );
                      }}
                      edge="end"
                    >
                      {passwordType === "password" ? (
                        <RemoveRedEyeIcon />
                      ) : (
                        <VisibilityOffIcon />
                      )}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          </Grid>

          <Grid item xs={12} md={6}>
            <TextField
              label="Taux horaire"
              required
              fullWidth
              value={formData.hourlyRate}
              onChange={(e) =>
                setFormData({ ...formData, hourlyRate: e.target.value })
              }
              type="number"
            />
          </Grid>
          <Grid item xs={12} container justifyContent="flex-end">
            {loadingForm ? (
              <CircularProgress size="35px" />
            ) : (
              <>
                <Button type="submit" variant="contained">
                  Ajouter
                </Button>
                <Button
                  type="button"
                  onClick={() => {
                    setAction("");
                    resetTeacher();
                  }}
                  variant="outlined"
                  style={{ marginLeft: "10px" }}
                >
                  Annuler
                </Button>
              </>
            )}
          </Grid>
        </Grid>
      </form>
    </>
  );
}

export default AddTeacher;
