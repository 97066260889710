import React from "react";
import {
  IconButton,
  Tooltip,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Chip,
} from "@mui/material";
import SettingsIcon from "@mui/icons-material/Settings";
import FindInPageIcon from "@mui/icons-material/FindInPage";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import NotificationsActiveIcon from "@mui/icons-material/NotificationsActive";
import BlockIcon from "@mui/icons-material/Block";
import CheckIcon from "@mui/icons-material/Check";
const TeacherTable = ({
  teachers,
  setTeacher,
  setFormData,
  setAction,
  navigate,
  setNotification,
  notification,
}) => {
  return (
    <TableContainer component={Paper}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Nom et prénom</TableCell>
            <TableCell>Email</TableCell>
            <TableCell>Téléphone</TableCell>
            <TableCell>Taux horaire</TableCell>
            <TableCell>Status</TableCell>
            <TableCell>Action</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {teachers.map((teacher) => (
            <TableRow key={teacher._id}>
              <TableCell>{teacher.user.name}</TableCell>
              <TableCell>{teacher.user.email}</TableCell>
              <TableCell>{teacher.user.phone}</TableCell>
              <TableCell>{teacher.hourlyRate} DT</TableCell>
              <TableCell>
                <Chip
                  label={
                    teacher.isActive
                      ? teacher.sex === "Male"
                        ? "Actif"
                        : "Active"
                      : teacher.sex === "Male"
                      ? "Bloqué"
                      : "Bloquée"
                  }
                  color={teacher.isActive ? "success" : "error"}
                  variant="outlined"
                />
              </TableCell>
              <TableCell style={{ width: "25%" }}>
                <Tooltip title="modifier">
                  <IconButton
                    onClick={() => {
                      setTeacher(teacher);
                      setFormData({
                        ...teacher,
                        ...teacher.user,
                        _id: teacher._id,
                      });
                      setAction("EDIT");
                    }}
                    sx={{ color: " #4CAF50" }}
                  >
                    <SettingsIcon />
                  </IconButton>
                </Tooltip>

                <Tooltip title="fiche">
                  <IconButton
                    onClick={() => {
                      navigate(`/gestion-scolaire/deposit`, {
                        state: { id: teacher._id, userType: "TEATCHER" },
                      });
                    }}
                    sx={{ color: "#2196F3" }}
                  >
                    <FindInPageIcon />
                  </IconButton>
                </Tooltip>
                <Tooltip title="emploi de travail">
                  <IconButton
                    onClick={() => {
                      navigate(
                        `/work-schedule/${teacher?.user?.name}/${teacher._id}`
                      );
                    }}
                    sx={{ color: "#FF9800" }}
                  >
                    <CalendarMonthIcon />
                  </IconButton>
                </Tooltip>
                <Tooltip title="notifier">
                  <IconButton
                    onClick={() => {
                      setTeacher(teacher);
                      setNotification({
                        ...notification,
                        receiver: teacher.user._id,
                      });
                      setAction("NOTIFY");
                    }}
                    sx={{ color: "#FFC107 " }}
                  >
                    <NotificationsActiveIcon />
                  </IconButton>
                </Tooltip>
                {teacher.isActive ? (
                  <Tooltip title="Bloquer">
                    <IconButton
                      onClick={() => {
                        setTeacher(teacher);
                        setAction("BLOCK");
                      }}
                      sx={{ color: "#F44336" }}
                    >
                      <BlockIcon />
                    </IconButton>
                  </Tooltip>
                ) : (
                  <Tooltip title="Débloquer">
                    <IconButton
                      onClick={() => {
                        setTeacher(teacher);
                        setAction("ACTIVATE");
                      }}
                      sx={{ color: "#4CAF50" }}
                    >
                      <CheckIcon />
                    </IconButton>
                  </Tooltip>
                )}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default TeacherTable;
