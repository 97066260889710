import React from "react";
import { Box, TextField, Button, useMediaQuery } from "@mui/material";
import RotateLeftIcon from "@mui/icons-material/RotateLeft";

const TeacherFilter = ({ name, setName, phone, setPhone }) => {
  const isSmallScreen = useMediaQuery((theme) => theme.breakpoints.down("sm"));

  return (
    <Box
      component="section"
      display="flex"
      flexDirection={isSmallScreen ? "column" : "row"}
      alignItems="center"
      gap={2}
      sx={{ marginBottom: "1em" }}
    >
      <TextField
        label="Nom / Prénom"
        value={name}
        onChange={(e) => setName(e.target.value)}
        variant="outlined"
        fullWidth
        size="small"
        sx={{ width: isSmallScreen ? "100%" : "20%" }}
      />
      <TextField
        label="Téléphone"
        value={phone}
        onChange={(e) => setPhone(e.target.value)}
        variant="outlined"
        fullWidth
        size="small"
        sx={{ width: isSmallScreen ? "100%" : "20%" }}
      />
      {(name || phone) && (
        <Button
          onClick={() => {
            setName("");
            setPhone("");
          }}
          size="large"
          variant="outlined"
          startIcon={<RotateLeftIcon />}
        >
          Réinitialiser
        </Button>
      )}
    </Box>
  );
};

export default TeacherFilter;
