import {
  Button,
  CircularProgress,
  Grid,
  TextField,
  Typography,
  FormControl,
  IconButton,
  InputAdornment,
} from "@mui/material";
import React, { useState } from "react";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import { MuiTelInput } from "mui-tel-input";

function EditMember(props) {
  const {
    memberData,
    setMemberData,
    editMember,
    loadingApi,
    setAction,
    resetMemberData,
  } = props;
  const { name, email, phone, password } = memberData;
  const [passwordType, setPasswordType] = useState("password");

  const handlePasswordTypeToggle = () => {
    setPasswordType((prevType) =>
      prevType === "password" ? "text" : "password"
    );
  };

  return (
    <>
      <Typography
        variant="h6"
        gutterBottom
        sx={{
          color: "#283487",
          fontWeight: "bold",
          textAlign: "center",
          marginBottom: "20px",
        }}
      >
        {" "}
        Modifier le membre:&nbsp;{name}{" "}
      </Typography>
      <form onSubmit={editMember}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <TextField
              label="Nom"
              variant="outlined"
              fullWidth
              required
              value={name}
              onChange={(e) => {
                setMemberData({ ...memberData, name: e.target.value });
              }}
            />
          </Grid>

          <Grid item xs={12}>
            <TextField
              label="Email"
              variant="outlined"
              fullWidth
              required
              type="email"
              value={email}
              onChange={(e) => {
                setMemberData({ ...memberData, email: e.target.value });
              }}
            />
          </Grid>

          <Grid item xs={12}>
            <FormControl fullWidth variant="outlined">
              <MuiTelInput
                id="phone"
                name="phone"
                className="fullwidth"
                focusOnSelectCountry={true}
                defaultCountry="TN"
                value={phone}
                onChange={(value) => {
                  setMemberData({ ...memberData, phone: value });
                }}
              />
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <TextField
              label="Mot de passe"
              variant="outlined"
              fullWidth
              required
              value={password}
              type={passwordType}
              onChange={(e) => {
                setMemberData({ ...memberData, password: e.target.value });
              }}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton onClick={handlePasswordTypeToggle} edge="end">
                      {passwordType === "password" ? (
                        <RemoveRedEyeIcon />
                      ) : (
                        <VisibilityOffIcon />
                      )}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          </Grid>

          <Grid
            item
            xs={12}
            container
            spacing={2}
            sx={{
              display: "flex",
              justifyContent: "flex-end",
            }}
          >
            <Grid item>
              <Button type="submit" variant="contained" color="primary">
                {loadingApi ? (
                  <CircularProgress size="20px" sx={{ color: "#fff" }} />
                ) : (
                  "Modifier"
                )}
              </Button>
            </Grid>
            <Grid item>
              <Button
                onClick={() => {
                  setAction("");
                  resetMemberData();
                }}
                variant="outlined"
                color="primary"
              >
                Annuler
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </form>
    </>
  );
}

export default EditMember;
