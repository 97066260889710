import axios from "axios";

export const fetchStudentsAPI = async (establishmentId, page, phone, name) => {
  const { data } = await axios.post(`/student/get`, {
    id: establishmentId,
    page,
    phone,
    name,
  });
  return data;
};

export const fetchClassroomsAPI = async (establishmentId, seasonId) => {
  const { data } = await axios.get(
    `/classroom/get/${establishmentId}/${seasonId}`
  );
  return data;
};

export const addStudentAPI = async (formData) => {
  const { data } = await axios.post("/student/add", formData);
  return data;
};

export const editStudentAPI = async (formData) => {
  const { data } = await axios.put("/student/edit", formData);
  return data;
};

export const activateStudentAPI = async (studentId) => {
  const { data } = await axios.put(`/student/activate/${studentId}`);
  return data;
};

export const blockStudentAPI = async (studentId) => {
  const { data } = await axios.put(`/student/block/${studentId}`);
  return data;
};
