import {
  Button,
  CircularProgress,
  Grid,
  MenuItem,
  Select,
  TextField,
  Typography,
  InputLabel,
  FormControl,
} from "@mui/material";
import { MuiTelInput } from "mui-tel-input";
import React from "react";

function AddEstablishment(props) {
  const {
    formData,
    setFormData,
    addEstablishment,
    loadingApi,
    resetEstablishment,
    setAction,
  } = props;
  const { name, type, address, phone, email } = formData;

  return (
    <>
      <Typography
        variant="h6"
        gutterBottom
        sx={{
          color: "#283487",
          fontWeight: "bold",
          textAlign: "center",
          marginBottom: "20px",
        }}
      >
        Ajouter un établissement
      </Typography>
      <form onSubmit={addEstablishment}>
        <Grid container spacing={2}>
          <Grid item xs={12} md={6}>
            <TextField
              label="Nom"
              variant="outlined"
              fullWidth
              required
              value={name}
              onChange={(e) =>
                setFormData({ ...formData, name: e.target.value })
              }
            />
          </Grid>

          <Grid item xs={12} md={6}>
            <FormControl fullWidth>
              <InputLabel>Type</InputLabel>
              <Select
                label="Type"
                variant="outlined"
                fullWidth
                required
                value={type}
                onChange={(e) =>
                  setFormData({ ...formData, type: e.target.value })
                }
                sx={{
                  "& .MuiOutlinedInput-notchedOutline": {
                    borderRadius: "5px",
                    border: "0.5px solid #283487",
                  },
                }}
              >
                <MenuItem value="ecole-primaire">Ecole primaire</MenuItem>
                <MenuItem value="collège">Collège</MenuItem>
                <MenuItem value="ecole-secondaire">Ecole secondaire</MenuItem>
              </Select>
            </FormControl>
          </Grid>

          <Grid item xs={12} md={6}>
            <TextField
              label="Adresse"
              variant="outlined"
              fullWidth
              required
              value={address}
              onChange={(e) =>
                setFormData({ ...formData, address: e.target.value })
              }
            />
          </Grid>

          <Grid item xs={12} md={6}>
            <TextField
              label="Email"
              variant="outlined"
              fullWidth
              required
              type="email"
              value={email}
              onChange={(e) =>
                setFormData({ ...formData, email: e.target.value })
              }
            />
          </Grid>

          <Grid item xs={12}>
            <FormControl fullWidth variant="outlined">
              <MuiTelInput
                id="phone"
                name="phone"
                className="fullwidth"
                focusOnSelectCountry={true}
                defaultCountry="TN"
                value={phone}
                onChange={(value) => {
                  setFormData({ ...formData, phone: value });
                }}
              />
            </FormControl>
          </Grid>

          <Grid
            item
            xs={12}
            container
            spacing={2}
            sx={{
              display: "flex",
              justifyContent: "flex-end",
            }}
          >
            <Grid item>
              <Button type="submit" variant="contained" color="primary">
                {loadingApi ? (
                  <CircularProgress size="20px" sx={{ color: "#fff" }} />
                ) : (
                  "Ajouter"
                )}
              </Button>
            </Grid>
            <Grid item>
              <Button
                onClick={() => {
                  resetEstablishment();
                  setAction("");
                }}
                variant="outlined"
                color="primary"
              >
                Annuler
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </form>
    </>
  );
}

export default AddEstablishment;
