/* eslint-disable no-unused-vars */
import { lazy } from "react";

import React from "react";
// project imports
import AuthGuard from "../utils/route-guard/AuthGuard";
import Layout from "../layout/Layout";
import NotFoundPage from "../views/pages/notFound";
import Messenger from "../views/pages/messenger/Messenger";
import StudentNotifications from "../views/pages/notifications/StudentNotifications";
import StudentFinance from "../views/pages/finance/StudentFinance";
import ClassroomSubjects from "../views/pages/subjects/Subjects";
import MyNotes from "../views/pages/notes/MyNotes";
import ProfileCard from "../views/pages/profile/ProfileCard";
import Students from "../views/pages/students/Students";
import Teachers from "../views/pages/teachers/Teachers";
import Rooms from "../views/pages/rooms/Rooms";
import WorkSchedule from "../views/pages/workSchedule/WorkSchedule";
import Prestation from "../views/pages/prestation/Prestation";
import Classrooms from "../views/pages/classroom/Classrooms";
import ClassroomStudents from "../views/pages/manageStudents/ClassroomStudents";
import AddStudent from "../views/pages/manageStudents/AddStudent";
import StudentCard from "../views/pages/finance/StudentCard";
import StudentNotes from "../views/pages/notes/StudentNotes";
import StudySchedule from "../views/pages/study-schedule/AdminStudySchedule";
import ExamSchedule from "../views/pages/exam-schedule/ExamAdminSchedule";
import Finance from "../views/pages/finance/AdminFinance";
import Home from "../pages/admin/Home";

// ==============================|| MAIN ROUTING ||============================== //

const MainRoutes = {
  path: "/",
  element: (
    <AuthGuard>
      <Layout />
    </AuthGuard>
  ),

  children: [
    {
      path: "*",
      element: <NotFoundPage />,
    },

    {
      path: "/gestion-scolaire",
      element: <ClassroomSubjects />,
    },

    {
      path: "/gestion-scolaire/matiere",
      element: <ClassroomSubjects />,
    },

    {
      path: "/gestion-scolaire/emploi-du-temps",
      element: <StudySchedule />,
    },

    {
      path: "/gestion-scolaire/emploi-des-examens",
      element: <ExamSchedule />,
    },

    {
      path: "/emploi-du-temps",
      element: <WorkSchedule />,
    },
    {
      path: "/gestion-scolaire/releve-de-notes",
      element: <MyNotes />,
    },

    {
      path: "/notification",
      element: <StudentNotifications />,
    },
    {
      path: "/gestion-financiere",
      element: <Finance />,
    },

    {
      path: "/finance",
      element: <StudentFinance />,
    },

    {
      path: "/messenger",
      element: <Messenger />,
    },

    {
      path: "/profile",
      element: <ProfileCard />,
    },

    {
      path: "/etudiants",
      element: <Students />,
    },
    {
      path: "/enseignants",
      element: <Teachers />,
    },
    {
      path: "/salle",
      element: <Rooms />,
    },

    {
      path: "/work-schedule/:name/:id",
      element: <WorkSchedule />,
    },

    {
      path: "/prestation/:name/:id",
      element: <Prestation />,
    },

    {
      path: "/classes",
      element: <Classrooms />,
    },

    {
      path: "/gestion-scolaire/eleves",
      element: <ClassroomStudents />,
    },

    {
      path: "/gestion-scolaire/eleves/add",
      element: <AddStudent />,
    },

    {
      path: "/gestion-scolaire/deposit",
      element: <StudentCard />,
    },

    {
      path: "/gestion-scolaire/notes",
      element: <StudentNotes />,
    },

    {
      path: "/dashboard",
      element: <Home />,
    },
  ],
};

export default MainRoutes;
