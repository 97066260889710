import React, { useEffect, useState } from "react";
import {
  Button,
  TextField,
  Select,
  MenuItem,
  IconButton,
  CircularProgress,
  Box,
  InputLabel,
  FormControl,
  Avatar,
  FormControlLabel,
  Checkbox,
  Grid,
  Typography,
} from "@mui/material";
import { DesktopDatePicker } from "@mui/x-date-pickers";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import moment from "moment";
import { compressImage, getError } from "../../../config/config";
import { initialStudentData, resetStudentData } from "../../../utils/formUtils";
import axios from "axios";
import { useSnackbar } from "notistack";
import { useSelector } from "react-redux";
import { addStudentFormInputs } from "../../../utils/Constants";
import CloseIcon from "@mui/icons-material/Close";
import { useLocation, useNavigate } from "react-router-dom";
const AddStudent = () => {
  const location = useLocation();
  const student = location.state?.student || {};
  const navigate = useNavigate();
  const { userInfo } = useSelector((state) => state.auth);
  const { currentClassroom } = useSelector((state) => state.dashboard);
  const [formData, setFormData] = useState(
    initialStudentData(userInfo?.establishment?._id, currentClassroom?._id)
  );
  const [loadingForm, setLoadingForm] = useState(false);
  const [isEditMode, setIsEditMode] = useState(false);
  const [passwordType, setPasswordType] = useState("password");
  const { enqueueSnackbar } = useSnackbar();

  const handleInputChange = (field) => (e) =>
    setFormData({ ...formData, [field]: e.target.value });
  const handleDateChange = (date) =>
    setFormData({ ...formData, birthday: moment(date).format("yyyy-MM-DD") });
  const togglePasswordVisibility = () =>
    setPasswordType(passwordType === "password" ? "text" : "password");

  const addStudent = async (e) => {
    e.preventDefault();
    setLoadingForm(true);
    try {
      let response;
      if (isEditMode) {
        response = await axios.put(`/student/edit/`, {
          user: {
            _id: formData.userId,
            name: formData.name,
            email: formData.email,
            phone: formData.phone,
            password: formData.password,
            avatar: formData.avatar,
          },
          student: {
            _id: formData.studentId,
            avatar: formData.avatar,
            sex: formData.sex,
            birthday: formData.birthday,
            address: formData.address,
            fatherName: formData.fatherName,
            motherName: formData.motherName,
            orphin: formData.orphin,
            maritalStatus: formData.maritalStatus,
            fatherPhone: formData.fatherPhone,
            motherPhone: formData.motherPhone,
            motherProfession: formData.motherProfession,
            fatherProfession: formData.fatherProfession,
            siblings: formData.siblings,
            siblingsStudents: formData.siblingsStudents,
            authorizedCompany: formData.authorizedCompany,
          },
        });
      } else {
        response = await axios.post("/student/add", formData);
        setIsEditMode(true);
      }
      enqueueSnackbar(response.data.message, { variant: "success" });
      setFormData((prevData) => ({
        ...prevData,
        ...response.data.user,
      }));
    } catch (error) {
      enqueueSnackbar(getError(error), { variant: "error" });
    } finally {
      setLoadingForm(false);
    }
  };

  useEffect(() => {
    if (Object.keys(student).length) {
      console.log("student", student);
      setIsEditMode(true);
      setFormData({
        userId: student.user._id,
        name: student.user.name,
        email: student.user.email,
        phone: student.user.phone,
        password: student.user.password,
        studentId: student._id,
        sex: student.sex,
        birthday: student.birthday,
        address: student.address,
        fatherName: student.fatherName,
        motherName: student.motherName,
        orphin: student.orphin,
        maritalStatus: student.maritalStatus,
        fatherPhone: student.fatherPhone,
        motherPhone: student.motherPhone,
        motherProfession: student.motherProfession,
        fatherProfession: student.fatherProfession,
        siblings: student.siblings,
        siblingsStudents: student.siblingsStudents,
        authorizedCompany: student.authorizedCompany,
      });
    }
  }, [student]);
  return (
    <Box
      component="section"
      sx={{
        display: "flex",
        flexDirection: "column",
        width: "100%",
      }}
    >
      <Typography
        variant="h6"
        sx={{ fontWeight: "bold", color: "#283487", fontSize: "2rem" }}
      >
        {isEditMode ? "Modifier" : "Ajouter"} un élève
      </Typography>

      <IconButton
        onClick={() => navigate("/gestion-scolaire/eleves/")}
        sx={{
          position: "absolute",
          right: 10,
          color: "#283487",
        }}
      >
        <CloseIcon />
      </IconButton>

      <form onSubmit={addStudent}>
        <Grid container spacing={3}>
          <Grid item xs={12} sx={{ textAlign: "center" }}>
            <Avatar
              src={formData?.avatar || "/icons/student_blue.svg"}
              alt="Avatar"
              sx={{
                margin: "5px auto",

                width: 80,
                height: 80,
                borderRadius: "50%",

                objectFit: "contain",
              }}
            />

            <input
              id="avatar"
              hidden
              type="file"
              accept="image/*"
              onChange={async (e) =>
                setFormData({
                  ...formData,
                  avatar: await compressImage(e.target.files[0]),
                })
              }
            />
            <label htmlFor="avatar">
              <Button
                variant="outlined"
                sx={{
                  color: "#283487",
                  borderColor: "#283487",
                  marginTop: "1em",
                }}
                component="span"
              >
                Upload Avatar
              </Button>
            </label>
          </Grid>

          {addStudentFormInputs.map(({ label, field, type, sm }) => (
            <Grid key={field} item xs={12} sm={sm}>
              <TextField
                label={label}
                variant="outlined"
                fullWidth
                required
                type={type}
                value={formData[field]}
                onChange={handleInputChange(field)}
              />
            </Grid>
          ))}

          <Grid item xs={12} sm={6}>
            <Box sx={{ position: "relative" }}>
              <TextField
                label="Mot de passe"
                variant="outlined"
                fullWidth
                required={isEditMode ? false : true}
                type={passwordType}
                value={formData.password}
                onChange={handleInputChange("password")}
              />
              <IconButton
                onClick={togglePasswordVisibility}
                sx={{
                  position: "absolute",
                  right: 10,
                  top: "50%",
                  transform: "translateY(-50%)",
                }}
              >
                {passwordType === "password" ? (
                  <VisibilityOffIcon />
                ) : (
                  <RemoveRedEyeIcon />
                )}
              </IconButton>
            </Box>
          </Grid>

          <Grid item xs={12} sm={4}>
            <DesktopDatePicker
              label="Date de naissance"
              inputFormat="yyyy-MM-DD"
              value={
                formData.birthday === "yyyy-mm-dd"
                  ? null
                  : moment(formData.birthday).toDate()
              }
              onChange={handleDateChange}
              renderInput={(params) => (
                <TextField {...params} fullWidth required />
              )}
            />
          </Grid>

          <Grid item xs={12} sm={4}>
            <FormControl fullWidth>
              <InputLabel id="sex-label">Sexe</InputLabel>
              <Select
                labelId="sex-label"
                value={formData.sex}
                label="Sexe"
                onChange={handleInputChange("sex")}
              >
                <MenuItem value="Masculin">Masculin</MenuItem>
                <MenuItem value="Féminin">Féminin</MenuItem>
              </Select>
            </FormControl>
          </Grid>

          <Grid item xs={12} sm={4}>
            <FormControl fullWidth>
              <InputLabel id="marital-status-label">État civil</InputLabel>
              <Select
                labelId="marital-status-label"
                value={formData.maritalStatus}
                label="État civil"
                onChange={handleInputChange("maritalStatus")}
              >
                <MenuItem value="Célibataire">Célibataire</MenuItem>
                <MenuItem value="Marié(e)">Marié(e)</MenuItem>
                <MenuItem value="Divorcé(e)">Divorcé(e)</MenuItem>
              </Select>
            </FormControl>
          </Grid>

          <Grid item xs={12} sm={6}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={formData.orphin}
                  onChange={(e) =>
                    setFormData({ ...formData, orphin: e.target.checked })
                  }
                />
              }
              label="Orphelin"
            />
          </Grid>

          <Grid
            container
            justifyContent="flex-end"
            spacing={2}
            sx={{ marginTop: "2em" }}
            xs={12}
            sm={6}
          >
            <Grid item>
              <Button
                type="submit"
                variant="contained"
                color="primary"
                disabled={loadingForm}
                startIcon={loadingForm && <CircularProgress size={20} />}
              >
                {loadingForm
                  ? isEditMode
                    ? "Modification en cours..."
                    : "Ajout en cours..."
                  : isEditMode
                  ? "Modifier"
                  : "Ajouter"}
              </Button>
            </Grid>
            <Grid item>
              <Button
                variant="outlined"
                color="primary"
                onClick={() => resetStudentData(setFormData)}
              >
                Annuler
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </form>
    </Box>
  );
};

export default AddStudent;
