import React from "react";
import Layout from "../components/Layout";
import Banner from "../components/Banner";
import WhyUsSection from "../components/WhyUsSection";
import VideoSection from "../components/VideoSection";
import ContactSection from "../components/ContactSection";

function Home(props) {
  return (
    <Layout>
      <Banner />
      <WhyUsSection />
      <VideoSection />
      <ContactSection />
    </Layout>
  );
}

export default Home;
