import React from "react";
import Slider from "react-slick";
import "./Banner.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Button, Typography } from "@mui/material";

const Banner = () => {
  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2000,
    arrows: false,
  };

  const slides = [
    { img: "/banner1.png", alt: "Image 1" },
    { img: "/banner3.png", alt: "Image 2" },
    { img: "/banner2.png", alt: "Image 3" },
  ];

  return (
    <div className="banner">
      <div className="banner-text" data-aos="fade-up" data-aos-delay="200">
        <Typography variant="h3" className="banner-text-title">
          LA GESTION DES DONNÉES RASSEMBLE À UNE HISTOIRE SANS FIN !
        </Typography>

        <Typography variant="body1" className="banner-text-description">
          CE POURQUOI, NOUS AVONS CRÉÉ SCHOOL-UP.
        </Typography>

        {/*  add yellow div that containe beautiful text */}
        <div
          className="banner-text-yellow"
          data-aos="fade-up"
          data-aos-delay="300"
        >
          <Typography
            variant="body1"
            sx={{
              color: "#1f3c88",
              fontWeight: "bold",
              fontSize: "1.2rem",
            }}
          >
            Schoolup est une application de gestion scolaire
          </Typography>
        </div>
      </div>
      <div className="banner-slider">
        <Slider {...settings}>
          {slides.map((slide, index) => (
            <div key={index}>
              <img src={slide.img} alt={slide.alt} />
            </div>
          ))}
        </Slider>
      </div>
    </div>
  );
};

export default Banner;
