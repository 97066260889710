//const  myBaseUrl = "http://128.199.32.128/";

import moment from "moment";
import InsertDriveFileIcon from "@mui/icons-material/InsertDriveFile";
import AccountBalanceWalletIcon from "@mui/icons-material/AccountBalanceWallet";
import NotificationsActiveIcon from "@mui/icons-material/NotificationsActive";
import MessageIcon from "@mui/icons-material/Message";
import AccountBoxIcon from "@mui/icons-material/AccountBox";
import MeetingRoomIcon from "@mui/icons-material/MeetingRoom";
import PeopleAltIcon from "@mui/icons-material/PeopleAlt";
import SchoolIcon from "@mui/icons-material/School";
import BusinessCenterIcon from "@mui/icons-material/BusinessCenter";
import DashboardIcon from "@mui/icons-material/Dashboard";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import TodayIcon from "@mui/icons-material/Today";
import SquareFootIcon from "@mui/icons-material/SquareFoot";
//const myBaseUrl = "http://localhost:3000/";
export const BASE_URL = process.env.REACT_APP_BASE_URL;
export const IS_TN = process.env.REACT_APP_IS_TN === "true" ?? true;

// theme constant
export const gridSpacing = 3;
export const drawerWidth = 275;
export const appDrawerWidth = 320;

const ALL = "ALL";

// USERS ROLES
const USER = "USER";
const ADMIN = "ADMIN";
const MEMBER = "MEMBER";

// USERS STATUS
const ACTIVE = "ACTIVE";
const BLOCKED = "BLOCKED";
const INACTIVE = "INACTIVE";
const DELETED = "DELETED";

// Frequency

const MONTHLY = "MONTHLY";
const YEARLY = "YEARLY";

// Payment Status
const PAID = "PAID";
const UNPAID = "UNPAID";
const PENDING = "PENDING";
const CANCELED = "CANCELED";

export const PRIMARY_LEVELS = ["1ère", "2ème", "3ème", "4ème", "5ème", "6ème"];
export const COLLEGE_LEVELS = ["7ème", "8ème", "9ème"];
export const SECONDARY_LEVELS = ["1ère", "2ème", "3ème", "4ème"];

// Payment Method
const CASH = "CASH";
const CHEQUE = "CHEQUE";
const BANK_TRANSFER = "BANK_TRANSFER";

// Sale Status
const IN_PROGRESS = "IN_PROGRESS";
const COMPLETED = "COMPLETED";
const CANCELLED = "CANCELLED";

// Salary Status
export const salaryStatus = [
  { id: ALL, name: "All" },
  { id: PAID, name: "Paid" },
  { id: UNPAID, name: "Unpaid" },
];
// Payment Method
export const paymentMethods = [
  { id: CASH, name: "Cash" },
  { id: CHEQUE, name: "Cheque" },
  { id: BANK_TRANSFER, name: "Bank Transfer" },
];

// Payment Status
export const PaymentStatus = [
  { id: ALL, name: "All" },
  { id: ACTIVE, name: "Active" },
  { id: INACTIVE, name: "Inactive" },
];

// Frequency
export const paymentFrequency = [
  { id: MONTHLY, name: "Monthly" },
  { id: YEARLY, name: "Yearly" },
];

export const usersRoles = [
  { id: USER, name: "User" },
  { id: ADMIN, name: "Admin" },
  { id: MEMBER, name: "Agent" },
];

export const usersStatus = [
  { id: ALL, name: "All" },
  { id: ACTIVE, name: "Active" },
  { id: BLOCKED, name: "Blocked" },
  { id: INACTIVE, name: "Inactive" },
  { id: DELETED, name: "Deleted" },
];

export const employeeStatus = [
  { id: ALL, name: "All" },
  { id: "AVAILABLE", name: "Available" },
  { id: "NOT_AVAILABLE", name: "Not Available" },
  { id: "ON_LEAVE", name: "On Leave" },
  { id: "ABSENT", name: "Absent" },
];

export const initialIncome = {
  id: null,
  title: "",
  date: moment().format("YYYY-MM-DD"),
  amount: 0,
  paymentMethod: "CASH",
  description: "",
};

export const initialSalary = {
  id: null,
  employeeId: "",
  Salaries: [
    {
      id: null,
      date: moment().format("YYYY-MM-DD"),
      amount: 0,
      bonus: 0,
      deduction: 0,
    },
  ],
};

export const initialRecurringPayments = {
  id: null,
  title: "",
  date: moment().format("YYYY-MM-DD"),
  amount: 0,
  invoiceNumber: "",
  status: "PAID",
  paymentMethod: "CASH",
  frequency: "MONTHLY",
  status: "ACTIVE",
};
export const initialPurchase = {
  id: null,
  title: "",
  date: moment().format("YYYY-MM-DD"),
  amount: 0,
  invoiceNumber: "",
  status: "PAID",
  paymentMethod: "CASH",
};
export const customerStatus = [
  { id: ALL, name: "All" },
  { id: ACTIVE, name: "Active" },
  { id: BLOCKED, name: "Blocked" },
  { id: INACTIVE, name: "Inactive" },
  { id: DELETED, name: "Deleted" },
];

export const programStatus = [
  { id: ALL, name: "All" },
  { id: ACTIVE, name: "Active" },
  { id: BLOCKED, name: "Blocked" },
  { id: INACTIVE, name: "Inactive" },
  { id: DELETED, name: "Deleted" },
];

export const projectstatus = [
  { id: ALL, name: "All" },
  { id: ACTIVE, name: "Active" },
  { id: BLOCKED, name: "Blocked" },
  { id: INACTIVE, name: "Inactive" },
  { id: DELETED, name: "Deleted" },
];

export const SaleStatus = [
  { id: ALL, name: "All" },
  { id: IN_PROGRESS, name: "In Progress" },
  { id: COMPLETED, name: "Completed" },
  { id: CANCELLED, name: "Cancelled" },
];

export const paymentStatus = [
  { id: ALL, name: "All" },
  { id: PAID, name: "Paied" },
  { id: PENDING, name: "Pending" },
  { id: CANCELED, name: "Canceled" },
];

export const initialEmployee = {
  id: null,
  name: "",
  email: "",
  phone: "",
  jobTitle: "",
  hourlyRate: 0,
  leave: 0,
  status: ACTIVE,
};
export const initialAttendance = {
  id: null,
  from: moment().format("YYYY-MM-DD"),
  to: moment().format("YYYY-MM-DD"),
  employeeId: "",
  type: "LEAVE",
  status: "PRESENT",
  notes: "",
};

export const saleStatus = [
  { id: PAID, name: "Paied" },
  { id: PENDING, name: "Pending" },
  { id: CANCELED, name: "Canceled" },
];

export const attendanceTypes = [
  { id: "LEAVE", name: "Leave" },
  { id: "ABSENCE", name: "Absence" },
  { id: "OVERTIME", name: "Overtime" },
];

export const initialAddress = {
  id: null,
  address: "",
  lat: "",
  lng: "",
};

export const initialImage = {
  id: null,
  filename: "",
  mimetype: "",
  encoding: "",
};

export const initialPayment = {
  id: null,
  date: "",
  amount: 0,
};

export const initialUser = {
  id: null,
  username: "",
  firstName: "",
  lastName: "",
  email: "",
  password: "",
  phone: "",
  role: "",
  status: ACTIVE,
  isVerified: true,
  otpVerified: true,
  image: initialImage,
  address: initialAddress,
};

export const initialCustomer = {
  id: null,
  username: "",
  firstName: "",
  lastName: "",
  email: "",
  phone: "",
  status: ACTIVE,
  image: initialImage,
  project: [],
};
export const initialDeveloper = {
  id: null,
  username: "",
  firstName: "",
  lastName: "",
  email: "",
  phone: "",
  status: ACTIVE,
  image: initialImage,
};

export const initialProgram = {
  id: null,
  name: "",
  type: "",
  developer: "",
  details: "",
  totalPrice: 0,
  status: ACTIVE,
  image: initialImage,
};

export const initialProject = {
  id: null,
  name: "",
  type: "",
  program: "",
  price: 0,
  details: "",
  status: ACTIVE,
  image: initialImage,
};

export const initialSale = {
  id: null,
  date: moment().format("YYYY-MM-DDTHH:MM"),
  reference: "",
  customerId: "",
  projectId: "",
  status: IN_PROGRESS,
  PaymentMethod: [initialPayment],
};

export const initialProfile = {
  id: null,
  username: "",
  firstName: "",
  lastName: "",
  email: "",
  phone: "",
  image: initialImage,
  address: initialAddress,
  changePassword: false,
  newPassword: "",
};

export const initialExpense = {
  id: null,
  type: "OUT",
  date: moment().format("YYYY-MM-DDTHH:MM"),
  amount: 0,
  notes: "",
  transactionId: "",
  userId: "0",
  status: "PAID",
  username: "",
};

export const encodeUserNameAndPass = (userName, password) => {
  var decodedStringBtoA = `[userName]${userName}[password]${password}`;
  return btoa(decodedStringBtoA);
};

export const decodeUserNameAndPass = (str) => {
  return atob(str);
};

export const fileSupportedFormats = [
  "image/JPEG",
  "image/JPG",
  "image/jpg",
  "image/png",
  "image/PNG",
  "image/jpeg",
];

export const arabicMonths = [
  "يناير",
  "فبراير",
  "مارس",
  "ابريل",
  "مايو",
  "يونيو",
  "يوليو",
  "اغسطس",
  "سبتمبر",
  "اكتوبر",
  "نوفمبر",
  "ديسمبر",
];

export const englishMonths = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

export const months = [
  "JAN",
  "FÉV",
  "MAR",
  "AVR",
  "MAI",
  "JUIN",
  "JUIL",
  "AOÛT",
  "SEPT",
  "OCT",
  "NOV",
  "DÉC",
];

export const arabicDays = [
  "الاحد",
  "الاثنين",
  "الثلاثاء",
  "الاربعاء",
  "الخميس",
  "الجمعة",
  "السبت",
];

export const ADMIN_ITEMS = [
  { text: "Dashboard", path: "/dashboard", icon: <DashboardIcon /> },
  { text: "Classes", path: "/classes", icon: <SchoolIcon /> },
  { text: "Étudiants", path: "/etudiants", icon: <PeopleAltIcon /> },
  { text: "Enseignants", path: "/enseignants", icon: <BusinessCenterIcon /> },
  { text: "Salle", path: "/salle", icon: <MeetingRoomIcon /> },
  {
    text: "Gestion Financière",
    path: "/gestion-financiere",
    icon: <AccountBalanceWalletIcon />,
  },
  {
    text: "Notification",
    path: "/notification",
    icon: <NotificationsActiveIcon />,
  },
  { text: "Messenger", path: "/messenger", icon: <MessageIcon /> },
];

export const STUDENT_ITEMS = [
  {
    text: "Gestion Scolaire",
    path: "/gestion-scolaire",
    icon: <InsertDriveFileIcon />,
  },
  {
    text: "Gestion Financière",
    path: "/finance",
    icon: <AccountBalanceWalletIcon />,
  },
  {
    text: "Notification",
    path: "/notification",
    icon: <NotificationsActiveIcon />,
  },
  { text: "Messenger", path: "/messenger", icon: <MessageIcon /> },

  { text: "Profile", path: "/profile", icon: <AccountBoxIcon /> },
];

export const TEACHER_ITEMS = [
  {
    text: "Emploi du temps",
    path: "/emploi-du-temps",
    icon: <CalendarMonthIcon />,
  },
  {
    text: "Gestion Financière",
    path: "/finance",
    icon: <AccountBalanceWalletIcon />,
  },
  {
    text: "Notification",
    path: "/notification",
    icon: <NotificationsActiveIcon />,
  },
  //{ text: "Profile", path: "/profile", icon: <AccountBoxIcon /> },
];

export const STUDENT_MENU_ITEMS = [
  {
    path: "/gestion-scolaire/matiere",
    icon: <SquareFootIcon />,
    title: "Liste des matières",
  },
  {
    path: "/gestion-scolaire/emploi-du-temps",
    icon: <CalendarMonthIcon />,
    title: "Emploi de temps",
  },
  {
    path: "/gestion-scolaire/emploi-des-examens",
    icon: <TodayIcon />,
    title: "Emploi des examens",
  },
  {
    path: "/gestion-scolaire/releve-de-notes",
    icon: <TodayIcon />,
    title: "Relevés des notes",
  },
];

export const ADMIN_MENU_ITEMS = [
  {
    path: "/gestion-scolaire/eleves",
    icon: <PeopleAltIcon />,
    title: "Liste des élèves",
  },
  {
    path: "/gestion-scolaire/matiere",
    icon: <SquareFootIcon />,
    title: "Liste des matières",
  },
  {
    path: "/gestion-scolaire/emploi-du-temps",
    icon: <CalendarMonthIcon />,
    title: "Emploi du temps",
  },
  {
    path: "/gestion-scolaire/emploi-des-examens",
    icon: <TodayIcon />,
    title: "Emploi des examens",
  },
];

export const addStudentFormInputs = [
  { label: "Nom et prénom", field: "name", type: "text", sm: 4 },
  { label: "Email", field: "email", type: "email", sm: 4 },
  { label: "Téléphone", field: "phone", type: "text", sm: 4 },
  { label: "Adresse", field: "address", type: "text", sm: 4 },
  {
    label: "Nom et prénom de père",
    field: "fatherName",
    type: "text",
    sm: 4,
  },
  {
    label: "Profession de père",
    field: "fatherProfession",
    type: "text",
    sm: 4,
  },
  {
    label: "Téléphone de père",
    field: "fatherPhone",
    type: "text",
    sm: 4,
  },
  {
    label: "Nom et prénom de mère",
    field: "motherName",
    type: "text",
    sm: 4,
  },
  {
    label: "Profession de mère",
    field: "motherProfession",
    type: "text",
    sm: 4,
  },
  {
    label: "Téléphone de mère",
    field: "motherPhone",
    type: "text",
    sm: 6,
  },
  {
    label: "Nombre de frères et sœurs",
    field: "siblings",
    type: "number",
    sm: 6,
  },
  {
    label: "Nombre de frères et sœurs étudiants",
    field: "siblingsStudents",
    type: "number",
    sm: 6,
  },
];
