import React from "react";
import { Dialog } from "@mui/material";
import AddStudent from "./AddStudent";
import EditStudent from "./EditStudent";
import { resetFormData } from "../../../utils/formUtils";
import StudentAction from "./StudentAction";

const StudentDialog = ({
  action,
  setAction,
  formData,
  setFormData,
  loadingForm,
  student,
  addStudent,
  editStudent,
  blockStudent,
  activateStudent,
  userInfo,
  classrooms,
  currentSeason,
}) => {
  console.log("classrooms", classrooms);
  return (
    <Dialog
      maxWidth="md"
      open={action !== ""}
      onClose={() => resetFormData(setFormData, userInfo.establishment._id)}
    >
      <div
        className={
          action === "BLOCK" || action === "ACTIVATE"
            ? "modal small"
            : "modal medium"
        }
      >
        {action === "ADD" && (
          <AddStudent
            loadingForm={loadingForm}
            formData={formData}
            setFormData={setFormData}
            setAction={setAction}
            addStudent={addStudent}
            establishment={userInfo.establishment}
            classrooms={classrooms}
          />
        )}
        {action === "EDIT" && (
          <EditStudent
            loadingForm={loadingForm}
            formData={formData}
            setFormData={setFormData}
            setAction={setAction}
            student={student}
            editStudent={editStudent}
            establishment={userInfo.establishment}
            classrooms={classrooms}
            currentSeason={currentSeason}
          />
        )}
        {(action === "BLOCK" || action === "ACTIVATE") && (
          <StudentAction
            actionType={action}
            loadingForm={loadingForm}
            setAction={setAction}
            handleAction={action === "BLOCK" ? blockStudent : activateStudent}
            student={student}
          />
        )}
      </div>
    </Dialog>
  );
};

export default StudentDialog;
