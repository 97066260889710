import {
  Box,
  Button,
  CircularProgress,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import React from "react";

function RoomForm({
  formData,
  setFormData,
  loadingForm,
  handleSubmit,
  actionLabel,
  setAction,
  resetRoom,
  roomName = "",
}) {
  return (
    <Box
      sx={{
        margin: "2em",
      }}
    >
      <Typography
        variant="h6"
        gutterBottom
        sx={{
          color: "#283487",
          fontWeight: "bold",
          textAlign: "center",
          marginBottom: "20px",
        }}
      >
        {actionLabel} {roomName}
      </Typography>
      <form onSubmit={handleSubmit}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <TextField
              label="Nom"
              required
              fullWidth
              value={formData.name}
              onChange={(e) =>
                setFormData({ ...formData, name: e.target.value })
              }
              type="text"
            />
          </Grid>
          <Grid item xs={12}>
            <FormControl fullWidth>
              <InputLabel>Type</InputLabel>
              <Select
                label="Type"
                value={formData.type}
                required
                onChange={(e) => {
                  setFormData({ ...formData, type: e.target.value });
                }}
                sx={{
                  width: "100%",
                }}
              >
                <MenuItem value="TP">TP</MenuItem>
                <MenuItem value="SALLE">SALLE</MenuItem>
                <MenuItem value="AMPHI">AMPHI</MenuItem>
              </Select>
            </FormControl>
          </Grid>

          <Grid item xs={12}>
            <TextField
              label="Capacité"
              required
              fullWidth
              value={formData.spots}
              onChange={(e) =>
                setFormData({ ...formData, spots: e.target.value })
              }
              type="number"
            />
          </Grid>

          <Grid item xs={12}>
            <FormControl fullWidth>
              <InputLabel>Disponibilité</InputLabel>
              <Select
                label="Disponibilité"
                value={formData.available}
                required
                onChange={(e) => {
                  setFormData({ ...formData, available: e.target.value });
                }}
                sx={{
                  width: "100%",
                }}
              >
                <MenuItem value={true}>Oui</MenuItem>
                <MenuItem value={false}>Non</MenuItem>
              </Select>
            </FormControl>
          </Grid>

          <Grid item xs={12} container justifyContent="flex-end">
            {loadingForm ? (
              <CircularProgress size="35px" />
            ) : (
              <>
                <Button type="submit" variant="contained">
                  {actionLabel}
                </Button>
                <Button
                  type="button"
                  onClick={() => {
                    setAction("");
                    resetRoom();
                  }}
                  variant="outlined"
                  style={{ marginLeft: "10px" }}
                >
                  Annuler
                </Button>
              </>
            )}
          </Grid>
        </Grid>
      </form>
    </Box>
  );
}

export default RoomForm;
