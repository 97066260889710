import React from "react";
import { AppBar, Toolbar, IconButton, Box, Button } from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

const Navbar = ({ toggleDrawer }) => {
  const dispatch = useDispatch();
  const Navigate = useNavigate();

  return (
    <AppBar
      position="fixed"
      sx={{
        backgroundColor: "#283487",
        zIndex: (theme) => theme.zIndex.drawer + 1,
      }}
    >
      <Toolbar>
        <IconButton color="inherit" edge="start" onClick={toggleDrawer}>
          <MenuIcon />
        </IconButton>

        <img src="/WhiteLogo.svg" style={{ width: "150px" }} />

        <Box sx={{ flexGrow: 1 }} />
        <Button
          color="inherit"
          variant="outlined"
          sx={{
            backgroundColor: "#fff",
            color: "#283487",
            border: "none",
            borderRadius: "0px",

            "&:hover": {
              backgroundColor: "#FF2B5E",
              color: "#fff",
            },
          }}
          onClick={() => {
            dispatch({ type: "USER_LOGOUT" });
            Navigate("/login");
          }}
        >
          Déconnexion
        </Button>
      </Toolbar>
    </AppBar>
  );
};

export default Navbar;
