import React, { useState, useEffect } from "react";
import {
  Button,
  CircularProgress,
  Dialog,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  Pagination,
} from "@mui/material";
import { useSnackbar } from "notistack";
import { useSelector } from "react-redux";
import axios from "axios";

import Menu from "../../../components/Menu";
import TopicIcon from "@mui/icons-material/Topic";
import Add from "./Add";
import Edit from "./Edit";
import { getError } from "../../../config/config";
import { useNavigate } from "react-router-dom";

function ClassroomSubjects() {
  const { enqueueSnackbar } = useSnackbar();
  const { userInfo } = useSelector((state) => state.auth);
  const navigate = useNavigate();
  const [loadingForm, setLoadingForm] = useState(false);
  const [open, setOpen] = useState(false);
  const { currentClassroom } = useSelector((state) => state.dashboard);

  const [page, setPage] = useState(1);
  const [count, setCount] = useState(1);
  const [name, setName] = useState("");
  const [loading, setLoading] = useState(true);
  const [subjects, setSubjects] = useState([]);
  const [teachers, setTeachers] = useState([]);

  const [action, setAction] = useState("");

  useEffect(() => {
    fetchSubjects(userInfo.classroom?._id || currentClassroom);
    fetchTeachers(userInfo.establishment._id);
  }, [page, name]);

  const fetchSubjects = async () => {
    try {
      const { data } = await axios.post(`/subject/getbyclassroom`, {
        classroom: userInfo.classroom || currentClassroom,
        page: page,
        name: name,
      });
      setSubjects(data.subjects);
      setCount(data.count);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      enqueueSnackbar(getError(error), { variant: "error" });
    }
  };

  const fetchTeachers = async (establishmentId) => {
    try {
      const { data } = await axios.get(
        `/teacher/getbyestablishment/${establishmentId}`
      );
      setTeachers(data);
    } catch (error) {
      enqueueSnackbar(getError(error), { variant: "error" });
    }
  };

  const [formData, setFormData] = useState({
    classroom: currentClassroom?._id,
    name: "",
    coefficient: "",
    teacher: "",
  });

  const resetForm = () => {
    setFormData({
      classroom: currentClassroom?._id,
      name: "",
      coefficient: "",
      teacher: "",
    });
  };

  const addSubject = async (e) => {
    e.preventDefault();
    setLoadingForm(true);
    try {
      const { data } = await axios.post("/subject/add", formData);
      enqueueSnackbar(data.message, { variant: "success" });
      resetForm();
      setLoadingForm(false);
      fetchSubjects();
      setAction("");
    } catch (error) {
      enqueueSnackbar(getError(error), { variant: "error" });
      setLoadingForm(false);
    }
  };

  const editSubject = async (e) => {
    e.preventDefault();
    setLoadingForm(true);
    try {
      const { data } = await axios.put("/subject/edit", formData);
      enqueueSnackbar(data.message, { variant: "success" });
      resetForm();
      setLoadingForm(false);
      fetchSubjects();
      setAction("");
    } catch (error) {
      enqueueSnackbar(getError(error), { variant: "error" });
      setLoadingForm(false);
    }
  };

  const renderTable = () =>
    loading ? (
      <div
        style={{ display: "flex", justifyContent: "center", marginTop: "2em" }}
      >
        <CircularProgress />
      </div>
    ) : (
      <TableContainer
        component={Paper}
        style={{ maxHeight: "calc(100vh - 401px)" }}
      >
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Nom</TableCell>
              <TableCell>Coefficient</TableCell>
              <TableCell>Enseignant</TableCell>
              {userInfo.user.role !== "STUDENT" && (
                <TableCell>Actions</TableCell>
              )}
            </TableRow>
          </TableHead>
          <TableBody>
            {subjects.map((subject) => (
              <TableRow key={subject._id}>
                <TableCell>{subject.name}</TableCell>
                <TableCell>{subject.coefficient}</TableCell>
                <TableCell>
                  {subject.teacher?.user?.name || "Non affecté"}
                </TableCell>
                {userInfo.user.role !== "STUDENT" && (
                  <TableCell>
                    <Button
                      onClick={() => {
                        setFormData(subject);
                        setAction("EDIT");
                      }}
                    >
                      Edit
                    </Button>
                  </TableCell>
                )}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    );

  const renderAdminUI = () => (
    <>
      <Dialog
        maxWidth="md"
        open={action !== ""}
        onClose={() => {
          setAction("");
          resetForm();
        }}
      >
        <div style={{ top: "10vh", maxHeight: "100%" }}>
          {action === "ADD" ? (
            <Add
              formData={formData}
              setFormData={setFormData}
              setAction={setAction}
              teachers={teachers}
              addSubject={addSubject}
            />
          ) : action === "EDIT" ? (
            <Edit
              formData={formData}
              setFormData={setFormData}
              setAction={setAction}
              teachers={teachers}
              editSubject={editSubject}
            />
          ) : null}
        </div>
      </Dialog>
    </>
  );

  return userInfo.user.role === "STUDENT" ? (
    <>
      <Menu />
      <div style={{ padding: "1em" }}>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <div
            style={{
              display: "flex",
              alignItems: "center",
              gap: "1em",
            }}
          >
            <TopicIcon style={{ fontSize: "2.2rem", color: "#283487" }} />
            <h3 style={{ fontSize: "2rem", color: "#283487" }}>
              {" "}
              Liste des matiere
            </h3>
          </div>
        </div>
        {renderTable()}
        <section
          style={{
            display: "flex",
            justifyContent: "center",
            marginTop: "1em",
          }}
        >
          <Pagination
            onChange={(e, page) => setPage(page)}
            page={page}
            count={count}
            color="primary"
          />
        </section>
      </div>
    </>
  ) : (
    <>
      <Menu />
      <div style={{ marginTop: "50px" }}>
        <section>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <div
              style={{
                display: "flex",
                alignItems: "center",
                gap: "1em",
              }}
            >
              <TopicIcon style={{ fontSize: "2.2rem", color: "#283487" }} />
              <h3 style={{ fontSize: "2rem", color: "#283487" }}>
                {" "}
                Liste des matières
              </h3>
            </div>
            <Button
              variant="contained"
              color="primary"
              onClick={() => setAction("ADD")}
            >
              Ajouter
            </Button>
          </div>
          {renderAdminUI()}
        </section>
        {renderTable()}
        <section
          style={{
            display: "flex",
            justifyContent: "center",
            marginTop: "1em",
          }}
        >
          <Pagination
            onChange={(e, page) => setPage(page)}
            page={page}
            count={count}
            color="primary"
          />
        </section>
      </div>
    </>
  );
}

export default ClassroomSubjects;
