import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import EditIcon from "@mui/icons-material/Edit";
import LocalPrintshopIcon from "@mui/icons-material/LocalPrintshop";
import {
  CircularProgress,
  IconButton,
  Dialog,
  Tooltip,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from "@mui/material";
import moment from "moment";
import { useSnackbar } from "notistack";
import React, { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import ReactToPrint from "react-to-print";
import AddWithdrawal from "./AddWithdrawal";
import AddRow from "./AddRow";
import DeleteWithdrawal from "./DeleteWithdrawal";
import EditWithdrawal from "./EditWithdrawal";
import Receipt from "./Receipt";
import {
  fetchTeacher,
  fetchWithdrawals,
  addWithdrawal,
  editWithdrawal,
  deleteWithdrawal,
} from "../../../Api/prestationAPI";

function TeacherCard(props) {
  const { enqueueSnackbar } = useSnackbar();
  const { id } = useParams();
  const printRef = useRef();
  const { userInfo } = useSelector((state) => state.auth);
  const { currentSeason } = useSelector((state) => state.dashboard);
  const [teacher, setTeacher] = useState({});
  const [withdrawals, setWithdrawals] = useState([]);
  const [loadingCard, setLoadingCard] = useState(true);
  const [loadingForm, setLoadingForm] = useState(false);
  const [loadingWithdrawals, setLoadingWithdrawals] = useState(true);
  const [label, setLabel] = useState("");
  const [action, setAction] = useState("");

  const [withdrawal, setWithdrawal] = useState({
    establishment: userInfo.establishment._id,
    season: "",
    admin: userInfo._id,
    teacher: id,
    label: "",
    monthIndex: 0,
    amount: 0,
    method: "",
    multiple: 0,
  });

  const addRow = (e) => {
    e.preventDefault();
    setLoadingForm(true);
    try {
      setWithdrawals([
        ...withdrawals,
        {
          label: label,
          withdrawals: Array.from({ length: 12 }, (_, i) => ({
            monthIndex: i,
          })),
        },
      ]);
      setAction("");
      setLabel("");
      setLoadingForm(false);
    } catch (error) {
      console.error(error);
    }
  };

  const resetWithdrawal = () => {
    setWithdrawal({
      establishment: userInfo.establishment._id,
      season: currentSeason._id,
      admin: userInfo._id,
      teacher: id,
      label: "",
      monthIndex: 0,
      amount: 0,
      method: "",
      multiple: 0,
    });
  };

  const renderWithdrawals = (row) => {
    return Array.from({ length: 12 }, (_, i) => {
      const withdrawalForMonth = row.withdrawals.find(
        (w) => w.monthIndex === i
      );
      return (
        <TableCell key={i} align="center">
          {withdrawalForMonth ? (
            <Tooltip title="Print receipt">
              <div>
                <Typography variant="body2">
                  {withdrawalForMonth.amount} DT
                </Typography>
                <Typography variant="body2">
                  {withdrawalForMonth.method}
                </Typography>
                <div>
                  <IconButton
                    onClick={(e) => {
                      e.stopPropagation();
                      setWithdrawal(withdrawalForMonth);
                      setAction("EDIT_WITHDRAWAL");
                    }}
                    sx={{ color: "orange" }}
                  >
                    <EditIcon />
                  </IconButton>
                  <IconButton
                    onClick={(e) => {
                      e.stopPropagation();
                      setWithdrawal(withdrawalForMonth);
                      setAction("DELETE_WITHDRAWAL");
                    }}
                    sx={{ color: "red" }}
                  >
                    <DeleteOutlineIcon />
                  </IconButton>
                </div>
              </div>
            </Tooltip>
          ) : (
            <IconButton
              onClick={() => {
                setWithdrawal({
                  ...withdrawal,
                  season: currentSeason._id,
                  label: row.label,
                  monthIndex: i,
                });
                setAction("ADD_WITHDRAWAL");
              }}
              sx={{ color: "green" }}
            >
              <AddCircleOutlineIcon />
            </IconButton>
          )}
        </TableCell>
      );
    });
  };

  useEffect(() => {
    fetchTeacher({ id, setLoadingCard, setTeacher, enqueueSnackbar });
  }, [id, enqueueSnackbar]);

  useEffect(() => {
    fetchWithdrawals({
      id,
      setLoadingWithdrawals,
      setWithdrawals,
      enqueueSnackbar,
      userInfo,
      currentSeason,
    });
  }, [id, currentSeason, enqueueSnackbar, userInfo]);

  const e = {
    setLoadingForm,
    enqueueSnackbar,
    userInfo,
    currentSeason,
    withdrawal,
    setWithdrawal,
    withdrawals,
  };

  return (
    <>
      <Dialog
        open={action !== ""}
        onClose={() => {
          setAction("");
          setLabel("");
          resetWithdrawal();
        }}
      >
        <div
          className={action === "SHOW_RECEIPT" ? "modal medium" : "modal small"}
          style={
            action === "ADD_WITHDRAWAL" || action === "EDIT_WITHDRAWAL"
              ? { maxHeight: "100%" }
              : null
          }
        >
          {action === "ADD_ROW" ? (
            <AddRow
              setAction={setAction}
              label={label}
              setLabel={setLabel}
              addRow={addRow}
              loadingForm={loadingForm}
            />
          ) : action === "ADD_WITHDRAWAL" ? (
            <AddWithdrawal
              setAction={setAction}
              withdrawal={withdrawal}
              setWithdrawal={setWithdrawal}
              resetWithdrawal={resetWithdrawal}
              addWithdrawal={addWithdrawal({
                e,
                withdrawal,
                setAction,
                resetWithdrawal,
                enqueueSnackbar,
                withdrawals,
              })}
              loadingForm={loadingForm}
            />
          ) : action === "EDIT_WITHDRAWAL" ? (
            <EditWithdrawal
              setAction={setAction}
              withdrawal={withdrawal}
              setWithdrawal={setWithdrawal}
              resetWithdrawal={resetWithdrawal}
              editWithdrawal={editWithdrawal({
                e,
                withdrawal,
                setAction,
                resetWithdrawal,
                enqueueSnackbar,
              })}
              loadingForm={loadingForm}
            />
          ) : action === "DELETE_WITHDRAWAL" ? (
            <DeleteWithdrawal
              setAction={setAction}
              withdrawal={withdrawal}
              setWithdrawal={setWithdrawal}
              resetWithdrawal={resetWithdrawal}
              deleteWithdrawal={deleteWithdrawal({
                e,
                withdrawal,
                setAction,
                resetWithdrawal,
                enqueueSnackbar,
              })}
              loadingForm={loadingForm}
            />
          ) : action === "SHOW_RECEIPT" ? (
            <Receipt
              setAction={setAction}
              setWithdrawal={setWithdrawal}
              withdrawal={withdrawal}
              resetWithdrawal={resetWithdrawal}
              teacher={teacher}
            />
          ) : null}
        </div>
      </Dialog>

      <div>
        <div className="dontprint">
          <IconButton sx={{ color: "#ff9484" }}>
            <ReactToPrint
              trigger={() => (
                <LocalPrintshopIcon style={{ cursor: "pointer" }} />
              )}
              content={() => printRef.current}
            />
          </IconButton>
        </div>
        {loadingCard ? (
          <div className="spinner">
            <CircularProgress size="30px" />
          </div>
        ) : (
          <TableContainer component={Paper} ref={printRef}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell colSpan={2}>
                    <Typography variant="h6">Teacher Details</Typography>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>
                    <Typography variant="body1">
                      <strong>Name:</strong> {teacher.user.name}
                    </Typography>
                    <Typography variant="body1">
                      <strong>Email:</strong> {teacher.user.email}
                    </Typography>
                    <Typography variant="body1">
                      <strong>Phone:</strong> {teacher.user.phone}
                    </Typography>
                    <Typography variant="body1">
                      <strong>Gender:</strong> {teacher.sex}
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography variant="body1">
                      <strong>Mission Start Date:</strong>{" "}
                      {moment(teacher.createdAt).format("YYYY-MM-DD")}
                    </Typography>
                    <Typography variant="body1">
                      <strong>Hourly Rate:</strong> {teacher.hourlyRate} DT
                    </Typography>
                  </TableCell>
                </TableRow>
              </TableHead>
            </Table>
          </TableContainer>
        )}

        <Typography variant="h6">Withdrawals</Typography>
        {loadingWithdrawals ? (
          <div className="spinner">
            <CircularProgress size="30px" />
          </div>
        ) : (
          <TableContainer component={Paper}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell></TableCell>
                  <TableCell>Jan</TableCell>
                  <TableCell>Feb</TableCell>
                  <TableCell>Mar</TableCell>
                  <TableCell>Apr</TableCell>
                  <TableCell>May</TableCell>
                  <TableCell>Jun</TableCell>
                  <TableCell>Jul</TableCell>
                  <TableCell>Aug</TableCell>
                  <TableCell>Sep</TableCell>
                  <TableCell>Oct</TableCell>
                  <TableCell>Nov</TableCell>
                  <TableCell>Dec</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {withdrawals.map((row, index) => (
                  <TableRow key={index}>
                    <TableCell>{row.label}</TableCell>
                    {renderWithdrawals(row)}
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        )}
      </div>
    </>
  );
}

export default TeacherCard;
