import React from "react";
import styles from "../styles/Footer.module.css";
import { HashLink as Link } from "react-router-hash-link";
import LocalPhoneIcon from "@mui/icons-material/LocalPhone";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import MailIcon from "@mui/icons-material/Mail";
import { HashLink } from "react-router-hash-link";
import { Typography } from "@mui/material";

function Footer(props) {
  return (
    <div className={styles.footer}>
      <div className={styles.row}>
        <div className={styles.col30}>
          <HashLink to="/">
            <img alt="school-hub" src="./WhiteLogo.svg" />
          </HashLink>
          <div className="description">
            <p>
              Schoolup est une application de gestion scolaire efficace et
              intuitive. Simplifiez la gestion de votre établissement avec
              Schoolup. Optimisez votre expérience scolaire grâce à Schoolup.
            </p>
          </div>
        </div>

        <div className={styles.col20}>
          <div className={styles.linkRow}>
            <MailIcon />
            <p>contact@school-up.tn</p>
          </div>
          {/* <div className={styles.linkRow}>
            <LocationOnIcon />
            <p>Avenue Hedi Karay 4, Tunisie</p>
          </div> */}
          <div className={styles.linkRow}>
            <LocalPhoneIcon />
            <p>+216 94027053 </p>
          </div>
        </div>

        <div className={styles.col20}>
          <Typography variant="h6" sx={{ color: "white", fontWeight: "bold" }}>
            À propos de nous
          </Typography>
          <Link to="/#who-are-we">
            <p>Pourquoi nous ?</p>
          </Link>
          <Link to="/#our-values">
            <p>Contactez-nous</p>
          </Link>
        </div>
      </div>

      <div className={styles.socialMedia}>
        <a href="https://www.linkedin.com/company/103878336/admin/feed/posts/">
          <img alt="linkedin" src="/icons/linkedin.svg" />
        </a>
        <a href="https://www.facebook.com/profile.php?id=61561222118144">
          <img alt="facebook" src="./icons/fb.svg" />
        </a>
        <a href="https://www.instagram.com/schooluptunisie/">
          <img alt="instagram" src="/icons/instagram.svg" />
        </a>
      </div>

      <div className={styles.copyright}>
        Copyright © platforme scolaire by creo
      </div>
    </div>
  );
}

export default Footer;
