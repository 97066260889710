import React from "react";
import RoomForm from "./Form";

function EditRoom(props) {
  const {
    formData,
    setFormData,
    loadingForm,
    setAction,
    editRoom,
    resetRoom,
    room,
  } = props;

  return (
    <RoomForm
      formData={formData}
      setFormData={setFormData}
      loadingForm={loadingForm}
      handleSubmit={editRoom}
      actionLabel="Modifier la salle"
      setAction={setAction}
      resetRoom={resetRoom}
      roomName={formData.name}
    />
  );
}

export default EditRoom;
