import React from "react";
import {
  Autocomplete,
  Button,
  CircularProgress,
  TextField,
  Grid,
  Box,
  Typography,
} from "@mui/material";

function SubjectForm({
  formData,
  setFormData,
  handleSubmit,
  teachers,
  loadingForm,
  isEdit = false,
  title,
  setAction,
  resetForm,
}) {
  const onChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };
  const handleTeacherChange = (event, value) => {
    setFormData({
      ...formData,
      teacher: value || "",
    });
  };
  return (
    <Box
      sx={{
        padding: "20px",
        borderRadius: "10px",
        boxShadow: "0px 0px 5px 0px rgba(0,0,0,0.1)",
        backgroundColor: "#fff",
      }}
    >
      <Typography
        variant="h6"
        gutterBottom
        sx={{
          color: "#283487",
          fontWeight: "bold",
          textAlign: "center",
          marginBottom: "20px",
        }}
      >
        {title}
      </Typography>

      <form onSubmit={handleSubmit}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <TextField
              label="Nom"
              variant="outlined"
              name="name"
              fullWidth
              required
              value={formData.name}
              onChange={onChange}
            />
          </Grid>

          <Grid item xs={12} md={6}>
            <TextField
              label="Coefficient"
              required
              value={formData.coefficient}
              type="number"
              name="coefficient"
              onChange={onChange}
              fullWidth
            />
          </Grid>

          <Grid item xs={12} md={6}>
            <Autocomplete
              value={formData.teacher || null}
              options={teachers}
              getOptionLabel={(option) => option.user.name}
              isOptionEqualToValue={(option, value) => option.id === value.id}
              onChange={handleTeacherChange}
              renderInput={(params) => (
                <TextField {...params} label="Enseignant" required fullWidth />
              )}
            />
          </Grid>

          <Grid
            item
            xs={12}
            container
            spacing={2}
            sx={{
              display: "flex",
              justifyContent: "flex-end",
            }}
          >
            <Grid item>
              <Button type="submit" variant="contained" color="primary">
                {loadingForm ? (
                  <CircularProgress size="20px" sx={{ color: "#fff" }} />
                ) : isEdit ? (
                  "Modifier"
                ) : (
                  "Ajouter"
                )}
              </Button>
            </Grid>
            <Grid item>
              <Button
                onClick={() => {
                  setAction("");
                  setFormData({});
                }}
                variant="outlined"
                color="primary"
              >
                Annuler
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </form>
    </Box>
  );
}

export default SubjectForm;
