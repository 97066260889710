import { createTheme, ThemeProvider } from "@mui/material/styles";
import { SnackbarProvider } from "notistack";
import axios from "axios";
import { getUrl } from "./config/config";
import { useSelector } from "react-redux";
import Routes from "./routes";

import AOS from "aos";
import "aos/dist/aos.css";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { LocalizationProvider } from "@mui/x-date-pickers";

function App() {
  AOS.init({
    offset: 120,
    delay: 100,
    duration: 600,
    easing: "ease",
    once: false,
  });

  const { userInfo } = useSelector((state) => state.auth);
  axios.defaults.withCredentials = true;
  axios.defaults.baseURL = getUrl();
  axios.defaults.headers.common["Authorization"] = userInfo?.token;

  const theme = createTheme({
    palette: {
      primary: { main: "#283487" },
      secondary: { main: "#FDCF1B" },
      third: { main: "#06CB90" },
      fourth: { main: "#cb0664" },
    },
  });

  return (
    <SnackbarProvider
      anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
    >
      <LocalizationProvider dateAdapter={AdapterMoment}>
        <ThemeProvider theme={theme}>
          <Routes />
        </ThemeProvider>
      </LocalizationProvider>
    </SnackbarProvider>
  );
}

export default App;
