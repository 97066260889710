import React from "react";
import {
  Button,
  Select,
  MenuItem,
  TextField,
  InputLabel,
  FormControl,
  Box,
} from "@mui/material";
import RotateLeftIcon from "@mui/icons-material/RotateLeft";
import { useMediaQuery } from "@mui/material";

const FilterRooms = ({ type, setType, spots, setSpots }) => {
  const isSmallScreen = useMediaQuery((theme) => theme.breakpoints.down("sm"));
  return (
    <Box
      component="section"
      display="flex"
      flexDirection={isSmallScreen ? "column" : "row"}
      alignItems="center"
      gap={2}
      sx={{ marginBottom: "1em" }}
    >
      <FormControl
        variant="outlined"
        size="small"
        sx={{
          width: isSmallScreen ? "100%" : "20%",
        }}
      >
        <InputLabel>Type</InputLabel>
        <Select
          value={type}
          onChange={(e) => setType(e.target.value)}
          label="Type"
        >
          <MenuItem value="TP">TP</MenuItem>
          <MenuItem value="SALLE">SALLE</MenuItem>
          <MenuItem value="AMPHI">AMPHI</MenuItem>
        </Select>
      </FormControl>

      <TextField
        label="Capacité"
        type="number"
        inputProps={{ min: 0 }}
        value={spots}
        size="small"
        onChange={(e) => setSpots(e.target.value)}
        variant="outlined"
        sx={{
          width: isSmallScreen ? "100%" : "20%",
        }}
      />

      {(type !== "" || spots !== 0) && (
        <Button
          onClick={() => {
            setType("");
            setSpots(0);
          }}
          size="large"
          variant="outlined"
          startIcon={<RotateLeftIcon />}
        >
          Reset
        </Button>
      )}
    </Box>
  );
};

export default FilterRooms;
