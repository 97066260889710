import React from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Typography,
} from "@mui/material";
import { Pagination } from "@mui/material";

const NotesTable = ({
  subjects,
  notes,
  userInfo,
  total,
  totalCoefficient,
  moyenne,
  page,
  count,
  setPage,
  printRef,
}) => {
  console.log("notes", notes);
  return (
    <div ref={printRef} className="printBody">
      <TableContainer
        component={Paper}
        style={{ marginTop: "2em", maxHeight: "500px" }}
      >
        <Table>
          <TableHead>
            <TableRow>
              <TableCell
                style={{
                  fontWeight: "bold",
                  backgroundColor: "#f5f5f5",
                  color: "#333",
                }}
              >
                Matières
              </TableCell>
              <TableCell style={{ fontWeight: "bold" }}>Notes</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {subjects.map((subject) => (
              <TableRow key={subject._id}>
                <TableCell
                  style={{
                    backgroundColor: "#F5F3F3",
                    padding: "10px",
                    color: "#333",
                  }}
                >
                  <Typography variant="body2">
                    Matière:{" "}
                    <strong style={{ textTransform: "capitalize" }}>
                      {subject.name}
                    </strong>
                  </Typography>
                  <Typography variant="body2">
                    Enseignant(e):{" "}
                    <strong style={{ textTransform: "capitalize" }}>
                      {subject.teacher.user.name}{" "}
                    </strong>
                  </Typography>
                </TableCell>
                <TableCell
                  style={{
                    backgroundColor: "#EBE9E9",
                    padding: "10px",
                    border: "1px solid #e0e0e0",
                  }}
                >
                  {notes
                    .filter((note) => note.user === userInfo._id)
                    .map((note) => (
                      <div
                        key={note._id}
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          marginBottom: "8px",
                        }}
                      >
                        <div
                          style={{
                            flex: "1",
                            textAlign: "left",
                            background: "#283487",
                            color: "white",
                            padding: "2em",
                          }}
                        >
                          <Typography variant="body2">
                            Type: <strong>{note.type}</strong>
                          </Typography>
                          <Typography variant="body2">
                            Coefficient: <strong>{note.coefficient} </strong>
                          </Typography>
                          <Typography variant="body2">
                            Note: <strong>{note.note} </strong>
                          </Typography>
                        </div>
                      </div>
                    ))}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <section
        style={{
          display: "flex",
          justifyContent: "center",
          marginTop: "1em",
        }}
      >
        <Pagination
          onChange={(e, page) => setPage(page)}
          page={page}
          count={count}
          color="primary"
        />
      </section>

      <div
        style={{
          width: "100%",
          display: "flex",
          justifyContent: "flex-end",
          alignContent: "center",

          padding: "10px",
        }}
      >
        {total > 0 && (
          <div
            style={{
              width: "100%",
              display: "flex",
              textAlign: "right",
              flexDirection: "column",
              gap: "10px",
            }}
          >
            <span>
              Total: <strong>{total}</strong>
            </span>
            <span>
              Coefficient: <strong>{totalCoefficient}</strong>
            </span>
            <span>
              Moyenne: <strong>{moyenne.toFixed(2)}</strong>
            </span>
          </div>
        )}
      </div>
    </div>
  );
};

export default NotesTable;
