import { Button, CircularProgress } from "@mui/material";
import React from "react";

function AddRow(props) {
  const { addRow, setAction, label, setLabel, loadingForm } = props;
  return (
    <form onSubmit={addRow}>
      <div className="labeledInput">
        <label>Titre de paiement</label>
        <input
          className="defaultInput"
          type="text"
          required
          value={label}
          onChange={(e) => setLabel(e.target.value)}
        />
      </div>
      <br />
      {loadingForm ? (
        <CircularProgress size="33px" />
      ) : (
        <div className="btnRow">
          <Button type="submit" variant="contained">
            ajouter
          </Button>
          &nbsp;
          <Button
            type="button"
            variant="outlined"
            onClick={() => {
              setAction("");
              setLabel("");
            }}
          >
            annuler
          </Button>
        </div>
      )}
    </form>
  );
}

export default AddRow;
