import axios from "axios";
import { useSnackbar } from "notistack";
import { useEffect, useState } from "react";
import { getError } from "../../../config/config";
import { useSelector } from "react-redux";

export function useStudentNotesAPI(id, currentSeason, period) {
  const { enqueueSnackbar } = useSnackbar();
  const { userInfo } = useSelector((state) => state.auth);
  const { currentClassroom } = useSelector((state) => state.dashboard);
  const [subjects, setSubjects] = useState([]);
  const [notes, setNotes] = useState([]);
  const [total, setTotal] = useState();
  const [totalCoefficient, setTotalCoefficient] = useState();
  const [moyenne, setMoyenne] = useState();
  const [loadingForm, setLoadingForm] = useState(false);
  const [note, setNote] = useState({});
  const [action, setAction] = useState("");

  const addNote = async (e) => {
    e.preventDefault();
    try {
      const { data } = await axios.post("/note/add", note);
      enqueueSnackbar(data.message, { variant: "success" });
      setNote({ type: "", note: 0 });
      fetchNotes();
      setAction("");
      setLoadingForm(false);
    } catch (error) {
      setLoadingForm(false);
      enqueueSnackbar(error.message, { variant: "error" });
    }
  };

  const editNote = async (e) => {
    e.preventDefault();
    try {
      const { data } = await axios.put("/note/edit", note);
      enqueueSnackbar(data.message, { variant: "success" });
      setNote({});
      fetchNotes();
      setAction("");
      setLoadingForm(false);
    } catch (error) {
      setLoadingForm(false);
      enqueueSnackbar(error.message, { variant: "error" });
    }
  };

  const deleteNote = async (e) => {
    e.preventDefault();
    try {
      const { data } = await axios.delete(`/note/remove/${note._id}`);
      enqueueSnackbar(data.message, { variant: "success" });
      setNote({});
      fetchNotes();
      setAction("");
      setLoadingForm(false);
    } catch (error) {
      setLoadingForm(false);
      enqueueSnackbar(getError(error), { variant: "error" });
    }
  };

  const fetchSubjects = async () => {
    try {
      const { data } = await axios.get(
        `/subject/getallbyclassroom/${currentClassroom._id}`
      );
      setSubjects(data);
    } catch (error) {
      enqueueSnackbar(getError(error), { variant: "error" });
    }
  };

  const fetchNotes = async () => {
    try {
      const { data } = await axios.get(
        `/note/get/${id}?season=${currentSeason._id}&period=${period}`
      );
      setNotes(data.notes);
      setTotal(data.total);
      setTotalCoefficient(data.totalCoefficient);
      setMoyenne(data.moyenne);
    } catch (error) {
      enqueueSnackbar(getError(error), { variant: "error" });
    }
  };

  const calculateTotals = (notes) => {
    const totalNotes = notes.reduce(
      (acc, note) => acc + note.note * note.coefficient,
      0
    );
    const totalCoeff = notes.reduce((acc, note) => acc + note.coefficient, 0);
    setTotal(totalNotes);
    setTotalCoefficient(totalCoeff);
    setMoyenne(totalCoeff ? (totalNotes / totalCoeff).toFixed(2) : 0);
  };

  useEffect(() => {
    fetchSubjects();
    fetchNotes();
  }, [id, currentSeason, period]);

  return {
    subjects,
    notes,
    total,
    totalCoefficient,
    moyenne,
    setNote,
    setAction,
    note,
    loadingForm,
    setLoadingForm,
    fetchNotes,
    action,
    addNote,
    editNote,
    deleteNote,
    calculateTotals,
  };
}
