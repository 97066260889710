import React, { useState } from "react";
import "./VideoSection.css";

const VideoSection = () => {
  const [isPlaying, setIsPlaying] = useState(false);

  const handlePlayButtonClick = () => {
    setIsPlaying(true);
  };

  return (
    <div className="video-container">
      {!isPlaying && (
        <div className="overlay">
          <img
            src="images/videoBg.png"
            alt="Overlay"
            className="overlay-image"
            onError={(e) => (e.target.style.display = "none")}
          />
          <button onClick={handlePlayButtonClick} className="play-button">
            ▶
          </button>
        </div>
      )}
      {isPlaying && (
        <iframe
          className="video"
          width="560"
          height="315"
          src="https://www.youtube.com/embed/O8-94eQfEV0?autoplay=1"
          title="YouTube video player"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
        ></iframe>
      )}
    </div>
  );
};

export default VideoSection;
