import { Button, CircularProgress } from "@mui/material";
import React from "react";

function BlockEstablishment(props) {
  return (
    <>
      <h1 style={{ textAlign: "left" }}>
        Voulez vous activer l&apos;établissement:&nbsp;
        {props.establishment.name}
        &nbsp;?
      </h1>
      <br />
      <div>
        {props.loadingApi ? (
          <CircularProgress size="32px" />
        ) : (
          <div className="btnRow">
            <Button
              variant="contained"
              color="primary"
              onClick={() => props.activateEstablishment()}
            >
              oui
            </Button>
            &nbsp;
            <Button
              variant="outlined"
              color="primary"
              onClick={() => props.setAction("")}
            >
              non
            </Button>
          </div>
        )}
      </div>
    </>
  );
}

export default BlockEstablishment;
