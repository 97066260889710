import React, { useLayoutEffect, useState } from "react";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import { useSelector } from "react-redux";
import axios from "axios";

function LineChart(props) {
  const { userInfo } = useSelector((state) => state.auth);
  const { currentSeason } = useSelector((state) => state.dashboard);
  const [count, setCount] = useState({ garcons: 0, filles: 0 });

  const fetchData = async () => {
    try {
      const { data } = await axios.get(
        `/stats/candle/${userInfo.establishment._id}/${currentSeason?._id}`
      );
      setCount(data);
    } catch (error) {
      console.log(error);
    }
  };

  useLayoutEffect(() => {
    if (currentSeason) {
      fetchData();
    }
  }, [currentSeason]);

  const options = {
    chart: {
      backgroundColor: "#fff",
      type: "column",
      height: "400",
    },
    title: {
      text: "Élève",
    },
    legend: {
      enabled: true,
    },
    xAxis: {
      categories: ["Élève"],
    },
    yAxis: {
      title: {
        text: "",
      },
    },
    plotOptions: {
      line: {
        dataLabels: {
          enabled: true,
        },
      },
    },
    series: [
      {
        name: "Garçon",
        color: "#283487",
        data: [count.garcons],
      },
      {
        name: "Fille",
        color: "#FFBF00",
        data: [count.filles],
      },
    ],
  };
  return <HighchartsReact highcharts={Highcharts} options={options} />;
}

export default LineChart;
