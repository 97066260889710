import React from "react";
import { Dialog } from "@mui/material";
import AddRow from "./AddRow";
import AddSession from "./AddSession";
import EditSession from "./EditSession";
import DeleteSession from "./DeleteSession";

const SessionDialog = ({
  action,
  setAction,
  start,
  setStart,
  end,
  setEnd,
  addRow,
  addSession,
  editSession,
  deleteSession,
  session,
  setSession,
  subjects,
  rooms,
  loadingForm,
  resetSession,
}) => {
  return (
    <Dialog
      open={action !== ""}
      onClose={() => {
        setAction("");
        setStart("");
        setEnd("");
        resetSession();
      }}
    >
      <div style={{ maxHeight: "100%" }} className={"modal small"}>
        {action === "ADD_ROW" ? (
          <AddRow
            addRow={addRow}
            setAction={setAction}
            start={start}
            end={end}
            setStart={setStart}
            setEnd={setEnd}
          />
        ) : action === "ADD_SESSION" ? (
          <AddSession
            setAction={setAction}
            addSession={addSession}
            session={session}
            setSession={setSession}
            subjects={subjects}
            rooms={rooms}
            loadingForm={loadingForm}
            resetSession={resetSession}
          />
        ) : action === "EDIT_SESSION" ? (
          <EditSession
            setAction={setAction}
            editSession={editSession}
            session={session}
            setSession={setSession}
            subjects={subjects}
            rooms={rooms}
            loadingForm={loadingForm}
            resetSession={resetSession}
          />
        ) : action === "DELETE_SESSION" ? (
          <DeleteSession
            setAction={setAction}
            deleteSession={deleteSession}
            session={session}
            loadingForm={loadingForm}
            resetSession={resetSession}
          />
        ) : null}
      </div>
    </Dialog>
  );
};

export default SessionDialog;
