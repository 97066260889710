// api/scheduleApi.js
import axios from "axios";

export const fetchSessions = async (classroomId, userId) => {
  const { data } = await axios.get(`session/get/${classroomId}/${userId}`);
  return data;
};

export const addSessionApi = async (session) => {
  const res = await axios.post(`session/add`, session);
  return res.data;
};

export const editSessionApi = async (session) => {
  const { data } = await axios.put(`session/edit`, session);
  return data;
};

export const deleteSessionApi = async (sessionId) => {
  const { data } = await axios.delete(`session/remove/${sessionId}`);
  return data;
};

export const publishSessionApi = async (classroomId) => {
  const res = await axios.put(`/session/publish/${classroomId}`);
  return res.data;
};

export const fetchSubjects = async (classroomId) => {
  const { data } = await axios.get(`/subject/getallbyclassroom/${classroomId}`);
  return data;
};

export const fetchRooms = async (establishmentId) => {
  const { data } = await axios.get(`/room/getall/${establishmentId}`);
  return data;
};
