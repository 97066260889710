import { Button, TextField, Box, Typography } from "@mui/material";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";
import moment from "moment";
import React from "react";

function AddRow(props) {
  const { addRow, setAction, start, end, setStart, setEnd } = props;

  return (
    <form onSubmit={addRow}>
      <Typography
        variant="h4"
        gutterBottom
        style={{
          color: "#283487",
          textAlign: "center",
          fontWeight: "bold",
          fontSize: "1.5rem",
          marginBottom: "1em",
        }}
      >
        Ajouter un timing
      </Typography>
      <Box display="flex" justifyContent="space-between" mb={2}>
        <Box sx={{ flexGrow: 1 }}>
          <Typography variant="subtitle1">Heure de début</Typography>
          <TimePicker
            inputFormat="hh:mm"
            ampm={false}
            value={start}
            onChange={(val) => {
              setStart(moment(val, "hh:mm"));
            }}
            renderInput={(params) => (
              <TextField
                required
                fullWidth
                sx={{
                  "& .MuiOutlinedInput-notchedOutline": {
                    borderRadius: 1,
                    borderColor: "gray !important",
                  },
                }}
                {...params}
              />
            )}
          />
        </Box>
        <Box sx={{ flexGrow: 1, ml: 3 }}>
          <Typography variant="subtitle1">Heure de fin</Typography>
          <TimePicker
            inputFormat="hh:mm"
            ampm={false}
            value={end}
            onChange={(val) => {
              setEnd(moment(val, "hh:mm"));
            }}
            renderInput={(params) => (
              <TextField
                required
                fullWidth
                sx={{
                  "& .MuiOutlinedInput-notchedOutline": {
                    borderRadius: 1,
                    borderColor: "gray  !important",
                  },
                }}
                {...params}
              />
            )}
          />
        </Box>
      </Box>
      <Box display="flex" justifyContent="flex-end" gap={2}>
        <Button variant="contained" type="submit">
          ajouter
        </Button>
        <Button
          variant="outlined"
          onClick={() => {
            setAction("");
            setStart("");
            setEnd("");
          }}
        >
          annuler
        </Button>
      </Box>
    </form>
  );
}

export default AddRow;
