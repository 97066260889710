// hooks/useStudySchedule.js
import { useState, useEffect, useRef } from "react";
import { useSnackbar } from "notistack";
import moment from "moment";
import {
  fetchSessions,
  addSessionApi,
  editSessionApi,
  deleteSessionApi,
  publishSessionApi,
  fetchSubjects,
  fetchRooms,
} from "../../../Api/scheduleAPI";

const useStudySchedule = (userInfo, classroom) => {
  const { enqueueSnackbar } = useSnackbar();
  const [start, setStart] = useState("");
  const [end, setEnd] = useState("");
  const [action, setAction] = useState("");
  const [subjects, setSubjects] = useState([]);
  const [rooms, setRooms] = useState([]);
  const [loading, setLoading] = useState(true);
  const [loadingForm, setLoadingForm] = useState(false);
  const [session, setSession] = useState({
    dayIndex: 0,
    classroom: classroom,
    timing: "",
    subject: "",
    teacher: "",
    room: "",
  });
  const [sessions, setSessions] = useState([]);
  const printRef = useRef(null);

  const loadSessions = async () => {
    setLoading(true);
    try {
      const data = await fetchSessions(classroom, userInfo.user._id);
      setSessions(data || []);
    } catch (error) {
      enqueueSnackbar(error.message, { variant: "error" });
    }
    setLoading(false);
  };

  const loadSubjects = async () => {
    try {
      const data = await fetchSubjects(classroom);
      setSubjects(data);
    } catch (error) {
      enqueueSnackbar(error.message, { variant: "error" });
    }
  };

  const loadRooms = async () => {
    try {
      const data = await fetchRooms(userInfo.establishment._id);
      setRooms(data);
    } catch (error) {
      enqueueSnackbar(error.message, { variant: "error" });
    }
  };

  const addSession = async (e) => {
    e.preventDefault();
    setLoadingForm(true);
    try {
      const res = await addSessionApi(session);
      loadSessions();
      resetSession();
      setAction("");
      enqueueSnackbar(res.message, { variant: res.ok ? "success" : "error" });
    } catch (error) {
      enqueueSnackbar(error.message, { variant: "error" });
    }
    setLoadingForm(false);
  };

  const editSession = async (e) => {
    e.preventDefault();
    setLoadingForm(true);
    try {
      const data = await editSessionApi(session);
      loadSessions();
      resetSession();
      setAction("");
      enqueueSnackbar(data.message, { variant: "success" });
    } catch (error) {
      enqueueSnackbar(error.message, { variant: "error" });
    }
    setLoadingForm(false);
  };

  const deleteSession = async (e) => {
    e.preventDefault();
    setLoadingForm(true);
    try {
      const data = await deleteSessionApi(session._id);
      loadSessions();
      resetSession();
      setAction("");
      enqueueSnackbar(data.message, { variant: "success" });
    } catch (error) {
      enqueueSnackbar(error.message, { variant: "error" });
    }
    setLoadingForm(false);
  };

  const publishSession = async (e) => {
    e.preventDefault();
    setLoadingForm(true);
    try {
      const res = await publishSessionApi(classroom);
      loadSessions();
      enqueueSnackbar(res.message, { variant: "success" });
    } catch (error) {
      enqueueSnackbar(error.message, { variant: "error" });
    }
    setLoadingForm(false);
  };

  const addRow = (e) => {
    e.preventDefault();
    if (moment(end).isBefore(moment(start))) {
      return enqueueSnackbar(
        "l'heure de début doit toujours être supérieure à l'heure de début !",
        { variant: "warning" }
      );
    }
    setSessions([
      ...sessions,
      {
        timing: `${moment(start).format("hh:mm")} - ${moment(end).format(
          "hh:mm"
        )}`,
        sessions: Array(6).fill({ dayIndex: 0 }),
      },
    ]);
    setAction("");
    setStart("");
    setEnd("");
  };

  const resetSession = () => {
    setSession({
      dayIndex: 0,
      classroom: classroom,
      timing: "",
      subject: "",
      teacher: "",
      room: "",
    });
  };

  useEffect(() => {
    loadSessions();
    loadSubjects();
    loadRooms();
  }, []);

  return {
    start,
    setStart,
    end,
    setEnd,
    action,
    setAction,
    subjects,
    rooms,
    loading,
    loadingForm,
    session,
    setSession,
    sessions,
    printRef,
    addRow,
    addSession,
    editSession,
    deleteSession,
    publishSession,
    loadSessions,
  };
};

export default useStudySchedule;
