import React from "react";
import Dialog from "@mui/material/Dialog";
import AddTeacher from "./Add";
import EditTeacher from "./Edit";
import NotifyTeacher from "./SendNotification";
import TeacherAction from "./Action";

const TeacherDialog = ({
  action,
  setAction,
  resetTeacher,
  resetNotif,
  formData,
  setFormData,
  loadingForm,
  addTeacher,
  editTeacher,
  activateTeacher,
  blockTeacher,
  notifyTeacher,
  teacher,
  notification,
  setNotification,
  selected,
  setSelected,
}) => {
  return (
    <Dialog
      maxWidth="md"
      open={action !== ""}
      onClose={() => {
        setAction("");
        resetTeacher();
        resetNotif();
      }}
    >
      <div
        className={
          action === "BLOCK" ||
          action === "ACTIVATE" ||
          action === "MESSAGE" ||
          action === "NOTIFY"
            ? "modal small"
            : "modal medium"
        }
        style={action === "NOTIFY" ? { top: "10vh", maxHeight: "100%" } : null}
      >
        {action === "ADD" ? (
          <AddTeacher
            loadingForm={loadingForm}
            formData={formData}
            setFormData={setFormData}
            setAction={setAction}
            addTeacher={addTeacher}
            establishment={formData.establishment}
            resetTeacher={resetTeacher}
          />
        ) : action === "EDIT" ? (
          <EditTeacher
            loadingForm={loadingForm}
            formData={formData}
            setFormData={setFormData}
            setAction={setAction}
            editTeacher={editTeacher}
            teacher={teacher}
            establishment={formData.establishment}
            resetTeacher={resetTeacher}
          />
        ) : action === "ACTIVATE" || action === "BLOCK" ? (
          <TeacherAction
            loadingForm={loadingForm}
            setAction={setAction}
            actionHandler={
              action === "ACTIVATE" ? activateTeacher : blockTeacher
            }
            teacher={teacher}
            actionType={action}
          />
        ) : action === "NOTIFY" ? (
          <NotifyTeacher
            loadingForm={loadingForm}
            setAction={setAction}
            notifyTeacher={notifyTeacher}
            teacher={teacher}
            notification={notification}
            setNotification={setNotification}
            selected={selected}
            setSelected={setSelected}
            resetNotif={resetNotif}
          />
        ) : null}
      </div>
    </Dialog>
  );
};

export default TeacherDialog;
