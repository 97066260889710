import { useRoutes } from "react-router-dom";

// routes
import LoginRoutes from "./LoginRoutes";
import MainRoutes from "./MainRoutes";
import AuthenticationRoutes from "./AuthenticationRoutes";
import NotFoundPage from "../views/pages/notFound";
import Home from "../pages/superadmin/Home";
import SuperAdminEstablishment from "../pages/superadmin/Establishment";

// ==============================|| ROUTING RENDER ||============================== //

export default function ThemeRoutes() {
  return useRoutes([
    MainRoutes,
    AuthenticationRoutes,
    LoginRoutes,
    {
      path: "/home",
      element: <Home />,
    },
    {
      path: "/establishment/:id",
      element: <SuperAdminEstablishment />,
    },

    {
      path: "/404",
      element: <NotFoundPage />,
    },
    {
      path: "*",
      element: <NotFoundPage />,
    },
  ]);
}
