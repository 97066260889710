import { Button, CircularProgress } from "@mui/material";
import React from "react";
import { getDay } from "../../../config/config";

function EditSession(props) {
  const { deleteSession, setAction, session, resetSession, loadingForm } =
    props;
  return (
    <form onSubmit={deleteSession}>
      <h1>
        Supprimer la séance le {getDay(session.dayIndex)} de{" "}
        {session.timing.split("-")[0]} à {session.timing.split("-")[1]}
      </h1>
      <br />
      {loadingForm ? (
        <CircularProgress size="32px" />
      ) : (
        <div className="btnRow">
          <Button variant="contained" type="submit">
            Supprimer
          </Button>
          &nbsp;
          <Button
            variant="outlined"
            type="button"
            onClick={() => {
              setAction("");
              resetSession();
            }}
          >
            Annuler
          </Button>
        </div>
      )}
    </form>
  );
}

export default EditSession;
