import React from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  IconButton,
  Chip,
} from "@mui/material";
import SettingsIcon from "@mui/icons-material/Settings";

const RoomsTable = ({ rooms, onEdit }) => {
  return (
    <TableContainer component={Paper}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Nom</TableCell>
            <TableCell>Type</TableCell>
            <TableCell>Capacité</TableCell>
            <TableCell>Disponibilité</TableCell>
            <TableCell>Action</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rooms.length > 0 ? (
            rooms.map((room) => (
              <TableRow key={room._id}>
                <TableCell>{room?.name}</TableCell>
                <TableCell>{room?.type}</TableCell>
                <TableCell>{room?.spots}</TableCell>
                <TableCell>
                  <Chip
                    label={room?.available ? "Oui" : "Non"}
                    color={room?.available ? "success" : "error"}
                  />
                </TableCell>
                <TableCell>
                  <IconButton onClick={() => onEdit(room)} color="primary">
                    <SettingsIcon />
                  </IconButton>
                </TableCell>
              </TableRow>
            ))
          ) : (
            <TableRow>
              <TableCell colSpan={5} align="center">
                Aucune salle trouvée
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default RoomsTable;
