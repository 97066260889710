import React from "react";
import Dialog from "@mui/material/Dialog";
import AddDate from "./AddDate";
import AddExam from "./AddExam";
import EditExam from "./EditExam";
import DeleteExam from "./DeleteExam";
import DeleteSchedule from "./DeleteSchedule";

const ActionDialog = ({
  action,
  setAction,
  addDate,
  date,
  setDate,
  exam,
  setExam,
  addExam,
  editExam,
  deleteExam,
  deleteSchedule,
  subjects,
  rooms,
  loadingForm,
}) => (
  console.log("action", action),
  (
    <Dialog open={action !== ""} onClose={() => setAction("")}>
      <div className="modal small">
        {action === "ADD_DATE" && (
          <AddDate
            addDate={addDate}
            setAction={setAction}
            setDate={setDate}
            date={date}
          />
        )}
        {action === "ADD_EXAM" && (
          <AddExam
            addExam={addExam}
            setAction={setAction}
            action={action}
            exam={exam}
            setExam={setExam}
            subjects={subjects}
            rooms={rooms}
            loadingForm={loadingForm}
          />
        )}
        {action === "EDIT_EXAM" && (
          <EditExam
            editExam={editExam}
            exam={exam}
            setExam={setExam}
            subjects={subjects}
            setAction={setAction}
            rooms={rooms}
            loadingForm={loadingForm}
          />
        )}
        {action === "DELETE_EXAM" && (
          <DeleteExam
            deleteExam={deleteExam}
            exam={exam}
            setAction={setAction}
            loadingForm={loadingForm}
          />
        )}
        {action === "DELETE_SCHEDULE" && (
          <DeleteSchedule
            deleteSchedule={deleteSchedule}
            loadingForm={loadingForm}
            setAction={setAction}
          />
        )}
      </div>
    </Dialog>
  )
);

export default ActionDialog;
