import { USER_LOGIN, USER_LOGOUT } from "../actions";

const authReducer = (
  state = {
    userInfo: localStorage.getItem("userInfo")
      ? JSON.parse(localStorage.getItem("userInfo"))
      : null,
  },
  action
) => {
  switch (action.type) {
    case USER_LOGIN:
      localStorage.setItem("userInfo", JSON.stringify(action.payload));
      return { ...state, userInfo: action.payload };
    case USER_LOGOUT:
      localStorage.removeItem("userInfo");
      localStorage.removeItem("notifications");
      return { ...state, userInfo: null, notification: 0 };
    default:
      return state;
  }
};
export default authReducer;
