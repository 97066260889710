import { CircularProgress, Skeleton } from "@mui/material";
import axios from "axios";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";

function PieChart(props) {
  const [pie, setPie] = useState(null);
  const [loadingPie, setLoadingPie] = useState(true);
  const { userInfo } = useSelector((state) => state.auth);
  const { currentSeason } = useSelector((state) => state.dashboard);
  const { refresh } = props;

  const fetchPie = async () => {
    const { data } = await axios.get(
      `/stats/pie/${userInfo.establishment._id}/${currentSeason._id}`
    );
    setPie(data);
    setLoadingPie(false);
  };

  useEffect(() => {
    fetchPie();
  }, [currentSeason, refresh]);

  const options = {
    //  Styles
    chart: {
      width: "400",
      height: "400",
      spacing: [20, 20, 20, 20],
      backgroundColor: "transparent",
      type: "pie",
      options3d: {
        enabled: false,
      },
    },
    title: {
      text: "PORTFEUILLE",
      align: "center",
      verticalAlign: "middle",
      style: {
        fontWeight: "bold",
        color: "#AAA",
        fontSize: "15px",
      },
    },
    plotOptions: {
      pie: {
        center: ["50%", "50%"],
        innerSize: 150,
        depth: 0,
        opacity: 1,
        borderWidth: 0,
        dataLabels: {
          distance: 10,
          color: "#666",
          style: { "font-size": "15px" },
          crookDistance: "100%",
        },
      },
    },
    // Data
    series: [
      {
        type: "pie",
        data: pie,
      },
    ],
  };

  return (
    <>
      {loadingPie ? (
        <div className="spinner">
          <Skeleton variant="circular" width="400px" height="400px" />
        </div>
      ) : (
        <HighchartsReact highcharts={Highcharts} options={options} />
      )}
    </>
  );
}

export default PieChart;
