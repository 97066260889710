import React from "react";
import {
  Button,
  CircularProgress,
  TextField,
  Grid,
  Typography,
} from "@mui/material";

function AddRow(props) {
  const { addRow, setAction, label, setLabel, loadingForm } = props;

  return (
    <form onSubmit={addRow}>
      <Grid container spacing={2} alignItems="center">
        <Grid item xs={12}>
          <Typography variant="subtitle1">Intitulé de paiement</Typography>
        </Grid>
        <Grid item xs={12}>
          <TextField
            fullWidth
            variant="outlined"
            label="Titre"
            value={label}
            onChange={(e) => setLabel(e.target.value)}
            required
          />
        </Grid>
        <Grid item xs={12}>
          {loadingForm ? (
            <CircularProgress size={33} />
          ) : (
            <div
              style={{
                display: "flex",
                justifyContent: "flex-end",
                width: "100%",
              }}
            >
              <Button type="submit" variant="contained" color="primary">
                Ajouter
              </Button>
              &nbsp;
              <Button
                type="button"
                variant="contained"
                sx={{
                  backgroundColor: "#f44336",
                  "&:hover": {
                    backgroundColor: "#d32f2f",
                  },
                }}
                onClick={() => {
                  setAction("");
                  setLabel("");
                }}
              >
                Annuler
              </Button>
            </div>
          )}
        </Grid>
      </Grid>
    </form>
  );
}

export default AddRow;
