import {
  Button,
  CircularProgress,
  TextField,
  FormControl,
  FormControlLabel,
  Checkbox,
  Box,
} from "@mui/material";
import React from "react";

function WithdrawalForm({
  onSubmit,
  withdrawal,
  setWithdrawal,
  resetWithdrawal,
  loadingForm,
  multiple,
  setMultiple,
  isEdit = false,
}) {
  return (
    <form onSubmit={onSubmit}>
      <Box mb={2}>
        <TextField
          fullWidth
          label="Montant"
          type="number"
          step="any"
          InputProps={{ inputProps: { min: 1 } }}
          required
          value={withdrawal.amount}
          onChange={(e) =>
            setWithdrawal({ ...withdrawal, amount: e.target.value })
          }
        />
      </Box>
      <Box mb={2}>
        <TextField
          fullWidth
          label="Mode de paiement"
          required
          value={withdrawal.method}
          onChange={(e) =>
            setWithdrawal({ ...withdrawal, method: e.target.value })
          }
        />
      </Box>
      {multiple !== undefined && (
        <FormControl component="fieldset" fullWidth>
          <FormControlLabel
            control={
              <Checkbox
                checked={multiple}
                onChange={(e) => setMultiple(e.target.checked)}
              />
            }
            label="Multiple ?"
          />
          {multiple && (
            <TextField
              fullWidth
              label="Multiple Amount"
              type="number"
              InputProps={{ inputProps: { min: 1 } }}
              required
              value={withdrawal.multiple}
              onChange={(e) =>
                setWithdrawal({ ...withdrawal, multiple: e.target.value })
              }
            />
          )}
        </FormControl>
      )}
      <Box mt={2} display="flex" justifyContent="flex-end" gap={1}>
        {loadingForm ? (
          <CircularProgress size={24} />
        ) : (
          <>
            <Button type="submit" variant="contained" color="primary">
              {isEdit ? "Modifier" : "Ajouter"}
            </Button>
            <Button
              type="button"
              variant="outlined"
              color="secondary"
              onClick={resetWithdrawal}
            >
              Annuler
            </Button>
          </>
        )}
      </Box>
    </form>
  );
}

export default WithdrawalForm;
