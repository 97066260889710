import React from "react";
import NoteForm from "./NoteForm";

function EditNote(props) {
  const { editNote, note, setNote, loadingForm, setAction } = props;
  return (
    <NoteForm
      onSubmit={editNote}
      note={note}
      setNote={setNote}
      actionType="edit"
      loadingForm={loadingForm}
      setAction={setAction}
    />
  );
}

export default EditNote;
