import { Button, CircularProgress } from "@mui/material";
import React from "react";
import { getMonth } from "../../../config/config";

function DeleteDeposit(props) {
  const { deletePayment, resetPayment, setAction, loadingForm, payment } =
    props;
  return (
    <>
      <h1 style={{ textAlign: "left", lineHeight: "25px" }}>
        Supprimer ce paiement du mois&nbsp;"{getMonth(payment.monthIndex)}" avec
        le montant&nbsp;"{payment.amount} DT" ?
      </h1>
      {loadingForm ? (
        <CircularProgress size="33px" />
      ) : (
        <div className="btnRow">
          <Button
            variant="contained"
            onClick={() => {
              deletePayment();
            }}
          >
            supprimer
          </Button>
          &nbsp;
          <Button
            type="button"
            variant="outlined"
            onClick={() => {
              setAction("");
              resetPayment();
            }}
          >
            annuler
          </Button>
        </div>
      )}
    </>
  );
}

export default DeleteDeposit;
