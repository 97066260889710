// src/components/StudentTable.js
import React from "react";
import {
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Table,
  TableBody,
  TableCell,
} from "@mui/material";
import { Tooltip } from "@mui/material";
import { months } from "../../../utils/Constants";
const DepositTable = ({ deposits, setDeposit, setAction, renderDeposits }) => {
  return (
    <TableContainer
      component={Paper}
      style={{ maxWidth: "100%", overflowX: "auto" }}
    >
      <Table>
        <TableHead>
          <TableRow>
            <TableCell
              style={{ backgroundColor: "#283487", color: "#fff" }}
            ></TableCell>
            {months.map((month, index) => (
              <TableCell key={index}>{month}</TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {deposits.map((row, i) => (
            <TableRow key={i}>
              <TableCell style={{ backgroundColor: "#283487", color: "#fff" }}>
                {row.label}
              </TableCell>
              {renderDeposits(row)}
            </TableRow>
          ))}

          {deposits.length === 0 && (
            <TableRow>
              <TableCell
                colSpan="13"
                style={{
                  textAlign: "center",
                  padding: "4em",
                  fontSize: "1.2rem",
                }}
              >
                Aucun paiement effectué
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default DepositTable;
