/* eslint-disable no-unused-vars */

import Home from "../pages/Home";

// ==============================|| AUTHENTICATION ROUTING ||============================== //

const AuthenticationRoutes = {
  path: "/",
  children: [
    {
      path: "/login",
      element: <Home />,
    },
  ],
};

export default AuthenticationRoutes;
