import { Button } from "@mui/material";
import React, { useRef } from "react";
import { useSelector } from "react-redux";
import styles from "../styles/admin/StudentCard.module.css";
import moment from "moment";
import { getMonth } from "../config/config";
import ReactToPrint from "react-to-print";

function Receipt(props) {
  const printRef = useRef();
  const { setAction, resetWithdrawal, withdrawal, teacher } = props;
  const { currentSeason } = useSelector((state) => state.dashboard);
  const { userInfo } = useSelector((state) => state.auth);
  return (
    <div ref={printRef} className={styles.receipt}>
      <br />
      <br />
      <h1>
        {userInfo.establishment.type}&nbsp;
        {userInfo.establishment.name}
      </h1>
      <h2>
        Adresse: <a>{userInfo.establishment.address}</a>
      </h2>
      <h2>
        Email: <a>{userInfo.establishment.email}</a>
      </h2>
      <h2>
        Téléphone: <a>{userInfo.establishment.phone}</a>
      </h2>
      <h2>
        Année scolaire: <a>{currentSeason.year}</a>
      </h2>
      <h2>
        Agent: <a>{withdrawal.admin.user.name}</a>
      </h2>
      <br />
      <h1 style={{ textAlign: "center" }}>
        {withdrawal.label} pour le mois "{getMonth(withdrawal.monthIndex)}"
      </h1>
      <br />
      <div className={styles.hr} />
      <h2>
        enseignant: <a>{teacher.user.name}</a>
      </h2>
      <h2>
        montant: <a>{withdrawal.amount} DT</a>
      </h2>
      <h2>
        modalité de paiement: <a>{withdrawal.method}</a>
      </h2>
      <h2>
        date de paiement:{" "}
        <a>{moment(withdrawal.createdAt).format("DD-MM-yyyy")}</a>
      </h2>
      <div className={styles.hr} />
      <br />
      <h2 style={{ textAlign: "right" }}>
        <a>Bénéficiaire: "{teacher.user.name}"</a>
      </h2>
      <h2 style={{ textAlign: "right" }}>
        <a>..................................................</a>
      </h2>
      <h2 style={{ textAlign: "right" }}>
        <a>
          {userInfo.establishment.address} le {moment().format("DD-MM-yyyy")}
        </a>
      </h2>
      <div className="btnRow">
        <div className={`dontprint`}>
          <ReactToPrint
            trigger={() => <Button variant="contained">Imprimer</Button>}
            content={() => printRef.current}
          />
          &nbsp;
          <Button
            onClick={() => {
              resetWithdrawal();
              setAction("");
            }}
            variant="outlined"
          >
            annuler
          </Button>
        </div>
      </div>
    </div>
  );
}

export default Receipt;
