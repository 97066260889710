import { Button, CircularProgress } from "@mui/material";
import React from "react";

function DeleteSchedule(props) {
  const { deleteSchedule, setAction, loadingForm } = props;

  return (
    <form onSubmit={deleteSchedule}>
      <h1 style={{ textAlign: "left" }}>
        Supprimer l&apos;emploi d&apos;examen ?
      </h1>
      <br />
      {loadingForm ? (
        <CircularProgress size="32px" />
      ) : (
        <div className="btnRow">
          <Button variant="contained" type="submit">
            supprimer
          </Button>
          &nbsp;
          <Button
            variant="outlined"
            type="button"
            onClick={() => {
              setAction("");
            }}
          >
            annuler
          </Button>
        </div>
      )}
    </form>
  );
}

export default DeleteSchedule;
