import React, { useEffect, useRef, useState } from "react";
import axios from "axios";
import { useSnackbar } from "notistack";
import {
  CircularProgress,
  Tooltip,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  IconButton,
  Button,
} from "@mui/material";
import ReactToPrint from "react-to-print";
import PrintIcon from "@mui/icons-material/Print";

import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";

function WorkSchedule() {
  const { enqueueSnackbar } = useSnackbar();
  const [loading, setLoading] = useState(true);
  const printRef = useRef();
  const [sessions, setSessions] = useState([]);
  const { id: paramId, name: paramName } = useParams();
  const { userInfo } = useSelector((state) => state.auth);

  const id = userInfo.user.role === "TEACHER" ? userInfo._id : paramId;
  const name =
    userInfo.user.role === "TEACHER" ? userInfo.user.name : paramName;
  console.log("id", id);
  const renderRow = (row) => {
    const cells = [
      <TableCell key={row.timing} sx={{ backgroundColor: "#F5F3F3" }}>
        {row.timing}
      </TableCell>,
    ];

    for (let i = 0; i < 6; i++) {
      const session = row.sessions.find((s) => s.dayIndex === i);

      cells.push(
        <Tooltip
          key={row.timing + i}
          title={!session?.room?.available ? "Salle non disponible" : ""}
        >
          <TableCell
            sx={{
              backgroundColor: session?.room?.available
                ? "#283487"
                : session
                ? "#283487"
                : "#EBE9E9",
              color: "#FFF",
              p: 1,
            }}
          >
            {session ? (
              <>
                <p>
                  Matière:&nbsp;
                  <strong
                    style={{ textTransform: "capitalize", color: "#FFF" }}
                  >
                    {session?.subject?.name || "N/A"}
                  </strong>
                </p>
                <p>
                  Salle:&nbsp;
                  <strong
                    style={{ textTransform: "capitalize", color: "#FFF" }}
                  >
                    {session?.room?.name || "N/A"}
                  </strong>
                </p>
              </>
            ) : (
              <div></div>
            )}
          </TableCell>
        </Tooltip>
      );
    }

    return cells;
  };

  const fetchSessions = async () => {
    try {
      const { data } = await axios.get(`session/getByTeacher/${id}`);
      setSessions(data || []);
      setLoading(false);
    } catch (error) {
      enqueueSnackbar(error.message, { variant: "error" });
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchSessions();
  }, [id]);

  return (
    <>
      <div ref={printRef} style={{ padding: "1em" }}>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            marginBottom: "1em",
            gap: "1em",
          }}
        >
          <h3 style={{ fontSize: "2rem", color: "#283487" }}>
            Emploi du temps de{" "}
            <strong
              style={{
                color: "#283487",
                textTransform: "capitalize",
              }}
            >
              {name} :
            </strong>
          </h3>
        </div>

        {loading ? (
          <div style={{ display: "flex", justifyContent: "center" }}>
            <CircularProgress />
          </div>
        ) : sessions.length === 0 ? (
          <div style={{ display: "flex", justifyContent: "center" }}>
            <h3>Aucune session trouvée</h3>
          </div>
        ) : (
          <TableContainer
            component={Paper}
            sx={{ width: "100%", overflowX: "auto" }}
          >
            <Table aria-label="schedule table">
              <TableHead>
                <TableRow>
                  <TableCell>Date</TableCell>
                  <TableCell>Lundi</TableCell>
                  <TableCell>Mardi</TableCell>
                  <TableCell>Mercredi</TableCell>
                  <TableCell>Jeudi</TableCell>
                  <TableCell>Vendredi</TableCell>
                  <TableCell>Samedi</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {sessions.map((row, index) => (
                  <TableRow key={index}>{renderRow(row)}</TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        )}
      </div>

      <div style={{ display: "flex", justifyContent: "end", marginTop: "2em" }}>
        <ReactToPrint
          trigger={() => (
            <Button
              variant="contained"
              color="primary"
              startIcon={<PrintIcon />}
              sx={{
                backgroundColor: "#283487",
                color: "#FFF",
                padding: "0.5em 1em",
                borderRadius: "0px",
                textTransform: "none",
                fontSize: "1rem",
                fontWeight: "bold",
              }}
            >
              Imprimer
            </Button>
          )}
          content={() => printRef.current}
        />
      </div>
    </>
  );
}

export default WorkSchedule;
