import React, { useEffect, useLayoutEffect, useRef, useState } from "react";
import styles from "../../../styles/admin/Messenger.module.css";
import { Button, IconButton, Skeleton, TextField } from "@mui/material";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import { useSnackbar } from "notistack";
import RotateLeftIcon from "@mui/icons-material/RotateLeft";
import moment from "moment";
import SendIcon from "@mui/icons-material/Send";
import PhoneEnabledIcon from "@mui/icons-material/PhoneEnabled";
import { io } from "socket.io-client";
import { getError } from "../../../config/config";

function Messenger() {
  const [loading, setLoading] = useState(false);
  const [recipients, setRecipients] = useState([]);
  const [rooms, setRooms] = useState([]);
  const [userName, setUserName] = useState("");
  const { userInfo } = useSelector((state) => state.auth);
  const [chat, setChat] = useState(null);
  const { enqueueSnackbar } = useSnackbar();
  const [limit, setLimit] = useState(10);
  const [more, setMore] = useState(false);
  const [text, setText] = useState("");
  const [loadingRoom, setLoadingRoom] = useState(false);
  const [recipient, setRecipient] = useState(null);
  const [recipientName, setRecipientName] = useState(null);
  console.log("chat", chat);
  const bottomRef = useRef(null);
  const dispatch = useDispatch();

  const scrollToBottom = () => {
    bottomRef.current?.scrollIntoView();
  };

  const isStudent = userInfo?.user?.role === "STUDENT" ? true : false;
  console.log("isStudent", isStudent);
  const fetchRecipients = async () => {
    try {
      setLoading(true);
      const { data } = await axios.get(
        `/recipient/get/${userInfo?.establishment?._id}`
      );
      setRecipients(data);
      setLoading(false);
    } catch (error) {
      enqueueSnackbar(getError(error), { variant: "error" });
      setLoading(false);
    }
  };

  const fetchRoom = async (recipient) => {
    setLoadingRoom(true);
    setRecipient(recipient._id);
    setRecipientName(recipient.name);

    try {
      const { data } = await axios.get(`/messages/get/${recipient._id}`);
      console.log("Fetched room data:", data);
      setChat(data);
      setLoadingRoom(false);
    } catch (error) {
      enqueueSnackbar(getError(error), { variant: "error" });
      setLoadingRoom(false);
    }
  };

  const send = async (e) => {
    e.preventDefault();
    try {
      await axios.post("/message/send", {
        sender: isStudent ? userInfo?.user._id : userInfo?.establishment?._id,
        senderModel: isStudent ? "User" : "Establishment",
        recipient,
        recipientModel: isStudent ? "Establishment" : "User",
        message: text,
      });
      setText("");
      setChat((prevChat) => [
        ...prevChat,
        {
          sender: isStudent ? userInfo?.user._id : userInfo?.establishment?._id,
          senderModel: isStudent ? "User" : "Establishment",
          recipient,
          recipientModel: isStudent ? "Establishment" : "User",
          message: text,
        },
      ]);
    } catch (error) {
      enqueueSnackbar(error.response.data.error || "Error sending message", {
        variant: "error",
      });
    }
  };

  useEffect(() => {
    if (!userInfo) return;

    const socket = io(process.env.REACT_APP_SERVER_URL);

    socket.on("connect", () => {
      console.log("Connected to WebSocket server");
      socket.emit("JOIN", {
        id: isStudent ? userInfo?.user._id : userInfo?.establishment?._id,
        type: isStudent ? "User" : "Establishment",
      });
    });

    socket.on("disconnect", () => {
      console.log("Disconnected from WebSocket server");
    });

    socket.on("NEW_MESSAGE", (newMessage) => {
      console.log("New message received:", newMessage);
      if (
        isStudent
          ? newMessage.recipient === userInfo?.user._id
          : newMessage.recipient === userInfo?.establishment?._id
      ) {
        setChat((prevChat) => [...prevChat, newMessage]);
      }
    });

    return () => {
      socket.disconnect();
    };
  }, [userInfo, isStudent]);

  useEffect(() => {
    if (!isStudent) {
      fetchRecipients();
    }
  }, [isStudent]);

  useEffect(() => {
    console.log("Chat updated:", chat);
    scrollToBottom();
  }, [chat]);

  console.log("recipient:", recipientName);

  return (
    <>
      <div className={styles.row}>
        <div className={styles.sideMenu}>
          {loading ? (
            <section className={styles.rooms}>
              {[...Array(3)].map((_, index) => (
                <Skeleton
                  key={index}
                  className={styles.room}
                  variant="rectangular"
                  sx={{ height: "75px", marginBottom: "10px" }}
                />
              ))}
            </section>
          ) : (
            <section className={styles.rooms}>
              {isStudent && (
                <section className={styles.rooms}>
                  <div
                    key={userInfo.establishment?._id}
                    className={styles.room}
                    onClick={() => fetchRoom(userInfo.establishment)}
                  >
                    <div className={styles.avatar}>
                      <img
                        alt={userInfo.establishment?.name}
                        src={
                          userInfo.establishment?.avatar ||
                          "/icons/profile2.svg"
                        }
                      />
                    </div>
                    <div className={styles.text}>
                      <p>{userInfo.establishment?.name}</p>
                    </div>
                  </div>
                </section>
              )}
              {recipients?.map((recipient) => (
                <div
                  key={recipient._id}
                  className={styles.room}
                  onClick={() => fetchRoom(recipient?.user)}
                  style={{
                    marginBottom: "10px",
                  }}
                >
                  <div className={styles.avatar}>
                    <img
                      alt={recipient?.user?.name}
                      src={recipient?.user?.avatar || "/icons/profile2.svg"}
                    />
                  </div>
                  <div className={styles.text}>
                    <p>{recipient?.user?.name}</p>
                  </div>
                </div>
              ))}
              {more && (
                <div
                  style={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  <p
                    onClick={() => {
                      setLimit(limit + 10);
                    }}
                  >
                    voir plus
                  </p>
                </div>
              )}
            </section>
          )}
        </div>
        <div className={styles.chat}>
          {!loadingRoom && chat === null ? (
            <div className={styles.placeholder}>
              <img
                alt={userInfo?.user?.name}
                src={userInfo?.user?.avatar || "/icons/profile2.svg"}
              />
              <p
                style={{
                  fontSize: "1.5rem",
                  color: "#283487",
                  fontWeight: "bold",
                  textAlign: "center",
                }}
              >
                Bienvenue,{" "}
                {isStudent
                  ? userInfo?.user?.name
                  : userInfo?.establishment?.name}{" "}
                !
              </p>
              <p
                style={{
                  fontSize: "1.2rem",
                  color: "#000",
                  fontWeight: "bold",
                  textAlign: "center",
                }}
              >
                Veuillez sélectionner un chat pour démarrer la messagerie
              </p>
            </div>
          ) : (
            <>
              <div className={styles.chatHeader}>
                <div className={styles.avatar}>
                  <img
                    alt={chat?.recipient?.name}
                    src={chat?.recipient?.avatar || "/icons/profile2.svg"}
                  />
                  &nbsp;
                  <p
                    style={{
                      textTransform: "capitalize",
                    }}
                  >
                    {!isStudent ? recipientName : userInfo?.establishment?.name}
                  </p>
                </div>
                <a href={`tel:${chat?.recipient?.phone}`}>
                  <IconButton sx={{ color: "#73EB9C" }}>
                    <div className={styles.avatar}>
                      <img alt="phone" src="/icons/phone.svg" />
                    </div>
                  </IconButton>
                </a>
              </div>
              <div className={styles.body}>
                {chat?.map((message) => {
                  const isSenderCurrentUser =
                    (isStudent && message.senderModel === "User") ||
                    (!isStudent && message.senderModel === "Establishment");

                  return (
                    <div key={message._id}>
                      {isSenderCurrentUser ? (
                        <div className={`${styles.messageRow} ${styles.right}`}>
                          <div className={`${styles.message} ${styles.me}`}>
                            {message?.message}
                          </div>
                        </div>
                      ) : (
                        <div className={`${styles.messageRow} ${styles.left}`}>
                          <div className={`${styles.message} ${styles.him}`}>
                            {message?.message}
                          </div>
                        </div>
                      )}
                    </div>
                  );
                })}
                <div ref={bottomRef} />
              </div>

              <div className={styles.chatFooter}>
                <form
                  onSubmit={send}
                  style={{
                    display: "flex",
                    alignItems: "center",
                    width: "100%",
                  }}
                >
                  <TextField
                    id="outlined-basic"
                    label="Écrire un message..."
                    variant="outlined"
                    value={text}
                    style={{ width: "100%" }}
                    onChange={(e) => setText(e.target.value)}
                  />
                  <IconButton type="submit">
                    <SendIcon />
                  </IconButton>
                </form>
              </div>
            </>
          )}
        </div>
      </div>
    </>
  );
}

export default Messenger;
