import React from "react";
import RoomForm from "./Form";

function AddRoom(props) {
  const { formData, setFormData, loadingForm, setAction, addRoom, resetRoom } =
    props;

  return (
    <RoomForm
      formData={formData}
      setFormData={setFormData}
      loadingForm={loadingForm}
      handleSubmit={addRoom}
      actionLabel="Ajouter une salle"
      setAction={setAction}
      resetRoom={resetRoom}
    />
  );
}

export default AddRoom;
