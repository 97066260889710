import {
  Button,
  CircularProgress,
  TextField,
  Checkbox,
  FormControlLabel,
} from "@mui/material";
import React, { useState } from "react";

function AddDeposit(props) {
  const [multiple, setMultiple] = useState(false);
  const {
    addPayment,
    setAction,
    payment,
    setPayment,
    resetPayment,
    loadingForm,
  } = props;

  return (
    <form onSubmit={addPayment}>
      <div className="labeledInput">
        <TextField
          label="Montant"
          type="number"
          step="any"
          min={1}
          required
          fullWidth
          value={payment.amount}
          onChange={(e) => setPayment({ ...payment, amount: e.target.value })}
        />
      </div>
      <br />
      <div className="labeledInput">
        <TextField
          label="Modalité de paiement"
          type="text"
          required
          fullWidth
          value={payment.method}
          onChange={(e) => setPayment({ ...payment, method: e.target.value })}
        />
      </div>
      <br />
      <div className="labeledInput">
        <FormControlLabel
          control={
            <Checkbox
              checked={multiple}
              onChange={(e) => setMultiple(e.target.checked)}
            />
          }
          label="Multiple ?"
        />
        {multiple ? (
          <TextField
            label="Montant multiple"
            type="number"
            min={1}
            required
            fullWidth
            value={payment.multiple}
            onChange={(e) =>
              setPayment({ ...payment, multiple: e.target.value })
            }
          />
        ) : null}
      </div>

      <br />
      {loadingForm ? (
        <CircularProgress size="33px" />
      ) : (
        <div className="btnRow">
          <Button type="submit" variant="contained">
            Ajouter
          </Button>
          &nbsp;
          <Button
            type="button"
            variant="outlined"
            onClick={() => {
              setAction("");
              resetPayment();
            }}
          >
            Annuler
          </Button>
        </div>
      )}
    </form>
  );
}

export default AddDeposit;
