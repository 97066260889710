import React, { useEffect, useState } from "react";
import { Box, Button, CircularProgress, Pagination } from "@mui/material";
import { useSelector } from "react-redux";
import TeacherDialog from "./Dialog";
import TeacherTable from "./Table";
import styles from "../../../styles/admin/Dashboard.module.css";
import { useNavigate } from "react-router-dom";
import { useSnackbar } from "notistack";
import PeopleAltIcon from "@mui/icons-material/PeopleAlt";
import TeacherFilter from "./Filter";
import axios from "axios";
import { getError } from "../../../config/config";

function Teachers(props) {
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const { userInfo } = useSelector((state) => state.auth);
  const [page, setPage] = useState(1);
  const [count, setCount] = useState(1);
  const [name, setName] = useState("");
  const [phone, setPhone] = useState("");
  const [teachers, setTeachers] = useState([]);
  const [loading, setLoading] = useState(false);
  const [loadingForm, setLoadingForm] = useState(false);
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    password: "",
    phone: "",
    sex: "",
    hourlyRate: "",
    establishment: userInfo.establishment._id,
  });
  const [selected, setSelected] = useState([]);
  const [teacher, setTeacher] = useState({});
  const [action, setAction] = useState("");
  const [notification, setNotification] = useState({
    title: "",
    description: "",
    sender: userInfo._id,
    receiver: "",
  });

  const [filters, setFilters] = useState({ name: "", phone: "" });

  useEffect(() => {
    fetchTeachers(
      userInfo,
      1,
      filters.name,
      filters.phone,
      setTeachers,
      setCount,
      setLoading
    );
  }, [filters]);

  const resetTeacher = () => {
    setTeacher({});
    setFormData({
      name: "",
      email: "",
      password: "",
      phone: "",
      sex: "",
      hourlyRate: "",
      establishment: userInfo.establishment._id,
    });
  };

  const resetNotif = () => {
    setNotification({
      title: "",
      description: "",
      sender: userInfo._id,
      receiver: "",
    });
  };

  const fetchTeachers = async () => {
    setLoading(true);
    try {
      const { data } = await axios.post(`/teacher/get`, {
        id: userInfo.establishment._id,
        page: page,
        phone: phone,
        name: name,
      });
      setTeachers(data.teachers);
      setCount(data.count);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      enqueueSnackbar(getError(error), { variant: "error" });
    }
  };

  const addTeacher = async (e) => {
    setLoadingForm(true);
    e.preventDefault();
    try {
      const { data } = await axios.post("/teacher/add", formData);
      enqueueSnackbar(data.message, { variant: "success" });
      setFormData({
        name: "",
        email: "",
        password: "",
        phone: "",
        sex: "",
        hourlyRate: "",
        establishment: userInfo.establishment._id,
      });
      fetchTeachers();
      setLoadingForm(false);
      setAction("");
    } catch (error) {
      enqueueSnackbar(getError(error), { variant: "error" });
      setLoadingForm(false);
    }
  };

  const editTeacher = async (e) => {
    setLoadingForm(true);
    e.preventDefault();
    try {
      const { data } = await axios.put("/teacher/edit", formData);
      enqueueSnackbar(data.message, { variant: "success" });
      setFormData({
        name: "",
        email: "",
        password: "",
        phone: "",
        sex: "",
        hourlyRate: "",
        establishment: userInfo.establishment._id,
      });
      fetchTeachers();
      setLoadingForm(false);
      setAction("");
    } catch (error) {
      enqueueSnackbar(getError(error), { variant: "error" });
      setLoadingForm(false);
    }
  };

  const activateTeacher = async (e) => {
    setLoadingForm(true);
    e.preventDefault();
    try {
      const { data } = await axios.put(`/teacher/activate/${teacher._id}`);
      enqueueSnackbar(data.message, { variant: "success" });
      fetchTeachers();
      setLoadingForm(false);
      setAction("");
    } catch (error) {
      enqueueSnackbar(getError(error), { variant: "error" });
      setLoadingForm(false);
    }
  };

  const blockTeacher = async (e) => {
    setLoadingForm(true);
    e.preventDefault();
    try {
      const { data } = await axios.put(`/teacher/block/${teacher._id}`);
      enqueueSnackbar(data.message, { variant: "success" });
      fetchTeachers();
      setLoadingForm(false);
      setAction("");
    } catch (error) {
      enqueueSnackbar(getError(error), { variant: "error" });
      setLoadingForm(false);
    }
  };

  const notifyTeacher = async (e) => {
    setLoadingForm(true);
    e.preventDefault();
    try {
      const { data } = await axios.post("/notification/send", notification);
      enqueueSnackbar(data.message, { variant: "success" });
      setNotification({
        sender: userInfo.establishment._id,
        senderAccount: userInfo.user._id,
        receiver: "",
        subject: "",
        message: "",
        documents: [],
      });
      setSelected("");
      setLoadingForm(false);
      setAction("");
    } catch (error) {
      enqueueSnackbar(getError(error), { variant: "error" });
      setLoadingForm(false);
    }
  };

  useEffect(() => {
    if (userInfo) fetchTeachers();
  }, [userInfo, page, name, phone]);

  return (
    <div className={styles.dashboard}>
      <TeacherDialog
        action={action}
        setAction={setAction}
        resetTeacher={resetTeacher}
        resetNotif={resetNotif}
        formData={formData}
        setFormData={setFormData}
        loadingForm={loadingForm}
        addTeacher={addTeacher}
        editTeacher={editTeacher}
        activateTeacher={activateTeacher}
        blockTeacher={blockTeacher}
        notifyTeacher={notifyTeacher}
        teacher={teacher}
        notification={notification}
        setNotification={setNotification}
        selected={selected}
        setSelected={setSelected}
      />
      <Box maxWidth="100%" margin="auto">
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <div
            style={{
              display: "flex",
              alignItems: "center",
              gap: "1em",
            }}
          >
            <PeopleAltIcon style={{ fontSize: "2.2rem", color: "#283487" }} />
            <h3 style={{ fontSize: "2rem", color: "#283487" }}>
              {" "}
              Liste des enseignants
            </h3>
          </div>
          <Button
            variant="contained"
            color="primary"
            onClick={() => setAction("ADD")}
          >
            Ajouter
          </Button>
        </div>
        <TeacherFilter
          name={name}
          setName={setName}
          phone={phone}
          setPhone={setPhone}
        />
        {loading ? (
          <div className="spinner">
            <CircularProgress />
          </div>
        ) : (
          <TeacherTable
            teachers={teachers}
            setTeacher={setTeacher}
            setFormData={setFormData}
            setAction={setAction}
            navigate={navigate}
            notification={notification}
            setNotification={setNotification}
          />
        )}
        <Box margin="30px 0" display="flex" justifyContent="center">
          <Pagination
            count={count}
            color="primary"
            page={page}
            onChange={(_, value) => setPage(value)}
          />
        </Box>
      </Box>
    </div>
  );
}

export default Teachers;
