import {
  CircularProgress,
  Tooltip,
  Modal,
  TableCell,
  Pagination,
} from "@mui/material";
import axios from "axios";
import { useSnackbar } from "notistack";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import PaymentsIcon from "@mui/icons-material/Payments";
import Receipt from "./Receipt";
import styles from "../../../styles/admin/StudentCard.module.css";
import DepositTable from "./DepositTable";

function StudentFinance(props) {
  const [page, setPage] = useState(1);
  const [count, setCount] = useState(1);

  const { enqueueSnackbar } = useSnackbar();
  const { userInfo } = useSelector((state) => state.auth);
  const { currentSeason } = useSelector((state) => state.dashboard);
  const [student, setStudent] = useState({});
  const [deposits, setDeposits] = useState([]);
  const [loadingDeposits, setLoadingDeposits] = useState(true);
  const [action, setAction] = useState("");
  const dispatch = useDispatch();
  const [deposit, setDeposit] = useState({
    establishment: userInfo?.establishment?._id,
    season: "",
    student: userInfo?._id,
    label: "",
    monthIndex: 0,
    amount: 0,
    method: "",
    multiple: 0,
  });

  const fetchStudent = async () => {
    try {
      const { data } = await axios.get(`/student/getbyid/${userInfo?._id}`);
      setStudent(data);
    } catch (error) {
      enqueueSnackbar(error.message, { variant: "error" });
    }
  };

  const fetchDeposits = async () => {
    try {
      const { data } = await axios.get(
        `/deposit/getbystudent/${userInfo?.establishment?._id}/${currentSeason?._id}/${userInfo?._id}`
      );
      setDeposits(data);
      setLoadingDeposits(false);
    } catch (error) {
      enqueueSnackbar(error.message, { variant: "error" });
      setLoadingDeposits(false);
    }
  };

  const resetDeposit = () => {
    setDeposit({
      establishment: userInfo?.establishment?._id,
      season: currentSeason?._id,
      student: userInfo?._id,
      label: "",
      monthIndex: 0,
      amount: 0,
      method: "",
      multiple: 0,
    });
  };

  useEffect(() => {
    fetchStudent();
    fetchDeposits();
  }, [currentSeason]);

  const renderDeposits = (row) => {
    const months = Array.from({ length: 12 }, (_, i) => {
      const deposit = row.deposits.find((d) => d.monthIndex === i);
      return (
        <TableCell
          key={i}
          style={deposit ? {} : { backgroundColor: "#f5f5f5" }}
        >
          {deposit ? (
            <Tooltip title="imprimer reçu">
              <div
                onClick={() => {
                  setDeposit(deposit);
                  setAction("SHOW_RECEIPT");
                }}
              >
                <div>{deposit.amount} DT</div>
                <div>{deposit.method}</div>
              </div>
            </Tooltip>
          ) : (
            <div></div>
          )}
        </TableCell>
      );
    });
    return months;
  };

  return (
    <>
      <Modal
        open={action !== ""}
        onClose={() => {
          setAction("");
          resetDeposit();
        }}
      >
        <div
          className={action === "SHOW_RECEIPT" ? "modal medium" : "modal small"}
        >
          {action === "SHOW_RECEIPT" ? (
            <Receipt
              setAction={setAction}
              deposit={deposit}
              resetDeposit={resetDeposit}
              student={student}
            />
          ) : null}
        </div>
      </Modal>
      <div className={styles.container}>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            marginBottom: "1em",
            gap: "1em",
          }}
        >
          <PaymentsIcon style={{ fontSize: "2.2rem", color: "#283487" }} />
          <h3 style={{ fontSize: "2rem", color: "#283487" }}>Paiements</h3>
        </div>

        {loadingDeposits ? (
          <div className="spinner">
            <CircularProgress size="30px" />
          </div>
        ) : (
          <DepositTable
            deposits={deposits}
            setAction={setAction}
            setDeposit={setDeposit}
            renderDeposits={renderDeposits}
          />
        )}
        <section
          style={{
            display: "flex",
            justifyContent: "center",
            marginTop: "1em",
          }}
        >
          <Pagination
            onChange={(e, page) => setPage(page)}
            page={page}
            count={count}
            color="primary"
          />
        </section>
      </div>
    </>
  );
}

export default StudentFinance;
