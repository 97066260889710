import React, { useState } from "react";
import StudentNotesTable from "./Table";
import { useStudentNotesAPI } from "./Logic";
import { useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import { useRef } from "react";
import AddNote from "./AddNote";
import EditNote from "./EditNote";
import DeleteNote from "./DeleteNote";
import { Box, Dialog, MenuItem, Select } from "@mui/material";
import NoteIcon from "@mui/icons-material/Note";
import PrintIcon from "@mui/icons-material/Print";
import Button from "@mui/material/Button";
import ReactToPrint from "react-to-print";
import axios from "axios";
import { useSnackbar } from "notistack";
import { getError } from "../../../config/config";

function StudentNotesMain() {
  const { enqueueSnackbar } = useSnackbar();
  const { currentSeason } = useSelector((state) => state.dashboard);

  const printRef = useRef(null);
  const location = useLocation();
  const id = location.state?.id || "";

  const [period, setPeriod] = useState(
    currentSeason?.type === "trimestrielle" ? "trimestre 1" : "semestre 1"
  );
  const {
    subjects,
    notes,
    total,
    totalCoefficient,
    moyenne,
    setNote,
    setAction,
    note,
    loadingForm,
    fetchNotes,
    action,
    editNote,
    addNote,
    deleteNote,
  } = useStudentNotesAPI(id, currentSeason, period);

  const handleToggleChange = async (session) => {
    console.log("session", session);
    try {
      const { data } = await axios.put(`/note/publish`, session);
      enqueueSnackbar(data.message, { variant: "success" });
      fetchNotes();
    } catch (error) {
      enqueueSnackbar(getError(error), { variant: "error" });
    }
  };
  return (
    <>
      <Dialog
        open={action !== ""}
        onClose={() => setAction("")}
        fullWidth
        maxWidth="sm"
      >
        <Box
          sx={{
            padding: 2,
            display: "flex",
            flexDirection: "column",
            gap: 2,
          }}
        >
          {action === "ADD_NOTE" ? (
            <AddNote
              addNote={addNote}
              setAction={setAction}
              note={note}
              setNote={setNote}
              loadingForm={loadingForm}
            />
          ) : action === "EDIT_NOTE" ? (
            <EditNote
              editNote={editNote}
              setAction={setAction}
              note={note}
              setNote={setNote}
              loadingForm={loadingForm}
            />
          ) : action === "DELETE_NOTE" ? (
            <DeleteNote
              deleteNote={deleteNote}
              setAction={setAction}
              note={note}
              setNote={setNote}
              loadingForm={loadingForm}
            />
          ) : null}
        </Box>
      </Dialog>

      <Box
        sx={{
          padding: 2,
          display: "flex",
          flexDirection: "column",
          gap: 2,
        }}
      >
        {currentSeason?.type === "trimestrielle" && (
          <div
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "space-between",
              alignContent: "center",
              padding: "10px",
              marginBottom: "10px",
            }}
          >
            <div
              style={{
                display: "flex",
                alignItems: "center",
                gap: "1em",
              }}
            >
              <NoteIcon style={{ fontSize: "2.2rem", color: "#283487" }} />
              <h3 style={{ fontSize: "2rem", color: "#283487" }}>
                Relevés des notes
              </h3>
            </div>
            <Select
              required
              value={period}
              onChange={(e) => {
                setPeriod(e.target.value);
              }}
              sx={{
                backgroundColor: "transparent",
                height: "50px",
                margin: "auto 0",
                width: "20%",
              }}
            >
              <MenuItem
                style={{
                  marginBottom: "5px",
                }}
                value={"trimestre 1"}
              >
                trimestre 1
              </MenuItem>
              <MenuItem
                style={{
                  marginBottom: "5px",
                }}
                value={"trimestre 2"}
              >
                trimestre 2
              </MenuItem>
              <MenuItem
                style={{
                  marginBottom: "5px",
                }}
                value={"trimestre 3"}
              >
                trimester 3
              </MenuItem>
            </Select>
          </div>
        )}
        {currentSeason?.type === "semestrielle" && (
          <div
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "space-between",
              alignContent: "center",
              padding: "10px",
            }}
          >
            <div
              style={{
                display: "flex",
                alignItems: "center",
                gap: "1em",
              }}
            >
              <NoteIcon style={{ fontSize: "2.2rem", color: "#283487" }} />
              <h3 style={{ fontSize: "2rem", color: "#283487" }}>
                Relevés des notes
              </h3>
            </div>

            <Select
              required
              value={period}
              onChange={(e) => {
                setPeriod(e.target.value);
              }}
              sx={{
                backgroundColor: "transparent",
                height: "50px",
                margin: "auto 0",
                width: "20%",
              }}
            >
              <MenuItem
                style={{
                  marginBottom: "5px",
                }}
                value={"semestre 1"}
              >
                semestre 1
              </MenuItem>
              <MenuItem
                style={{
                  marginBottom: "5px",
                }}
                value={"semestre 2"}
              >
                semestre 2
              </MenuItem>
            </Select>
          </div>
        )}

        <Box
          style={{
            display: "flex",
            gap: "1em",
            alignItems: "center",
          }}
        >
          <Button
            variant="contained"
            sx={{
              borderRadius: "0px",
              background: notes[0]?.isPublished ? "#43A047" : "#FFCA00",
              "&:hover": {
                background: notes[0]?.isPublished ? "#2E7D32" : "#FFB300",
              },
            }}
            onClick={() => {
              if (notes[0]) {
                handleToggleChange({
                  ...notes[0],
                  isPublished: !notes[0].isPublished,
                });
              }
            }}
          >
            {notes[0]?.isPublished ? "Publié" : "Non publié"}
          </Button>
        </Box>
        <StudentNotesTable
          subjects={subjects}
          notes={notes}
          period={period}
          total={total}
          totalCoefficient={totalCoefficient}
          moyenne={moyenne}
          setPeriod={setPeriod}
          setAction={setAction}
          setNote={setNote}
          fetchNotes={fetchNotes}
          action={action}
          note={note}
          loadingForm={loadingForm}
          id={id}
          currentSeason={currentSeason}
          printRef={printRef}
        />

        <div
          style={{
            display: "flex",
            justifyContent: "end",
          }}
        >
          <ReactToPrint
            trigger={() => (
              <Button
                variant="contained"
                color="primary"
                startIcon={<PrintIcon />}
                style={{
                  backgroundColor: "#283487",
                  color: "#FFF",
                  padding: "0.5em 1em",
                  borderRadius: "0px",
                  textTransform: "none",
                  fontSize: "1rem",
                  fontWeight: "bold",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                Imprimer
              </Button>
            )}
            content={() => printRef.current}
          />
        </div>
      </Box>
    </>
  );
}

export default StudentNotesMain;
