import React from "react";
import {
  Grid,
  Button,
  CircularProgress,
  TextField,
  Typography,
  Box,
} from "@mui/material";

function NoteForm(props) {
  const { onSubmit, note, setNote, actionType, loadingForm, setAction } = props;

  return (
    <Box
      sx={{
        margin: "2em",
      }}
    >
      <Typography
        variant="h6"
        gutterBottom
        sx={{
          color: "#283487",
          fontWeight: "bold",
          textAlign: "center",
          marginBottom: "20px",
        }}
      >
        {actionType === "add" ? "Ajouter une note" : "Modifier une note"}
      </Typography>
      <form onSubmit={onSubmit}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <TextField
              label="Type"
              required
              fullWidth
              value={note.type}
              onChange={(e) => setNote({ ...note, type: e.target.value })}
              type="text"
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              label="Note"
              required
              fullWidth
              value={note.note}
              onChange={(e) => setNote({ ...note, note: e.target.value })}
              type="text"
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              label="Coefficient"
              required
              fullWidth
              value={note.coefficient}
              onChange={(e) =>
                setNote({ ...note, coefficient: e.target.value })
              }
              type="text"
            />
          </Grid>
          <Grid item xs={12}>
            {loadingForm ? (
              <CircularProgress size="32px" />
            ) : (
              <div
                style={{
                  display: "flex",
                  justifyContent: "flex-end",
                  width: "100%",
                }}
              >
                <Button variant="contained" type="submit">
                  {actionType === "add" ? "Ajouter" : "Modifier"}
                </Button>
                &nbsp;
                <Button
                  variant="outlined"
                  type="button"
                  onClick={() => {
                    setNote({});
                    setAction("");
                  }}
                >
                  Annuler
                </Button>
              </div>
            )}
          </Grid>
        </Grid>
      </form>
    </Box>
  );
}

export default NoteForm;
