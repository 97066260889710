import {
  Button,
  CircularProgress,
  TextField,
  Box,
  Typography,
  IconButton,
} from "@mui/material";
import UploadFileIcon from "@mui/icons-material/UploadFile";
import React from "react";
import { compressPdf } from "../../../config/config";

function NotifyStudent(props) {
  const {
    loadingForm,
    student,
    notifyStudent,
    notification,
    selected,
    setSelected,
    setNotification,
    setAction,
    resetNotif,
  } = props;

  return (
    <form onSubmit={notifyStudent}>
      <Typography variant="h5" component="h1" gutterBottom>
        Notifier l&apos;élève:&nbsp;{student?.user?.name}
      </Typography>

      <Box mb={2}>
        <TextField
          fullWidth
          label="Sujet"
          required
          value={notification?.subject}
          onChange={(e) =>
            setNotification({ ...notification, subject: e.target.value })
          }
        />
      </Box>

      <Box mb={2}>
        <TextField
          fullWidth
          label="Message"
          multiline
          rows={5}
          required
          value={notification?.message}
          onChange={(e) =>
            setNotification({ ...notification, message: e.target.value })
          }
        />
      </Box>

      <Box mb={2}>
        <label htmlFor="pdf">
          {selected?.length > 0 ? (
            <Box display="flex" flexWrap="wrap">
              {selected?.map((s, index) => (
                <Typography
                  key={index}
                  variant="body2"
                  sx={{ mr: 1, mb: 1, display: "inline-block" }}
                >
                  {s}
                </Typography>
              ))}
            </Box>
          ) : (
            <Box display="flex" alignItems="center" sx={{ cursor: "pointer" }}>
              <Typography variant="body2" component="span">
                Ajouter document
              </Typography>
              <IconButton color="primary" component="span">
                <UploadFileIcon />
              </IconButton>
            </Box>
          )}
        </label>
        <input
          accept="application/pdf, application/doc, application/docx, application/ppt, application/pptx, image/*"
          id="pdf"
          multiple
          hidden
          type="file"
          onChange={async (e) => {
            const files = [];
            const fileNames = [];
            for (let i = 0; i < e.target.files.length; i++) {
              fileNames.push(e.target.files[i].name);
              files.push(await compressPdf(e.target.files[i]));
            }
            setSelected(fileNames);
            setNotification({
              ...notification,
              documents: files,
            });
          }}
        />
      </Box>

      {loadingForm ? (
        <CircularProgress size={35} />
      ) : (
        <Box display="flex" gap={2}>
          <Button type="submit" variant="contained">
            Envoyer
          </Button>
          <Button
            type="button"
            onClick={() => {
              setNotification({
                receiver: "",
                subject: "",
                message: "",
                documents: [],
              });
              setSelected([]);
              resetNotif();
              setAction("");
            }}
            variant="outlined"
          >
            Annuler
          </Button>
        </Box>
      )}
    </form>
  );
}

export default NotifyStudent;
