import React, { useState } from "react";
import styles from "../styles/Navbar.module.css";
import { HashLink } from "react-router-hash-link";
import CloseIcon from "@mui/icons-material/Close";
import { Button, CircularProgress, Drawer, Modal } from "@mui/material";
import { useSnackbar } from "notistack";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import { getError } from "../config/config.js";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import "./Navbar.css";
import { useNavigate } from "react-router-dom";
function Navbar(props) {
  const [menuOpen, setMenuOpen] = useState(false);
  const [loginOpen, setLoginOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [passwordType, setPasswordType] = useState("password");
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [formData, setFormData] = useState({ email: "", password: "" });
  const { email, password } = formData;
  const { userInfo } = useSelector((state) => state.auth);

  const login = async (e) => {
    setLoading(true);
    e.preventDefault();
    try {
      const { data } = await axios.post("/login", formData);
      dispatch({ type: "USER_LOGIN", payload: data });
      enqueueSnackbar("Connecté avec succès", { variant: "success" });

      setLoading(false);
      setLoginOpen(false);
      console.log(userInfo);
      console.log("data", data);
      switch (userInfo?.user?.role || data.user.role) {
        case "STUDENT":
          navigate("/gestion-scolaire");
          break;
        case "TEACHER":
          navigate("/emploi-du-temps");
          break;
        case "ADMIN":
          navigate("/dashboard");
          break;
        case "SUPER-ADMIN":
          navigate("/home");
          break;
        default:
          break;
      }
    } catch (error) {
      enqueueSnackbar(getError(error), { variant: "error" });
      setLoading(false);
    }
  };

  return (
    <>
      <Drawer anchor="top" open={menuOpen} onClose={() => setMenuOpen(false)}>
        <div className={styles.mobileMenu}>
          <p>
            <CloseIcon onClick={() => setMenuOpen(false)} />
          </p>
          <HashLink onClick={() => setMenuOpen(false)} to="/#who-are-we">
            <p>Qui sommes nous</p>
          </HashLink>
          <HashLink onClick={() => setMenuOpen(false)} to="/#our-values">
            <p>Nos valeurs</p>
          </HashLink>
        </div>
      </Drawer>
      <Modal onClose={() => setLoginOpen(false)} open={loginOpen}>
        <form onSubmit={login} className={styles.login}>
          <div className={styles.labeledInput}>
            <label>Email</label>
            <input
              required
              type="email"
              value={email}
              onChange={(e) =>
                setFormData({ ...formData, email: e.target.value })
              }
            />
          </div>
          <div className={styles.labeledInput}>
            <label>Mot de passe</label>
            <div
              style={{ width: "100%", display: "flex", alignItems: "center" }}
            >
              <input
                required
                type={passwordType}
                value={password}
                onChange={(e) =>
                  setFormData({ ...formData, password: e.target.value })
                }
              />
              <Button
                onClick={() => {
                  passwordType === "password"
                    ? setPasswordType("text")
                    : setPasswordType("password");
                }}
                sx={{ marginLeft: "-65px" }}
              >
                {passwordType === "password" ? (
                  <RemoveRedEyeIcon />
                ) : (
                  <VisibilityOffIcon />
                )}
              </Button>
            </div>
          </div>
          <div className={styles.labeledInput}>
            <button
              className={styles.button}
              style={{ width: "100%", justifyContent: "center" }}
            >
              {loading ? <CircularProgress size={"20px"} /> : "Se connecter"}
            </button>
          </div>
        </form>
      </Modal>
      <div className="navbar">
        <div className="navbar-logo">
          <HashLink to="/">
            <img alt="school-hub" src="./logo.svg" />
          </HashLink>
        </div>
        <button onClick={() => setLoginOpen(true)} className="navbar-button">
          SE CONNECTER
        </button>
      </div>
    </>
  );
}

export default Navbar;
