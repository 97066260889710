import { useState, useEffect } from "react";
import axios from "axios";
import { useSnackbar } from "notistack";
import { useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import EditIcon from "@mui/icons-material/Edit";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import Tooltip from "@mui/material/Tooltip";

import { IconButton, TableCell } from "@mui/material";

export const useLogic = () => {
  const { enqueueSnackbar } = useSnackbar();
  const location = useLocation();
  const id = location.state?.id;
  const userType = location.state?.userType;
  const { userInfo } = useSelector((state) => state.auth);
  const { currentSeason } = useSelector((state) => state.dashboard);
  const [student, setUser] = useState({});
  const [deposits, setDeposits] = useState([]);
  const [loadingCard, setLoadingCard] = useState(true);
  const [loadingForm, setLoadingForm] = useState(false);
  const [loadingDeposits, setLoading] = useState(true);
  const [label, setLabel] = useState("");
  const [action, setAction] = useState("");
  console.log("student", student);
  const [payment, setPayment] = useState({
    establishment: userInfo.establishment._id,
    season: "",
    admin: userInfo._id,
    student: id,
    label: "",
    monthIndex: 0,
    amount: 0,
    method: "",
    multiple: 0,
  });

  const resetPayment = () => {
    setPayment({
      establishment: userInfo.establishment._id,
      season: currentSeason._id,
      admin: userInfo._id,
      student: id,
      label: "",
      monthIndex: 0,
      amount: 0,
      method: "",
      multiple: 0,
    });
  };

  const fetchUserData = async () => {
    const endpoint =
      userType === "STUDENT"
        ? `/student/getbyid/${id}`
        : `/teacher/getbyid/${id}`;
    setLoadingCard(true);
    try {
      const { data } = await axios.get(endpoint);
      setUser(data);
    } catch (error) {
      enqueueSnackbar(error.message, { variant: "error" });
    } finally {
      setLoadingCard(false);
    }
  };

  const fetchPayments = async () => {
    const endpoint =
      userType === "STUDENT"
        ? `/deposit/getbystudent/${userInfo?.establishment._id}/${currentSeason?._id}/${id}`
        : `/withdrawal/getbyteacher/${userInfo?.establishment._id}/${currentSeason?._id}/${id}`;
    setLoading(true);
    try {
      const { data } = await axios.get(endpoint);
      setDeposits(data);
    } catch (error) {
      enqueueSnackbar(error.message, { variant: "error" });
    } finally {
      setLoading(false);
    }
  };

  const addRow = (e) => {
    e.preventDefault();
    setLoadingForm(true);
    try {
      setDeposits([
        ...deposits,
        {
          label: label,
          deposits: [
            {
              monthIndex: 0,
            },
            {
              monthIndex: 1,
            },
            {
              monthIndex: 2,
            },
            {
              monthIndex: 3,
            },
            {
              monthIndex: 4,
            },
            {
              monthIndex: 5,
            },
            {
              monthIndex: 6,
            },
            {
              monthIndex: 7,
            },
            {
              monthIndex: 8,
            },
            {
              monthIndex: 9,
            },
            {
              monthIndex: 10,
            },
            {
              monthIndex: 11,
            },
          ],
        },
      ]);
      setAction("");
      setLabel("");
      setLoadingForm(false);
    } catch (error) {
      console.log(error);
    }
  };

  const addPayment = async () => {
    const endpoint =
      userType === "STUDENT" ? "/deposit/add" : "/withdrawal/add";
    try {
      const { data } = await axios.post(endpoint, payment);
      enqueueSnackbar(data.message, { variant: "success" });
      setAction("");
      resetPayment();
      fetchPayments();
    } catch (error) {
      enqueueSnackbar(error.message, { variant: "error" });
    }
  };

  const editPayment = async () => {
    const endpoint =
      userType === "STUDENT" ? "/deposit/edit" : "/withdrawal/edit";
    try {
      const { data } = await axios.put(endpoint, payment);
      enqueueSnackbar(data.message, { variant: "success" });
      setAction("");
      resetPayment();
      fetchPayments();
    } catch (error) {
      enqueueSnackbar(error.message, { variant: "error" });
    }
  };

  const deletePayment = async () => {
    const endpoint =
      userType === "STUDENT"
        ? `/deposit/remove/${payment.id}`
        : `/withdrawal/remove/${payment.id}`;
    try {
      const { data } = await axios.post(endpoint);
      enqueueSnackbar(data.message, { variant: "success" });
      setAction("");
      resetPayment();
      fetchPayments();
    } catch (error) {
      enqueueSnackbar(error.message, { variant: "error" });
    }
  };

  useEffect(() => {
    fetchUserData();
  }, [id]);

  useEffect(() => {
    fetchPayments();
  }, [currentSeason]);

  const findPaymentByMonth = (row, userType, monthIndex) => {
    if (userType === "STUDENT") {
      return row.deposits?.find((d) => d.monthIndex === monthIndex);
    } else if (userType === "TEATCHER") {
      return row.withdrawals?.find((w) => w.monthIndex === monthIndex);
    }
    return null;
  };

  const renderDeposits = (row) => {
    var block = [];
    for (let i = 0; i < 12; i++) {
      const payment = findPaymentByMonth(row, userType, i);

      if (payment?.amount) {
        block = [
          ...block,
          <Tooltip key={i} title="imprimer reçu">
            <TableCell
              onClick={() => {
                setPayment(payment);
                setAction("SHOW_RECEIPT");
              }}
            >
              <div>{payment.amount} DT</div>
              <div>{payment.method}</div>
              <IconButton
                onClick={(e) => {
                  e.stopPropagation();
                  setPayment(payment);
                  setAction("EDIT_DEPOSIT");
                }}
                size="small"
                sx={{ color: "orange" }}
              >
                <EditIcon sx={{ width: "15px", height: "15px" }} />
              </IconButton>
              <IconButton
                onClick={(e) => {
                  e.stopPropagation();
                  setPayment(payment);
                  setAction("DELETE_DEPOSIT");
                }}
                size="small"
                sx={{ color: "red" }}
              >
                <DeleteOutlineIcon sx={{ width: "15px", height: "15px" }} />
              </IconButton>
            </TableCell>
          </Tooltip>,
        ];
      } else {
        block = [
          ...block,
          <TableCell key={i} value={i}>
            <IconButton
              onClick={() => {
                setPayment({
                  ...payment,
                  season: currentSeason._id,
                  label: row.label,
                  monthIndex: i,
                });
                setAction("ADD_DEPOSIT");
              }}
              size="small"
              sx={{ color: "green" }}
            >
              <AddCircleOutlineIcon sx={{ width: "15px", height: "15px" }} />
            </IconButton>
          </TableCell>,
        ];
      }
    }
    return block;
  };

  return {
    student,
    deposits,
    loadingCard,
    loadingDeposits,
    loadingForm,
    label,
    action,
    payment,
    setAction,
    setLabel,
    setPayment,
    addRow,
    addPayment,
    editPayment,
    deletePayment,
    renderDeposits,
    resetPayment,
  };
};
