import React from "react";
import "./ContactSection.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Button, Typography } from "@mui/material";
import { Box, TextField } from "@mui/material";

const ContactSection = () => {
  return (
    <div className="contact-section">
      <Typography
        variant="h4"
        align="left"
        className="contact-title"
        data-aos="fade-left"
        data-aos-delay="300"
      >
        CONTACTEZ-NOUS
      </Typography>
      <div
        className="contact-underline"
        data-aos="fade-right"
        data-aos-delay="600"
      ></div>
      <div className="contact">
        <div className="contact-text">
          <Typography
            variant="body1"
            className="contact-text-description"
            data-aos="fade-left"
            data-aos-delay="700"
          >
            Si vous avez des questions ou si vous souhaitez commander une
            consultation, laissez une demande et notre consultant vous
            contactera dans un proche avenir.
          </Typography>
        </div>
        <div className="contact-form">
          <Box
            component="form"
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: 2,
            }}
          >
            <TextField label="Nom" variant="outlined" fullWidth />
            <TextField
              label="Email"
              type="email"
              variant="outlined"
              fullWidth
            />
            <TextField
              label="Message"
              variant="outlined"
              multiline
              rows={4}
              fullWidth
            />
            <Box
              sx={{
                display: "flex",
                justifyContent: "flex-end",
                width: "100%",
              }}
            >
              <Button
                variant="contained"
                color="primary"
                sx={{ width: "200px", borderRadius: "0 !important" }}
              >
                Envoyer
              </Button>
            </Box>
          </Box>
        </div>
      </div>
    </div>
  );
};

export default ContactSection;
