import AccountBalanceWalletIcon from "@mui/icons-material/AccountBalanceWallet";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import MeetingRoomIcon from "@mui/icons-material/MeetingRoom";
import PeopleAltIcon from "@mui/icons-material/PeopleAlt";
import SchoolIcon from "@mui/icons-material/School";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import styles from "../../styles/admin/Home.module.css";
import CandleChart from "./components/CandleChart";
import LineChart from "./components/LineChart";

function Home(props) {
  const { userInfo } = useSelector((state) => state.auth);
  const { currentSeason } = useSelector((state) => state.dashboard);

  const [count, setCount] = useState({ students: 0, teachers: 0, classes: 0 });
  const [wallet, setWallet] = useState({ deposits: 0, withdrawals: 0 });
  const fetchData = async () => {
    const { data } = await axios.get(
      `/stats/header/${userInfo?.establishment?._id}/${currentSeason?._id}/${currentSeason?.year}`
    );
    setCount(data);
  };

  const fetchWalletData = async () => {
    const { data } = await axios.get(
      `/stats/wallet/${userInfo?.establishment?._id}/${currentSeason?._id}`
    );
    setWallet({ ...data, withdrawals: data?.autres + data?.fournitures });
  };

  useEffect(() => {
    if (currentSeason) {
      fetchData();
      fetchWalletData();
    }
  }, [currentSeason]);

  return (
    <div className={styles.container}>
      <h1>Bienvenue {userInfo.user.name} !</h1>
      <div className={styles.header}>
        <div className={styles.card}>
          <p className={styles.cardText}>Etudiants</p>
          <h1>{count.students}</h1>
          <div className={styles.icon}>
            <SchoolIcon
              sx={{ width: "50px", height: "50px" }}
              color="primary"
            />
          </div>
        </div>
        <div className={styles.card}>
          <p className={styles.cardText}>Enseignants</p>
          <h1>{count.teachers}</h1>
          <div className={styles.icon}>
            <PeopleAltIcon
              sx={{ width: "50px", height: "50px" }}
              color="primary"
            />
          </div>
        </div>
        <div className={styles.card}>
          <p className={styles.cardText}>Classes</p>
          <h1>{count.classes}</h1>
          <div className={styles.icon}>
            <MeetingRoomIcon
              sx={{ width: "50px", height: "50px" }}
              color="primary"
            />
          </div>
        </div>
        <div className={styles.card}>
          <p className={styles.cardText}>Dépenses</p>
          <h1 style={{ display: "flex", alignItems: "center" }}>
            {wallet?.deposits - wallet?.withdrawals}&nbsp;DT&nbsp;
            {wallet?.deposits - wallet?.withdrawals > 0 ? (
              <ArrowDropUpIcon
                style={{
                  color: "green",
                  width: "30px",
                  height: "30px",
                  marginTop: "-10px",
                }}
              />
            ) : (
              <ArrowDropDownIcon
                style={{
                  color: "red",
                  width: "30px",
                  height: "30px",
                  marginTop: "-10px",
                }}
              />
            )}
          </h1>
          <div className={styles.icon}>
            <AccountBalanceWalletIcon
              sx={{ width: "50px", height: "50px" }}
              color="primary"
            />
          </div>
        </div>
      </div>
      <div className={styles.row}>
        <div className={styles.col60}>
          <LineChart />
        </div>
        <div className={styles.col40}>
          <CandleChart />
        </div>
      </div>
    </div>
  );
}

export default Home;
