import React, { useState } from "react";
import WithdrawalForm from "./Form";

function AddWithdrawal(props) {
  const [multiple, setMultiple] = useState(false);
  const {
    addWithdrawal,
    setAction,
    withdrawal,
    setWithdrawal,
    resetWithdrawal,
    loadingForm,
  } = props;

  return (
    <>
      {/* add title */}
      <h2>Ajouter un retrait</h2>
      <WithdrawalForm
        onSubmit={addWithdrawal}
        withdrawal={withdrawal}
        setWithdrawal={setWithdrawal}
        resetWithdrawal={() => {
          setAction("");
          resetWithdrawal();
        }}
        loadingForm={loadingForm}
        multiple={multiple}
        setMultiple={setMultiple}
      />
    </>
  );
}

export default AddWithdrawal;
