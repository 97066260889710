import React, { useState } from "react";
import { Drawer, Avatar, Typography, Box, Grid } from "@mui/material";
import DrawerItems from "./DrawerItems";
import { drawerWidth } from "../utils/Constants";
import { useSelector } from "react-redux";

const Sidebar = ({ isDrawerOpen, toggleDrawer, isMobile }) => {
  const { userInfo } = useSelector((state) => state.auth);
  const [selectedPage, setSelectedPage] = useState("/");

  return (
    <Drawer
      variant={isMobile ? "temporary" : "permanent"}
      open={isMobile ? isDrawerOpen : true}
      onClose={toggleDrawer}
      sx={{
        "& .MuiDrawer-paper": {
          width: drawerWidth,
          backgroundColor: "#3748BA",
          color: "white",
          zIndex: 1000,
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
        },
      }}
    >
      <DrawerItems
        selectedPage={selectedPage}
        setSelectedPage={setSelectedPage}
      />

      <Box
        sx={{
          padding: "16px",
          backgroundColor: "#283593",
          textAlign: "left",
          position: "relative",
        }}
      >
        <Grid container>
          <Grid item xs={3}>
            <Avatar
              src={userInfo?.user?.avatar || "/default-avatar.png"}
              alt={userInfo?.name}
              sx={{ width: 30, height: 30 }}
            />
          </Grid>
          <Grid item xs={9}>
            <Typography
              variant="h6"
              sx={{
                textAlign: "left",
                fontSize: "1rem",
                fontWeight: 600,
              }}
            >
              {userInfo?.user.name}
            </Typography>
          </Grid>
        </Grid>
        {/* 
        <Typography variant="body2">{userInfo?.user.email}</Typography>
        <Typography variant="caption">{userInfo?.user.role}</Typography> */}
      </Box>
    </Drawer>
  );
};

export default Sidebar;
