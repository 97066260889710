import axios from "axios";

export const fetchTeacher = async (props) => {
  const { id, setLoadingCard, setTeacher, enqueueSnackbar } = props;
  try {
    const { data } = await axios.get(`/teacher/getbyid/${id}`);
    setTeacher(data);
    setLoadingCard(false);
  } catch (error) {
    enqueueSnackbar(error.message, { variant: "error" });
    setLoadingCard(false);
  }
};

export const fetchWithdrawals = async (props) => {
  const {
    id,
    setLoadingWithdrawals,
    setWithdrawals,
    enqueueSnackbar,
    userInfo,
    currentSeason,
  } = props;
  try {
    const { data } = await axios.get(
      `/withdrawal/getbyteacher/${userInfo?.establishment._id}/${currentSeason?._id}/${id}`
    );
    setWithdrawals(data);
    setLoadingWithdrawals(false);
  } catch (error) {
    enqueueSnackbar(error.message, { variant: "error" });
    setLoadingWithdrawals(false);
  }
};

export const addWithdrawal = async (props) => {
  const {
    e,
    withdrawal,
    setAction,
    resetWithdrawal,
    enqueueSnackbar,
    withdrawals,
  } = props;
  e.preventDefault();
  var exists = false;
  const row = withdrawals.find((w) => w.label === withdrawal.label);
  for (let i = 0; i < row.withdrawals.length; i++) {
    if (
      Number(withdrawal.monthIndex) < Number(row.withdrawals[i].monthIndex) &&
      Number(row.withdrawals[i].monthIndex) <
        Number(withdrawal.multiple) + Number(withdrawal.monthIndex)
    ) {
      exists = true;
    }
  }
  try {
    if (Number(withdrawal.multiple) + Number(withdrawal.monthIndex) > 12) {
      return enqueueSnackbar("ne dépasser pas les mois disponible !", {
        variant: "warning",
      });
    }
    if (row.length > 0 && exists) {
      return enqueueSnackbar("respecter les mois déjà configurer !", {
        variant: "warning",
      });
    }
    const { data } = await axios.post("/withdrawal/add", withdrawal);
    enqueueSnackbar(data.message, { variant: "success" });
    setAction("");
    resetWithdrawal();
    fetchWithdrawals();
  } catch (error) {
    enqueueSnackbar(error.message, { variant: "error" });
  }
};

export const editWithdrawal = async (props) => {
  const { e, withdrawal, setAction, resetWithdrawal, enqueueSnackbar } = props;
  e.preventDefault();
  try {
    const { data } = await axios.put("/withdrawal/edit", withdrawal);
    enqueueSnackbar(data.message, { variant: "success" });
    setAction("");
    resetWithdrawal();
    fetchWithdrawals();
  } catch (error) {
    enqueueSnackbar(error.message, { variant: "error" });
  }
};

export const deleteWithdrawal = async (props) => {
  const { withdrawal, setAction, resetWithdrawal, enqueueSnackbar } = props;
  try {
    const { data } = await axios.post(`/withdrawal/remove/${withdrawal._id}`);
    enqueueSnackbar(data.message, { variant: "success" });
    setAction("");
    resetWithdrawal();
    fetchWithdrawals();
  } catch (error) {
    enqueueSnackbar(error.message, { variant: "error" });
  }
};
