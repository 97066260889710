import React from "react";
import WithdrawalForm from "./Form";

function EditWithdrawal(props) {
  const {
    editWithdrawal,
    setAction,
    withdrawal,
    setWithdrawal,
    resetWithdrawal,
    loadingForm,
  } = props;

  return (
    <>
      <h2>Modifier le retrait</h2>
      <WithdrawalForm
        onSubmit={editWithdrawal}
        withdrawal={withdrawal}
        setWithdrawal={setWithdrawal}
        resetWithdrawal={() => {
          setAction("");
          resetWithdrawal();
        }}
        loadingForm={loadingForm}
        isEdit
      />
    </>
  );
}

export default EditWithdrawal;
