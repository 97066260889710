import { configureStore, applyMiddleware } from "@reduxjs/toolkit";
import thunk from "redux-thunk";
import auth from "./reducers/auth";
import dashboard from "./reducers/dashboard";
import messenger from "./reducers/messenger";

export default configureStore(
  {
    reducer: {
      auth: auth,
      dashboard: dashboard,
      messenger: messenger,
    },
  },
  applyMiddleware(thunk)
);
