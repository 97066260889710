import { Button, CircularProgress } from "@mui/material";
import React from "react";

function StudentAction({
  actionType,
  loadingForm,
  setAction,
  handleAction,
  student,
}) {
  const handleSubmit = (event) => {
    event.preventDefault();
    handleAction(student._id);
  };

  return (
    <form onSubmit={handleSubmit}>
      <h1>
        {actionType === "ACTIVATE" ? "Activer" : "Bloquer"}{" "}
        l&apos;étudiant:&nbsp;{student.user.name}
      </h1>
      <div className="row">
        {loadingForm ? (
          <CircularProgress size="35px" />
        ) : (
          <>
            <Button type="submit" variant="contained">
              {actionType === "ACTIVATE" ? "Activer" : "Bloquer"}
            </Button>
            &nbsp;
            <Button
              type="button"
              onClick={() => {
                setAction("");
              }}
              variant="outlined"
            >
              Annuler
            </Button>
          </>
        )}
      </div>
    </form>
  );
}

export default StudentAction;
