import { UPDATE_ROOM } from "../actions";

const messengerReducer = (
  state = {
    chat: localStorage.getItem("chat")
      ? JSON.parse(localStorage.getItem("chat"))
      : null,
  },
  action
) => {
  switch (action.type) {
    case UPDATE_ROOM:
      localStorage.setItem("chat", JSON.stringify(action.payload));
      return { ...state, chat: action.payload };

    default:
      return state;
  }
};

export default messengerReducer;
