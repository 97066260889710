import React from "react";
import {
  Button,
  CircularProgress,
  TextField,
  IconButton,
  Typography,
  Box,
} from "@mui/material";
import UploadFileIcon from "@mui/icons-material/UploadFile";
import { compressPdf } from "../../../config/config";

function NotifyTeacher(props) {
  const {
    loadingForm,
    teacher,
    notifyTeacher,
    notification,
    selected,
    setSelected,
    setNotification,
    setAction,
    resetNotif,
  } = props;

  const handleFileUpload = async (e) => {
    let files = [];
    let fileNames = [];
    for (let i = 0; i < e.target.files.length; i++) {
      fileNames.push(e.target.files[i].name);
      files.push(await compressPdf(e.target.files[i]));
    }
    setSelected(fileNames);
    setNotification({
      ...notification,
      documents: files,
    });
  };

  return (
    <form onSubmit={notifyTeacher}>
      <Typography
        variant="h6"
        gutterBottom
        sx={{
          color: "#283487",
          fontWeight: "bold",
          textAlign: "center",
          marginBottom: "20px",
        }}
      >
        Notifier l&apos;enseignant :{" "}
        <span
          style={{
            textTransform: "capitalize",
          }}
        >
          {teacher.user.name}
        </span>
      </Typography>

      <TextField
        required
        fullWidth
        label="Sujet"
        variant="outlined"
        value={notification.subject}
        onChange={(e) => {
          setNotification({ ...notification, subject: e.target.value });
        }}
        margin="normal"
      />

      <TextField
        required
        fullWidth
        label="Message"
        multiline
        rows={5}
        variant="outlined"
        value={notification.message}
        onChange={(e) => {
          setNotification({ ...notification, message: e.target.value });
        }}
        margin="normal"
      />

      {/* File Upload */}
      <Box my={2}>
        <label htmlFor="pdf-upload">
          <IconButton component="span">
            <UploadFileIcon />
          </IconButton>
          <Typography variant="body1" display="inline">
            {selected?.length > 0
              ? selected.map((fileName, index) => (
                  <span key={index} style={{ marginRight: "10px" }}>
                    {fileName}
                  </span>
                ))
              : "Ajouter un document"}
          </Typography>
        </label>
        <input
          accept="application/pdf, application/doc, application/docx, application/ppt, application/pptx, image/*"
          id="pdf-upload"
          multiple
          type="file"
          hidden
          onChange={handleFileUpload}
        />
      </Box>

      {/* Buttons */}
      {loadingForm ? (
        <CircularProgress size="35px" />
      ) : (
        <Box mt={2}>
          <Button type="submit" variant="contained" color="primary">
            Envoyer
          </Button>
          &nbsp;
          <Button
            variant="outlined"
            color="secondary"
            onClick={() => {
              setNotification({
                receiver: "",
                subject: "",
                message: "",
                documents: [],
              });
              setSelected([]);
              resetNotif();
              setAction("");
            }}
          >
            Annuler
          </Button>
        </Box>
      )}
    </form>
  );
}

export default NotifyTeacher;
