import React from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  IconButton,
  Tooltip,
  Typography,
} from "@mui/material";
import FindInPageIcon from "@mui/icons-material/FindInPage";
import DescriptionIcon from "@mui/icons-material/Description";
import SettingsIcon from "@mui/icons-material/Settings";
import NotificationsActiveIcon from "@mui/icons-material/NotificationsActive";
import { useNavigate } from "react-router-dom";

export default function StudentTable({
  students,
  setStudent,
  setFormData,
  setAction,
  setNotification,
  notification,
  currentClassroom,
}) {
  const navigate = useNavigate();

  return (
    <TableContainer sx={{ maxHeight: "calc(100vh - 401px)" }}>
      <Table stickyHeader>
        <TableHead>
          <TableRow>
            <TableCell colSpan={5}>
              <Typography variant="h6" sx={{ fontWeight: "bold" }}>
                {" "}
                Niveau : {currentClassroom.level} - Groupe :{" "}
                {currentClassroom.group}
              </Typography>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>Nom et prénom</TableCell>
            <TableCell>Date de naissance</TableCell>
            <TableCell>Email</TableCell>
            <TableCell>Téléphone</TableCell>
            <TableCell>Action</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {students.map((student) => (
            <TableRow key={student._id}>
              <TableCell>{student.user.name}</TableCell>
              <TableCell>{student.birthday}</TableCell>
              <TableCell>{student.user.email}</TableCell>
              <TableCell>{student.user.phone}</TableCell>
              <TableCell sx={{ width: "25%" }}>
                <Tooltip title="modifier">
                  <IconButton
                    onClick={() => {
                      navigate(`/gestion-scolaire/eleves/add`, {
                        state: { student: student },
                      });
                    }}
                    sx={{ color: "#0058A5" }}
                  >
                    <SettingsIcon />
                  </IconButton>
                </Tooltip>
                <Tooltip title="fiche">
                  <IconButton
                    onClick={() => {
                      navigate(`/gestion-scolaire/deposit`, {
                        state: { id: student._id, userType: "STUDENT" },
                      });
                    }}
                    sx={{ color: "#0095B6" }}
                  >
                    <FindInPageIcon />
                  </IconButton>
                </Tooltip>
                <Tooltip title="Relevés des notes">
                  <IconButton
                    onClick={() => {
                      navigate(`/gestion-scolaire/notes`, {
                        state: { id: student._id },
                      });
                    }}
                    sx={{ color: "#00B0B0" }}
                  >
                    <DescriptionIcon />
                  </IconButton>
                </Tooltip>
                <Tooltip title="notifier">
                  <IconButton
                    onClick={() => {
                      setStudent(student);
                      // setNotification({
                      //   ...notification,
                      //   receiver: student.user._id,
                      // });
                      setAction("NOTIFY");
                    }}
                    sx={{ color: "#5DC9AB" }}
                  >
                    <NotificationsActiveIcon />
                  </IconButton>
                </Tooltip>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
