import React from "react";
import {
  Select,
  MenuItem,
  Button,
  Box,
  TextField,
  FormControl,
  InputLabel,
} from "@mui/material";
import RotateLeftIcon from "@mui/icons-material/RotateLeft";
import {
  PRIMARY_LEVELS,
  COLLEGE_LEVELS,
  SECONDARY_LEVELS,
} from "../../../utils/Constants";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";

const getLevels = (type) => {
  switch (type) {
    case "ecole-primaire":
      return PRIMARY_LEVELS;
    case "collège":
      return COLLEGE_LEVELS;
    case "ecole-secondaire":
      return SECONDARY_LEVELS;
    default:
      return [];
  }
};

const ClassroomFilter = ({
  level,
  setLevel,
  group,
  setGroup,
  establishmentType,
}) => {
  const levels = getLevels(establishmentType);
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <Box
      component="section"
      display="flex"
      flexDirection={isSmallScreen ? "column" : "row"}
      alignItems="center"
      gap={2}
      sx={{
        marginBottom: "1em",
        width: "100%",
      }}
    >
      <FormControl
        variant="outlined"
        size="small"
        fullWidth
        sx={{
          width: isSmallScreen ? "100%" : "20%",
        }}
      >
        <InputLabel>Niveau</InputLabel>
        <Select
          value={level}
          onChange={(e) => setLevel(e.target.value)}
          label="Niveau"
        >
          {levels.map((lvl) => (
            <MenuItem key={lvl} value={lvl}>
              {lvl}
            </MenuItem>
          ))}
        </Select>
      </FormControl>

      <TextField
        label="Groupe"
        value={group}
        onChange={(e) => setGroup(e.target.value)}
        variant="outlined"
        fullWidth
        size="small"
        sx={{
          width: isSmallScreen ? "100%" : "20%",
        }}
      />

      {(level !== "" || group !== "") && (
        <Button
          onClick={() => {
            setLevel("");
            setGroup("");
          }}
          size="large"
          variant="outlined"
          startIcon={<RotateLeftIcon />}
        >
          Reset
        </Button>
      )}
    </Box>
  );
};

export default ClassroomFilter;
