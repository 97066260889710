import React, { useState, useEffect } from "react";
import FormComponent from "./FormComponent";

function EditStudent(props) {
  const {
    student,
    establishment,
    classrooms,
    editStudent,
    currentSeason,
    setAction,
    formData,
    setFormData,
  } = props;

  const [loadingForm, setLoadingForm] = useState(false);
  console.log("student formData", formData);
  return (
    <FormComponent
      formData={formData}
      setFormData={setFormData}
      loadingForm={loadingForm}
      handleSubmit={editStudent}
      title={`Modifier l'étudiant : ${student?.user?.name}`}
      buttonText="Modifier"
      establishment={establishment}
      classrooms={classrooms}
      currentSeason={currentSeason}
      setAction={setAction}
    />
  );
}

export default EditStudent;
