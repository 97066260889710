import React, { useEffect, useState } from "react";
import { Button, CircularProgress, Dialog, Box } from "@mui/material";
import { useSnackbar } from "notistack";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import styles from "../../../styles/admin/Dashboard.module.css";
import AddClassroom from "./AddClassroom";
import EditClassroom from "./EditClassroom";
import ClassroomFilter from "./Filter";
import ClassroomTable from "./Table";
import { getError } from "../../../config/config";
import SchoolIcon from "@mui/icons-material/School";
function Classrooms() {
  const { enqueueSnackbar } = useSnackbar();
  const { userInfo } = useSelector((state) => state.auth);
  const { currentSeason } = useSelector((state) => state.dashboard);

  const [formData, setFormData] = useState({
    level: "",
    group: "",
    capacity: 0,
    costs: 0,
    establishment: userInfo.establishment._id,
    season: currentSeason?._id,
  });
  const [classRooms, setClassrooms] = useState([]);
  const [classroom, setClassroom] = useState(null);
  const [action, setAction] = useState("");
  const [loading, setLoading] = useState(false);
  const [loadingForm, setLoadingForm] = useState(false);
  const [level, setLevel] = useState("");
  const [group, setGroup] = useState("");

  const fetchClassrooms = async () => {
    setLoading(true);
    try {
      const { data } = await axios.get(
        `/classroom/get/${userInfo.establishment._id}/${currentSeason?._id}`
      );
      setClassrooms(data);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      enqueueSnackbar(getError(error), { variant: "error" });
    }
  };

  const addClassroom = async (e) => {
    setLoadingForm(true);
    e.preventDefault();
    try {
      const { data } = await axios.post("/classroom/add", formData);
      enqueueSnackbar(data.message, { variant: "success" });
      setFormData({
        level: "",
        group: "",
        capacity: 0,
        costs: 0,
        establishment: userInfo.establishment._id,
        season: currentSeason?._id,
      });
      fetchClassrooms();
      setLoadingForm(false);
      setAction("");
    } catch (error) {
      enqueueSnackbar(getError(error), { variant: "error" });
      setLoadingForm(false);
    }
  };

  const editClassroom = async (e) => {
    setLoadingForm(true);
    e.preventDefault();
    try {
      const { data } = await axios.put("/classroom/edit", formData);
      enqueueSnackbar(data.message, { variant: "success" });
      setFormData({
        level: "",
        group: "",
        capacity: 0,
        costs: 0,
        establishment: userInfo.establishment._id,
        season: currentSeason?._id,
      });
      fetchClassrooms();
      setLoadingForm(false);
      setAction("");
    } catch (error) {
      enqueueSnackbar(getError(error), { variant: "error" });
      setLoadingForm(false);
    }
  };

  useEffect(() => {
    if (userInfo && currentSeason) fetchClassrooms();
  }, [userInfo, currentSeason]);

  return (
    <>
      <Dialog
        maxWidth="sm"
        open={action !== ""}
        onClose={() => {
          setAction("");
          setFormData({
            level: "",
            group: "",
            capacity: 0,
            costs: 0,
            establishment: userInfo.establishment._id,
            season: currentSeason._id,
          });
        }}
      >
        <div
          style={{ maxHeight: "100vh" }}
          className={
            action === "ADD" || action === "EDIT" ? "modal small" : "modal"
          }
        >
          {action === "ADD" ? (
            <AddClassroom
              loadingForm={loadingForm}
              formData={formData}
              setFormData={setFormData}
              setAction={setAction}
              addClassroom={addClassroom}
              establishment={userInfo.establishment}
              season={currentSeason._id}
            />
          ) : action === "EDIT" ? (
            <EditClassroom
              EditClassroom
              loadingForm={loadingForm}
              formData={formData}
              setFormData={setFormData}
              setAction={setAction}
              classroom={classroom}
              editClassroom={editClassroom}
              establishment={userInfo.establishment}
              season={currentSeason._id}
            />
          ) : null}
        </div>
      </Dialog>
      <Box maxWidth="100%" margin="auto">
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <div
            style={{
              display: "flex",
              alignItems: "center",
              gap: "1em",
            }}
          >
            <SchoolIcon style={{ fontSize: "2.2rem", color: "#283487" }} />
            <h3 style={{ fontSize: "2rem", color: "#283487" }}>
              {" "}
              Liste des classes
            </h3>
          </div>
          <Button
            variant="contained"
            color="primary"
            onClick={() => setAction("ADD")}
          >
            Ajouter
          </Button>
        </div>

        {currentSeason && (
          <section className={styles.filter}>
            <ClassroomFilter
              level={level}
              setLevel={setLevel}
              group={group}
              setGroup={setGroup}
              establishmentType={userInfo.establishment.type}
            />
          </section>
        )}
        {loading ? (
          <div className="spinner">
            <CircularProgress />
          </div>
        ) : (
          <div
            style={{ height: "calc(100vh - 280px)" }}
            className={styles.tableContainer}
          >
            <ClassroomTable
              classRooms={classRooms}
              level={level}
              group={group}
              setClassroom={setClassroom}
              setAction={setAction}
              setFormData={setFormData}
            />
          </div>
        )}
      </Box>
    </>
  );
}

export default Classrooms;
