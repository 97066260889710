import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useSnackbar } from "notistack";
import {
  Button,
  CircularProgress,
  Pagination,
  Typography,
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from "@mui/material";
import {
  fetchStudentsAPI,
  fetchClassroomsAPI,
  addStudentAPI,
  editStudentAPI,
  activateStudentAPI,
  blockStudentAPI,
} from "../../../Api/studentAPI";
import { initialFormData, resetFormData } from "../../../utils/formUtils";
import StudentDialog from "./Dialog";
import StudentFilter from "./Filter";
import StudentRow from "./Row";
import { getError } from "../../../config/config";
import PeopleAltIcon from "@mui/icons-material/PeopleAlt";
function Students() {
  const { enqueueSnackbar } = useSnackbar();
  const { userInfo } = useSelector((state) => state.auth);
  const { currentSeason } = useSelector((state) => state.dashboard);
  const [page, setPage] = useState(1);
  const [count, setCount] = useState(1);
  const [name, setName] = useState("");
  const [phone, setPhone] = useState("");
  const [classrooms, setClassrooms] = useState([]);
  const [formData, setFormData] = useState(
    initialFormData(userInfo?.establishment?._id)
  );
  const [students, setStudents] = useState([]);
  const [student, setStudent] = useState({});
  const [action, setAction] = useState("");
  const [loading, setLoading] = useState(false);
  const [loadingForm, setLoadingForm] = useState(false);

  const fetchStudents = async () => {
    setLoading(true);
    try {
      const data = await fetchStudentsAPI(
        userInfo.establishment._id,
        page,
        phone,
        name
      );
      setStudents(data.students);
      setCount(data.count);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      enqueueSnackbar(getError(error), { variant: "error" });
    }
  };

  const fetchClassrooms = async () => {
    try {
      const data = await fetchClassroomsAPI(
        userInfo.establishment._id,
        currentSeason._id
      );
      setClassrooms(data);
    } catch (error) {
      enqueueSnackbar(getError(error), { variant: "error" });
    }
  };

  useEffect(() => {
    fetchStudents();
    if (userInfo?.establishment?._id) fetchClassrooms();
  }, [page, name, phone]);

  const addStudent = async () => {
    setLoadingForm(true);
    try {
      await addStudentAPI(formData);
      setLoadingForm(false);
      enqueueSnackbar("L'élève a été ajouté avec succès", {
        variant: "success",
      });
      setAction("");
      resetFormData(setFormData, userInfo.establishment._id);
      fetchStudents();
    } catch (error) {
      setLoadingForm(false);
      enqueueSnackbar(getError(error), { variant: "error" });
    }
  };

  const editStudent = async () => {
    setLoadingForm(true);
    try {
      console.log("formData", formData);
      await editStudentAPI(formData);
      setLoadingForm(false);
      enqueueSnackbar("L'élève a été modifié avec succès", {
        variant: "success",
      });
      setAction("");
      resetFormData(setFormData, userInfo.establishment._id);
      fetchStudents();
    } catch (error) {
      setLoadingForm(false);
      enqueueSnackbar(getError(error), { variant: "error" });
    }
  };

  const activateStudent = async () => {
    setLoadingForm(true);
    try {
      await activateStudentAPI(student._id);
      setLoadingForm(false);
      enqueueSnackbar("L'élève a été activé", { variant: "success" });
      setAction("");
      fetchStudents();
    } catch (error) {
      setLoadingForm(false);
      enqueueSnackbar(getError(error), { variant: "error" });
    }
  };

  const blockStudent = async () => {
    setLoadingForm(true);
    try {
      await blockStudentAPI(student._id);
      setLoadingForm(false);
      enqueueSnackbar("L'élève a été bloqué", { variant: "success" });
      setAction("");
      fetchStudents();
    } catch (error) {
      setLoadingForm(false);
      enqueueSnackbar(getError(error), { variant: "error" });
    }
  };

  return (
    <Box maxWidth="100%" margin="auto">
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <div
          style={{
            display: "flex",
            alignItems: "center",
            gap: "1em",
          }}
        >
          <PeopleAltIcon style={{ fontSize: "2.2rem", color: "#283487" }} />
          <h3 style={{ fontSize: "2rem", color: "#283487" }}>
            {" "}
            Liste des élèves
          </h3>
        </div>
        <Button
          variant="contained"
          color="primary"
          onClick={() => setAction("ADD")}
        >
          Ajouter
        </Button>
      </div>
      <StudentFilter
        name={name}
        setName={setName}
        phone={phone}
        setPhone={setPhone}
      />
      {loading ? (
        <div className="spinner">
          <CircularProgress />
        </div>
      ) : (
        <TableContainer
          component={Paper}
          sx={{ maxHeight: "calc(100vh - 401px)" }}
        >
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Nom</TableCell>
                <TableCell>Email</TableCell>
                <TableCell>Téléphone</TableCell>
                <TableCell>Classe</TableCell>
                <TableCell>Actions</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {students.length > 0 ? (
                students.map((student) => (
                  <StudentRow
                    key={student._id}
                    student={student}
                    setStudent={setStudent}
                    setFormData={setFormData}
                    setAction={setAction}
                  />
                ))
              ) : (
                <TableRow>
                  <TableCell colSpan={5} align="center">
                    Aucune élève trouvé
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      )}

      <Box margin="30px 0" display="flex" justifyContent="center">
        <Pagination
          count={count}
          color="primary"
          page={page}
          onChange={(_, value) => setPage(value)}
        />
      </Box>

      <StudentDialog
        action={action}
        setAction={setAction}
        formData={formData}
        setFormData={setFormData}
        loadingForm={loadingForm}
        student={student}
        addStudent={addStudent}
        editStudent={editStudent}
        blockStudent={blockStudent}
        activateStudent={activateStudent}
        userInfo={userInfo}
        classrooms={classrooms}
        currentSeason={currentSeason}
      />
    </Box>
  );
}

export default Students;
