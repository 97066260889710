import {
  CircularProgress,
  IconButton,
  MenuItem,
  Pagination,
  Select,
} from "@mui/material";
import axios from "axios";
import { useSnackbar } from "notistack";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import ReactToPrint from "react-to-print";
import Menu from "../../../components/Menu";
import PrintIcon from "@mui/icons-material/Print";
import NoteIcon from "@mui/icons-material/Note";
import { Button } from "@mui/material";
import { getError } from "../../../config/config";
import NotesTable from "./NotesTable";
function MyNotes(props) {
  const { enqueueSnackbar } = useSnackbar();
  const [page, setPage] = useState(1);
  const [count, setCount] = useState(1);
  const { userInfo } = useSelector((state) => state.auth);
  const currentClassroom = userInfo.classroom;
  const { currentSeason } = useSelector((state) => state.dashboard);
  const [subjects, setSubjects] = useState([]);
  const [total, setTotal] = useState(0);
  const [totalCoefficient, setTotalCoefficient] = useState(0);
  const [moyenne, setMoyenne] = useState(0);
  const [notes, setNotes] = useState([]);
  const [loading, setLoading] = useState(true);
  const [period, setPeriod] = useState(
    currentSeason?.type === "trimestrielle" ? "trimestre 1" : "semestre 1"
  );
  const printRef = useRef(null);
  const dispatch = useDispatch();

  const fetchSubjects = async () => {
    try {
      const { data } = await axios.get(
        `/subject/getallbyclassroom/${currentClassroom}`
      );
      setSubjects(data);
      setLoading(false);
    } catch (error) {
      enqueueSnackbar(getError(error), { variant: "error" });
    }
  };

  const fetchNotes = async () => {
    try {
      const { data } = await axios.get(
        `/note/getmynotes/${userInfo?._id}?season=${currentSeason?._id}&period=${period}`
      );
      setNotes(data.notes);
      setTotal(data.total);
      setTotalCoefficient(data.totalCoefficient);
      setMoyenne(data.moyenne);
    } catch (error) {
      enqueueSnackbar(getError(error), { variant: "error" });
    }
  };

  useEffect(() => {
    fetchSubjects();
    fetchNotes();
  }, [period]);

  return (
    <>
      <Menu />
      <div>
        {currentSeason?.type === "trimestrielle" && (
          <div
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "space-between",
              alignContent: "center",
              padding: "10px",
              marginBottom: "10px",
            }}
          >
            <div
              style={{
                display: "flex",
                alignItems: "center",
                gap: "1em",
              }}
            >
              <NoteIcon style={{ fontSize: "2.2rem", color: "#283487" }} />
              <h3 style={{ fontSize: "2rem", color: "#283487" }}>
                Relevés des notes
              </h3>
            </div>
            <Select
              required
              value={period}
              onChange={(e) => {
                setPeriod(e.target.value);
              }}
              sx={{
                backgroundColor: "transparent",
                height: "50px",
                margin: "auto 0",
                width: "20%",
              }}
            >
              <MenuItem
                style={{
                  marginBottom: "5px",
                }}
                value={"trimestre 1"}
              >
                trimestre 1
              </MenuItem>
              <MenuItem
                style={{
                  marginBottom: "5px",
                }}
                value={"trimestre 2"}
              >
                trimestre 2
              </MenuItem>
              <MenuItem
                style={{
                  marginBottom: "5px",
                }}
                value={"trimestre 3"}
              >
                trimester 3
              </MenuItem>
            </Select>
          </div>
        )}
        {currentSeason?.type === "semestrielle" && (
          <div
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "space-between",
              alignContent: "center",
              padding: "10px",
            }}
          >
            <div
              style={{
                display: "flex",
                alignItems: "center",
                gap: "1em",
              }}
            >
              <NoteIcon style={{ fontSize: "2.2rem", color: "#283487" }} />
              <h3 style={{ fontSize: "2rem", color: "#283487" }}>
                Relevés des notes
              </h3>
            </div>

            <Select
              required
              value={period}
              onChange={(e) => {
                setPeriod(e.target.value);
              }}
              sx={{
                backgroundColor: "transparent",
                height: "50px",
                margin: "auto 0",
                width: "20%",
              }}
            >
              <MenuItem
                style={{
                  marginBottom: "5px",
                }}
                value={"semestre 1"}
              >
                semestre 1
              </MenuItem>
              <MenuItem
                style={{
                  marginBottom: "5px",
                }}
                value={"semestre 2"}
              >
                semestre 2
              </MenuItem>
            </Select>
          </div>
        )}
        {loading ? (
          <div className="spinner">
            <CircularProgress />
          </div>
        ) : (
          <div>
            <NotesTable
              notes={notes}
              subjects={subjects}
              total={total}
              userInfo={userInfo}
              totalCoefficient={totalCoefficient}
              moyenne={moyenne}
              printRef={printRef}
              page={page}
              count={count}
              setPage={setPage}
            />
          </div>
        )}

        <div
          style={{
            display: "flex",
            justifyContent: "end",
          }}
        >
          <ReactToPrint
            trigger={() => (
              <Button
                variant="contained"
                color="primary"
                startIcon={<PrintIcon />}
                style={{
                  backgroundColor: "#283487",
                  color: "#FFF",
                  padding: "0.5em 1em",
                  borderRadius: "0px",
                  textTransform: "none",
                  fontSize: "1rem",
                  fontWeight: "bold",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                Imprimer
              </Button>
            )}
            content={() => printRef.current}
          />
        </div>
      </div>
    </>
  );
}

export default MyNotes;
