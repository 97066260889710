import React from "react";
import SubjectForm from "./SubjectForm";

function Edit(props) {
  const {
    formData,
    setFormData,
    resetForm,
    setAction,
    editSubject,
    teachers,
    loadingForm,
  } = props;

  return (
    <SubjectForm
      formData={formData}
      setAction={setAction}
      resetForm={resetForm}
      setFormData={setFormData}
      handleSubmit={editSubject}
      teachers={teachers}
      loadingForm={loadingForm}
      isEdit={true}
      title={`Modifier la matière: ${formData.name}`}
    />
  );
}

export default Edit;
