import React from "react";
import { Box, Container, Grid, Typography } from "@mui/material";
import "./WhyUsSection.css";

const WhyUsSection = () => {
  return (
    <div className="whyUs-section">
      <Typography
        variant="h4"
        align="left"
        className="whyUs-title"
        data-aos="fade-left"
        data-aos-delay="300"
      >
        POURQUOI NOUS ?
      </Typography>
      <div
        className="whyUs-underline"
        data-aos="fade-right"
        data-aos-delay="600"
      ></div>
      <Container maxWidth="lg" sx={{ mt: 5 }}>
        <Grid container spacing={4}>
          <Grid item xs={12} md={6}>
            <Box className="grid-item" data-aos="zoom-in" data-aos-delay="400">
              <img alt="icon1" src="./icons/icon1.svg" />

              <Typography variant="h6" gutterBottom>
                UN SERVICE DE GESTION
              </Typography>
              <Typography>
                Quelque soit le type de l'établissement: ecole primaire,
                collège, lycée ou centre de formation. Grâce à school-up,
                l'établissement scolaire peut gérer les flux des élèves et des
                enseignants dans un seul endroit.
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={12} md={6}>
            <Box className="grid-item" data-aos="zoom-in" data-aos-delay="600">
              <img alt="icon2" src="./icons/icon2.svg" />
              <Typography variant="h6" gutterBottom>
                GAGNER DU TEMPS
              </Typography>
              <Typography>
                Cette plateforme propose un service de gestion global de
                l'établissement qui optimise l'organisation et fait gagner du
                temps.
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={12} md={6}>
            <Box className="grid-item" data-aos="zoom-in" data-aos-delay="800">
              <img alt="icon3" src="./icons/icon3.svg" />
              <Typography variant="h6" gutterBottom>
                UN MODULE DE COMPTABILITÉ
              </Typography>
              <Typography>
                Un module de comptabilité facilite la gestion de portefeuille de
                l'établissement. Une interface de gestion scolaire est là pour
                vous aider à digitaliser les flux scolaires.
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={12} md={6}>
            <Box className="grid-item" data-aos="zoom-in" data-aos-delay="1000">
              <img alt="icon4" src="./icons/icon4.svg" />
              <Typography variant="h6" gutterBottom>
                UNE APPLICATION SÉCURISÉE
              </Typography>
              <Typography>
                Une application sécurisée, performante et robuste peut vous
                amener à un autre palier de gestion des flux d'informations.
              </Typography>
            </Box>
          </Grid>
        </Grid>
      </Container>
    </div>
  );
};

export default WhyUsSection;
