import React, { useRef } from "react";
import {
  IconButton,
  Tooltip,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Box,
  Typography,
} from "@mui/material";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import EditIcon from "@mui/icons-material/Edit";
import Pagination from "@mui/material/Pagination";

function StudentNotesTable({
  subjects,
  notes,
  period,
  setAction,
  setNote,
  id,
  note,
  currentSeason,
  page,
  setPage,
  count,
  total,
  totalCoefficient,
  moyenne,
  printRef,
}) {
  return (
    <Box ref={printRef} sx={{ overflowX: "auto" }}>
      <TableContainer component={Paper} sx={{ maxHeight: "500px" }}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell sx={{ position: "relative", width: "40%" }}>
                Matiéres
              </TableCell>
              <TableCell>Notes</TableCell>
            </TableRow>
          </TableHead>
          {subjects.length === 0 && (
            <TableRow>
              <TableCell colSpan={2}>
                <Typography
                  variant="h6"
                  align="center"
                  sx={{ color: "#283487" }}
                >
                  Aucune matière n'est disponible
                </Typography>
              </TableCell>
            </TableRow>
          )}
          <TableBody>
            {subjects.map((row) => (
              <TableRow key={row._id}>
                <TableCell
                  sx={{
                    width: "40%",
                    backgroundColor: "#f5f3f3",
                  }}
                >
                  <p>Matiére: {row.name}</p>
                  <p>Enseignant(e): {row.teacher.user.name}</p>
                  <p>Coefficient: {row.coefficient}</p>
                </TableCell>
                <TableCell>
                  {notes.map(
                    (note) =>
                      note.user === id && (
                        <Box
                          key={note._id}
                          sx={{
                            display: "flex",
                            gap: 2,
                            alignItems: "center",
                            mb: 1,
                          }}
                        >
                          <div
                            style={{
                              flex: "1",
                              textAlign: "left",
                              background: "#283487",
                              color: "white",
                              padding: "2em",
                            }}
                          >
                            <Typography variant="body2">
                              Type: <strong>{note.type}</strong>
                            </Typography>
                            <Typography variant="body2">
                              Coefficient: <strong>{note.coefficient} </strong>
                            </Typography>
                            <Typography variant="body2">
                              Note: <strong>{note.note} </strong>
                            </Typography>
                          </div>
                          <div
                            style={{
                              display: "flex",
                              height: "100%",
                              flexDirection: "column",
                              justifyContent: "center",
                              gap: "1em",
                            }}
                          >
                            <Tooltip title="Modifier la note">
                              <IconButton
                                size="small"
                                onClick={() => {
                                  setNote({
                                    ...note,
                                    user: id,
                                    subject: row._id,
                                  });
                                  setAction("EDIT_NOTE");
                                }}
                                sx={{ color: "#3748BA" }}
                              >
                                <EditIcon />
                              </IconButton>
                            </Tooltip>
                            <Tooltip
                              title="Supprimer la note"
                              sx={{ color: "red" }}
                            >
                              <IconButton
                                size="small"
                                onClick={() => {
                                  setNote(note);
                                  setAction("DELETE_NOTE");
                                }}
                              >
                                <DeleteOutlineIcon />
                              </IconButton>
                            </Tooltip>
                          </div>
                        </Box>
                      )
                  )}
                  <Tooltip title="Ajouter une note" sx={{ color: "green" }}>
                    <IconButton
                      size="small"
                      onClick={() => {
                        setNote({
                          ...note,
                          user: id,
                          subject: row._id,
                          season: currentSeason._id,
                          period: period,
                        });
                        setAction("ADD_NOTE");
                      }}
                    >
                      <AddCircleOutlineIcon />
                    </IconButton>
                  </Tooltip>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <section
        style={{
          display: "flex",
          justifyContent: "center",
          marginTop: "1em",
        }}
      >
        <Pagination
          onChange={(e, page) => setPage(page)}
          page={page}
          count={count}
          color="primary"
        />
      </section>

      <div
        style={{
          width: "100%",
          display: "flex",
          justifyContent: "flex-end",
          alignContent: "center",

          padding: "10px",
        }}
      >
        {total > 0 && (
          <div
            style={{
              width: "100%",
              display: "flex",
              textAlign: "right",
              flexDirection: "column",
              gap: "10px",
            }}
          >
            <span>
              Total: <strong>{total}</strong>
            </span>
            <span>
              Coefficient: <strong>{totalCoefficient}</strong>
            </span>
            <span>
              Moyenne: <strong>{moyenne.toFixed(2)}</strong>
            </span>
          </div>
        )}
      </div>
    </Box>
  );
}

export default StudentNotesTable;
