import { Button, TextField } from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers";
import moment from "moment";
import React from "react";

function AddDate(props) {
  const { addDate, setAction, date, setDate } = props;

  return (
    <form onSubmit={addDate}>
      <h1>Ajouter une date</h1>
      <div className="row">
        <div className="labeledInput">
          <label>Date d&apos;examen</label>
          <DatePicker
            inputFormat="DD-MM-yyyy"
            ampm={false}
            value={moment(date).format("MM-DD-yyyy")}
            onChange={(val) => {
              setDate(moment(val).format("MM-DD-yyyy"));
            }}
            renderInput={(params) => (
              <TextField
                required
                size="small"
                sx={{
                  width: "100%",
                  "& .MuiOutlinedInput-notchedOutline": {
                    borderRadius: "5px !important",
                    border: "1px solid #1d255e !important",
                  },
                }}
                {...params}
              />
            )}
          />
        </div>
      </div>
      <div className="btnRow">
        <Button variant="contained" type="submit">
          Ajouter
        </Button>
        &nbsp;
        <Button
          variant="outlined"
          type="button"
          onClick={() => {
            setAction("");
            setDate("");
          }}
        >
          Annuler
        </Button>
      </div>
    </form>
  );
}

export default AddDate;
