import React, { useLayoutEffect, useState } from "react";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import { useSelector } from "react-redux";
import axios from "axios";

function LineChart(props) {
  const { userInfo } = useSelector((state) => state.auth);
  const { currentSeason } = useSelector((state) => state.dashboard);
  const [count, setCount] = useState({ students: 0, teachers: 0 });

  const fetchData = async () => {
    try {
      const { data } = await axios.get(
        `/stats/line/${userInfo.establishment._id}/${currentSeason?._id}/${currentSeason?.year}`
      );
      setCount(data);
    } catch (error) {
      console.log(error);
    }
  };

  useLayoutEffect(() => {
    if (currentSeason) {
      fetchData();
    }
  }, [currentSeason]);

  const options = {
    chart: {
      backgroundColor: "#fff",
      type: "spline",
      height: "400",
    },
    title: {
      text: "Taux De Croissance",
    },
    legend: {
      enabled: true,
    },
    xAxis: {
      categories: [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
        "Oct",
        "Nov",
        "Dec",
      ],
      accessibility: {
        description: "Months of the year",
      },
    },
    yAxis: {
      title: {
        text: "",
      },
    },
    plotOptions: {
      line: {
        dataLabels: {
          enabled: true,
        },
      },
    },
    series: [
      {
        name: "Enseignants",
        color: "#283487",
        data: count.teachers,
      },
      {
        name: "Étudiants",
        color: "#FFBF00",
        data: count.students,
      },
    ],
  };
  return <HighchartsReact highcharts={Highcharts} options={options} />;
}

export default LineChart;
