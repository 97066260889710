// api.js
import axios from "axios";

export const fetchRoomsAPI = async (establishmentId, page, type, spots) => {
  const { data } = await axios.post(`/room/get`, {
    id: establishmentId,
    page,
    type,
    spots,
  });
  return data;
};

export const addRoomAPI = async (formData) => {
  const { data } = await axios.post("/room/add", formData);
  return data;
};

export const editRoomAPI = async (formData) => {
  const { data } = await axios.put("/room/edit", formData);
  return data;
};
