import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import { useSnackbar } from "notistack";
import { useSelector } from "react-redux";
import ExamTable from "./ExamTable";
import ActionDialog from "./ActionDialog";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import { Box, Button, Chip, Tooltip } from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
import ReactToPrint from "react-to-print";
import { getError } from "../../../config/config";
import Menu from "../../../components/Menu";
import PrintIcon from "@mui/icons-material/Print";
import Pagination from "@mui/material/Pagination";
import moment from "moment";
import IconButton from "@mui/material/IconButton";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import PublishIcon from "@mui/icons-material/Publish";
import UnpublishedIcon from "@mui/icons-material/Unpublished";

const ExamSchedule = () => {
  const { enqueueSnackbar } = useSnackbar();
  const { userInfo } = useSelector((state) => state.auth);
  const { currentClassroom } = useSelector((state) => state.dashboard);
  const [page, setPage] = useState(1);
  const [count, setCount] = useState(1);
  const [loading, setLoading] = useState(false);
  const [exams, setExams] = useState([]);
  const [date, setDate] = useState(
    moment(new Date()).format("YYYY-MM-DD").toString()
  );
  const [action, setAction] = useState("");
  const [exam, setExam] = useState({ start: "", end: "" });
  const [subjects, setSubjects] = useState([]);
  const [rooms, setRooms] = useState([]);
  const [loadingForm, setLoadingForm] = useState(false);
  const printRef = useRef(null);
  const isStudent = userInfo.user.role === "STUDENT";

  useEffect(() => {
    fetchExams();
    fetchSubjects();
    fetchRooms();
  }, []);

  const fetchExams = async () => {
    setLoading(true);
    const url =
      userInfo.user.role === "STUDENT"
        ? `/exam/get/${userInfo?.classroom}/${userInfo?.user?._id}`
        : `/exam/get/${currentClassroom?._id}/${userInfo?.user?._id}`;
    try {
      const { data } = await axios.get(url);
      setExams(data);
      setLoading(false);
    } catch (error) {
      enqueueSnackbar(getError(error), { variant: "error" });
    }
  };

  const fetchSubjects = async () => {
    try {
      if (!currentClassroom) return;
      const { data } = await axios.get(
        `/subject/getallbyclassroom/${currentClassroom?._id}`
      );
      setSubjects(data);
    } catch (error) {
      enqueueSnackbar(getError(error), { variant: "error" });
    }
  };

  const fetchRooms = async () => {
    try {
      const { data } = await axios.get(
        `/room/getall/${userInfo.establishment?._id}`
      );
      setRooms(data);
    } catch (error) {
      enqueueSnackbar(getError(error), { variant: "error" });
    }
  };

  const addDate = (e) => {
    e.preventDefault();
    const exists = exams.find((ex) => ex.date === date);
    if (exists)
      return enqueueSnackbar("La date est déjà crée !", { variant: "warning" });
    setExams([...exams, { date: date, exams: [] }]);
    setAction("");
    setDate("");
  };

  const addExam = async (e) => {
    e.preventDefault();
    setLoadingForm(true);
    const row = exams.find((ex) => ex.date === exam.date);
    const exits = row.exams.find(
      (ex) =>
        ex.timing ===
        `${
          moment(exam.start).format("hh:mm") +
          " - " +
          moment(exam.end).format("hh:mm")
        }`
    );
    if (exits)
      return (
        enqueueSnackbar("Il y a dèjà un examen dans cette heure !", {
          variant: "warning",
        }),
        setLoadingForm(false)
      );
    if (exam.start > exam.end)
      return (
        enqueueSnackbar(
          "L'heure de début doit être inférieur à l'haure de fin !",
          {
            variant: "warning",
          }
        ),
        setLoadingForm(false)
      );
    try {
      const { data } = await axios.post("/exam/add", exam);
      enqueueSnackbar(data.message, { variant: "success" });
      setExam({ start: "", end: "" });
      fetchExams();
      setAction("");
      setLoadingForm(false);
    } catch (error) {
      setLoadingForm(false);
      enqueueSnackbar(error.message, { variant: "error" });
    }
  };

  const editExam = async (e) => {
    e.preventDefault();
    setLoadingForm(true);
    const row = exams.find((ex) => ex.date === exam.date);
    const exits = row.exams.find(
      (ex) =>
        ex.timing ===
          `${
            moment(exam.start).format("hh:mm") +
            " - " +
            moment(exam.end).format("hh:mm")
          }` && ex?._id !== exam?._id
    );
    if (exits)
      return (
        enqueueSnackbar("Il y a dèjà un examen dans cette heure !", {
          variant: "warning",
        }),
        setLoadingForm(false)
      );
    if (exam.start > exam.end)
      return (
        enqueueSnackbar(
          "L'heure de début doit être inférieur à l'haure de fin !",
          {
            variant: "warning",
          }
        ),
        setLoadingForm(false)
      );
    try {
      const { data } = await axios.put("/exam/edit", exam);
      enqueueSnackbar(data.message, { variant: "success" });
      setExam({ start: "", end: "" });
      fetchExams();
      setAction("");
      setLoadingForm(false);
    } catch (error) {
      setLoadingForm(false);
      enqueueSnackbar(error.message, { variant: "error" });
    }
  };

  const deleteExam = async (e) => {
    e.preventDefault();
    setLoadingForm(true);
    try {
      const { data } = await axios.delete(`/exam/remove/${exam._id}`);
      enqueueSnackbar(data.message, { variant: "success" });
      setExam({ start: "", end: "" });
      fetchExams();
      setAction("");
      setLoadingForm(false);
    } catch (error) {
      setLoadingForm(false);
      enqueueSnackbar(error.message, { variant: "error" });
    }
  };

  const deleteSchedule = async (e) => {
    e.preventDefault();
    setLoadingForm(true);
    try {
      const { data } = await axios.delete(
        `/exam/removeall/${currentClassroom?._id}`
      );
      enqueueSnackbar(data.message, { variant: "success" });
      setExam({ start: "", end: "" });
      fetchExams();
      setAction("");
      setLoadingForm(false);
    } catch (error) {
      setLoadingForm(false);
      enqueueSnackbar(error.message, { variant: "error" });
    }
  };

  const handleToggleChange = async (exam) => {
    console.log("exam", exam);
    try {
      const { data } = await axios.put(`/exam/publish`, exam);
      enqueueSnackbar(data.message, { variant: "success" });
      fetchExams();
    } catch (error) {
      enqueueSnackbar(getError(error), { variant: "error" });
    }
  };

  return (
    <>
      <Menu />

      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <div
          style={{
            display: "flex",
            alignItems: "center",
            gap: "1em",
          }}
        >
          <CalendarMonthIcon style={{ fontSize: "2.2rem", color: "#283487" }} />
          <h3 style={{ fontSize: "2rem", color: "#283487" }}>
            {" "}
            Emploi du temps
          </h3>
        </div>
        {!isStudent && (
          <Box
            style={{
              display: "flex",
              gap: "1em",
              alignItems: "center",
            }}
          >
            <Button
              variant="contained"
              sx={{
                borderRadius: "0px",
                background: exams[0]?.exams[0]?.isPublished
                  ? "#43A047"
                  : "#FFCA00",
                "&:hover": {
                  background: exams[0]?.exams[0]?.isPublished
                    ? "#2E7D32"
                    : "#FFB300",
                },
              }}
              onClick={() => {
                if (exams[0]?.exams[0]) {
                  handleToggleChange({
                    ...exams[0].exams[0],
                    isPublished: !exams[0].exams[0].isPublished,
                  });
                }
              }}
            >
              {exams[0]?.exams[0]?.isPublished ? "Publié" : "Non publié"}
            </Button>

            <Tooltip title="Ajouter une date">
              <Button
                variant="contained"
                color="primary"
                sx={{
                  borderRadius: "0px",
                }}
                onClick={() => {
                  setExam({
                    ...exam,
                    classroom: currentClassroom?._id,
                  });

                  setAction("ADD_DATE");
                }}
              >
                Ajouter
              </Button>
            </Tooltip>
          </Box>
        )}
      </div>

      {loading ? (
        <div className="spinner">
          <CircularProgress />
        </div>
      ) : (
        <>
          {loading ? (
            <div className="spinner">
              <CircularProgress />
            </div>
          ) : (
            <>
              <ExamTable
                exams={exams}
                setAction={setAction}
                setExam={setExam}
                printRef={printRef}
                currentClassroom={currentClassroom}
                userInfo={userInfo}
              />
              <section
                style={{
                  display: "flex",
                  justifyContent: "center",
                  marginTop: "1em",
                }}
              >
                <Pagination
                  onChange={(e, page) => setPage(page)}
                  page={page}
                  count={count}
                  color="primary"
                />
              </section>

              <div
                style={{
                  display: "flex",
                  justifyContent: "end",
                  marginTop: "2em",
                }}
              >
                <ReactToPrint
                  trigger={() => (
                    <Button
                      variant="contained"
                      color="primary"
                      startIcon={<PrintIcon />}
                      sx={{
                        backgroundColor: "#283487",
                        color: "#FFF",
                        padding: "0.5em 1em",
                        borderRadius: "0px",
                        textTransform: "none",
                        fontSize: "1rem",
                        fontWeight: "bold",
                        marginRight: "1em",
                      }}
                    >
                      Imprimer
                    </Button>
                  )}
                  content={() => printRef.current}
                />
                {(exams.length > 0 && userInfo.user.role === "ADMIN") ||
                userInfo.user.role === "SUPER-ADMIN" ? (
                  <Tooltip title="Supprimer l'emploi" sx={{ color: "red" }}>
                    <Button
                      variant="contained"
                      color="primary"
                      startIcon={<DeleteOutlineIcon />}
                      sx={{
                        backgroundColor: "red",
                        color: "#FFF",
                        padding: "0.5em 1em",
                        borderRadius: "0px",
                        textTransform: "none",
                        fontSize: "1rem",
                        fontWeight: "bold",
                      }}
                      onClick={() => setAction("DELETE_SCHEDULE")}
                    >
                      Supprimer
                    </Button>
                  </Tooltip>
                ) : null}
              </div>
            </>
          )}
        </>
      )}

      <ActionDialog
        action={action}
        setAction={setAction}
        addDate={addDate}
        setDate={setDate}
        exam={exam}
        setExam={setExam}
        addExam={addExam}
        editExam={editExam}
        deleteExam={deleteExam}
        deleteSchedule={deleteSchedule}
        subjects={subjects}
        rooms={rooms}
        loadingForm={loadingForm}
      />
    </>
  );
};

export default ExamSchedule;
