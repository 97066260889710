export const initialFormData = (establishmentId) => ({
  name: "",
  email: "",
  password: "",
  phone: "",
  address: "",
  classroom: "",
  sex: "",
  birthday: "",
  establishment: establishmentId,
});

export const resetFormData = (setFormData, establishmentId) => {
  setFormData(initialFormData(establishmentId));
};

export const initialStudentData = (establishmentId, classroomId) => ({
  establishment: establishmentId,
  classroom: classroomId,
  avatar: "",
  name: "",
  email: "",
  password: "",
  phone: "",
  address: "",
  sex: "",
  birthday: "yyyy-mm-dd",
  fatherName: "",
  motherName: "",
  orphin: false,
  maritalStatus: "",
  fatherPhone: "",
  motherPhone: "",
  motherProfession: "",
  fatherProfession: "",
  siblings: 0,
  siblingsStudents: 0,
  authorizedCompany: [],
});

export const resetStudentData = (
  setStudentData,
  establishmentId,
  classroomId
) => {
  setStudentData(initialStudentData(establishmentId, classroomId));
};
