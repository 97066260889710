import Compress from "compress.js";
import axios from "axios";

const compress = new Compress();
export function getUrl() {
  return process.env.REACT_APP_SERVER_URL;
}

export async function compressImage(file) {
  const res = await compress.compress([file], {
    size: 0.5, // the max size in MB, defaults to 2MB
    quality: 0.8, // the quality of the image, max is 1,
    maxWidth: 250, // the max width of the output image, defaults to 1920px
    maxHeight: 250, // the max height of the output image, defaults to 1920px
    resize: true, // defaults to true, set false if you do not want to resize the image width and height
  });
  return `data:image/webp;base64,${res[0].data}`;
}

export const compressPdf = (file) => {
  return new Promise((resolve) => {
    let baseURL = "";
    // Make new FileReader
    let reader = new FileReader();

    // Convert the file to base64 text
    reader.readAsDataURL(file);

    // on reader load somthing...
    reader.onload = () => {
      // Make a fileInfo Object
      baseURL = reader.result;
      resolve(baseURL);
    };
  });
};

export const getMonth = (key) => {
  switch (key) {
    case 0:
      return "Janvier";
    case 1:
      return "Février";
    case 2:
      return "Mars";
    case 3:
      return "Avril";
    case 4:
      return "Mai";
    case 5:
      return "Juin";
    case 6:
      return "Juillet";
    case 7:
      return "Août";
    case 8:
      return "Septembre";
    case 9:
      return "Octobre";
    case 10:
      return "Novembre";
    case 11:
      return "Décembre";
    default:
      return "";
  }
};

export const getDay = (key) => {
  switch (key) {
    case 0:
      return "Lundi";
    case 1:
      return "Mardi";
    case 2:
      return "Mercredi";
    case 3:
      return "Jeudi";
    case 4:
      return "Vendredi";
    case 5:
      return "Samedi";
    default:
      return "";
  }
};

export function getError(error) {
  if (error?.response?.data?.message) return error.response.data.message;
  return error.message;
}

export async function sendMessage(user, establishment, sender, receiver, text) {
  const { data } = await axios.post("/chatroom/sendmessage", {
    user: user,
    establishment: establishment,
    sender: sender,
    receiver: receiver,
    text: text,
  });
  return data;
}
