import React, { useEffect, useState } from "react";
import axios from "axios";
import { useSnackbar } from "notistack";
import { getError } from "../../config/config.js";
import {
  Button,
  CircularProgress,
  IconButton,
  MenuItem,
  Dialog,
  Select,
  Tooltip,
  TextField,
  FormControl,
  InputLabel,
  Box,
  Grid,
  Typography,
} from "@mui/material";
import SettingsIcon from "@mui/icons-material/Settings";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import styles from "../../styles/superadmin/Establishment.module.css";
import BlockIcon from "@mui/icons-material/Block";

import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import CheckIcon from "@mui/icons-material/Check";
import { Link, useParams } from "react-router-dom";
import AddMember from "./components/AddMember";
import ActivateMember from "./components/ActivateMember";
import BlockMember from "./components/BlockMember";
import EditMember from "./components/EditMember";
import { MuiTelInput } from "mui-tel-input";

function Establishment(props) {
  const [establishment, setEstablishment] = useState(null);
  const [members, setMembers] = useState([]);
  const [member, setMember] = useState(null);
  const [loadingEst, setLoadingEst] = useState(true);
  const [loadingMembers, setLoadingMembers] = useState(true);
  const [loadingApi, setLoadingApi] = useState(false);
  const [action, setAction] = useState("");
  const { enqueueSnackbar } = useSnackbar();
  const { id } = useParams();

  const [formData, setFormData] = useState({
    name: "",
    type: "",
    address: "",
    email: "",
    phone: "",
  });
  const { name, type, address, email, phone } = formData;

  const [memberData, setMemberData] = useState({
    name: "",
    email: "",
    phone: "",
    password: "",
  });
  console.log("memberData", memberData);
  const resetMemberData = () =>
    setMemberData({
      name: "",
      email: "",
      phone: "",
      password: "",
    });

  const fetchEstablishment = async () => {
    try {
      const { data } = await axios.get(`/establishment/get/${id}`);
      setEstablishment(data);
      setLoadingEst(false);
    } catch (error) {
      enqueueSnackbar(getError(error), { variant: "error" });
      setLoadingEst(false);
    }
  };

  const editEstablishment = async (e) => {
    e.preventDefault();
    setLoadingApi(true);
    try {
      const { data } = await axios.put(`/establishment/edit`, formData);
      enqueueSnackbar(data.message, { variant: "success" });
      fetchEstablishment();
      setLoadingApi(false);
    } catch (error) {
      enqueueSnackbar(getError(error), { variant: "error" });
      setLoadingApi(false);
    }
  };

  const fetchMembers = async () => {
    try {
      const { data } = await axios.get(`/establishment/getmembers/${id}`);
      setMembers(data);
      setLoadingMembers(false);
    } catch (error) {
      enqueueSnackbar(getError(error), { variant: "error" });
      setLoadingMembers(false);
    }
  };

  const addMember = async (e) => {
    e.preventDefault();
    setLoadingApi(true);
    try {
      const { data } = await axios.post(`/establishment/addmember`, {
        ...memberData,
        establishment: id,
      });
      fetchMembers();
      enqueueSnackbar(data.message, { variant: "success" });
      setLoadingApi(false);
      setAction("");
      setMemberData({ name: "", email: "", phone: "", password: "" });
    } catch (error) {
      enqueueSnackbar(getError(error), { variant: "error" });
      setLoadingApi(false);
    }
  };

  const editMember = async (e) => {
    console.log("edit member pending ...");
    e.preventDefault();
    setLoadingApi(true);
    try {
      const { data } = await axios.put(`/establishment/editmember`, memberData);
      fetchMembers();
      enqueueSnackbar(data.message, { variant: "success" });
      setLoadingApi(false);
      setAction("");
      setMemberData({ name: "", email: "", phone: "", password: "" });
    } catch (error) {
      enqueueSnackbar(getError(error), { variant: "error" });
      setLoadingApi(false);
    }
  };

  // delete member
  const deleteMember = async (e) => {
    e.preventDefault();
    setLoadingApi(true);
    try {
      const { data } = await axios.delete(
        `/establishment/deletemember/${member._id}`
      );
      fetchMembers();
      enqueueSnackbar(data.message, { variant: "success" });
      setLoadingApi(false);
      setAction("");
    } catch (error) {
      enqueueSnackbar(getError(error), { variant: "error" });
      setLoadingApi(false);
    }
  };

  const activateMember = async () => {
    setLoadingApi(true);
    try {
      const { data } = await axios.put(
        `/establishment/activatemember/${member._id}`
      );
      fetchMembers();
      enqueueSnackbar(data.message, { variant: "success" });
      setLoadingApi(false);
      setAction("");
    } catch (error) {
      enqueueSnackbar(getError(error), { variant: "error" });
      setLoadingApi(false);
    }
  };

  const blockMember = async () => {
    setLoadingApi(true);
    try {
      const { data } = await axios.put(
        `/establishment/blockmember/${member._id}`
      );
      fetchMembers();
      enqueueSnackbar(data.message, { variant: "success" });
      setLoadingApi(false);
      setAction("");
    } catch (error) {
      enqueueSnackbar(getError(error), { variant: "error" });
      setLoadingApi(false);
    }
  };

  useEffect(() => {
    fetchEstablishment();
    fetchMembers();
  }, []);

  useEffect(() => {
    if (establishment) setFormData(establishment);
  }, [establishment]);

  return (
    <>
      <Dialog
        open={action !== ""}
        onClose={() => {
          setAction("");
          resetMemberData();
        }}
      >
        <div
          className={"modal small"}
          style={
            action === "ADD" || action === "EDIT"
              ? { maxHeight: "100%", top: "10vh" }
              : null
          }
        >
          {action === "BLOCK" ? (
            <BlockMember
              loadingApi={loadingApi}
              member={member}
              blockMember={blockMember}
              setAction={setAction}
            />
          ) : action === "ACTIVATE" ? (
            <ActivateMember
              loadingApi={loadingApi}
              member={member}
              activateMember={activateMember}
              setAction={setAction}
            />
          ) : action === "ADD" ? (
            <AddMember
              setMemberData={setMemberData}
              memberData={memberData}
              loadingApi={loadingApi}
              addMember={addMember}
              resetMemberData={resetMemberData}
              setAction={setAction}
            />
          ) : //delete member
          action === "DELETE" ? (
            <div className="deleteMember">
              <h1>Supprimer {member.user.name} ?</h1>
              <div className="buttons">
                <Button
                  onClick={() => {
                    setAction("");
                  }}
                  variant="contained"
                >
                  annuler
                </Button>
                &nbsp;
                <Button
                  onClick={deleteMember}
                  variant="contained"
                  color="error"
                >
                  supprimer
                </Button>
              </div>
            </div>
          ) : (
            <EditMember
              setMemberData={setMemberData}
              memberData={memberData}
              loadingApi={loadingApi}
              editMember={editMember}
              resetMemberData={resetMemberData}
              setAction={setAction}
            />
          )}
        </div>
      </Dialog>
      <>
        {loadingEst ? (
          <div className="spinner">
            <CircularProgress />
          </div>
        ) : (
          <>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                gap: 2,
                padding: {
                  xs: 2,
                  md: 4,
                },
              }}
            >
              <Link
                to="/home"
                style={{
                  color: "#283487",
                  textDecoration: "underline",
                  fontSize: "1.2rem",
                  fontWeight: "bold",
                }}
              >
                Retour
              </Link>

              <Typography
                variant="h4"
                sx={{
                  color: "#283487",
                  fontWeight: "bold",
                  fontSize: "1.5rem",
                  textTransform: "uppercase",
                }}
              >
                {name}
              </Typography>
              <form
                onSubmit={editEstablishment}
                style={{
                  display: "flex",
                  flexDirection: "row",
                  gap: 12,
                }}
              >
                {" "}
                <Grid container spacing={2}>
                  <Grid item xs={12} md={2}>
                    <TextField
                      label="Nom"
                      variant="outlined"
                      fullWidth
                      required
                      value={name}
                      onChange={(e) =>
                        setFormData({ ...formData, name: e.target.value })
                      }
                    />
                  </Grid>
                  <Grid item xs={12} md={2}>
                    <FormControl fullWidth>
                      <InputLabel>Type</InputLabel>
                      <Select
                        label="Type"
                        variant="outlined"
                        fullWidth
                        required
                        value={type}
                        onChange={(e) =>
                          setFormData({ ...formData, type: e.target.value })
                        }
                        sx={{
                          "& .MuiOutlinedInput-notchedOutline": {
                            borderRadius: "5px",
                            border: "0.5px solid #283487",
                          },
                        }}
                      >
                        <MenuItem value="ecole-primaire">
                          Ecole primaire
                        </MenuItem>
                        <MenuItem value="collège">Collège</MenuItem>
                        <MenuItem value="ecole-secondaire">
                          Ecole secondaire
                        </MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} md={2}>
                    <TextField
                      label="Adresse"
                      variant="outlined"
                      fullWidth
                      required
                      value={address}
                      onChange={(e) =>
                        setFormData({ ...formData, address: e.target.value })
                      }
                    />
                  </Grid>
                  <Grid item xs={12} md={2}>
                    <TextField
                      label="Email"
                      variant="outlined"
                      fullWidth
                      required
                      type="email"
                      value={email}
                      onChange={(e) =>
                        setFormData({ ...formData, email: e.target.value })
                      }
                    />
                  </Grid>
                  <Grid item xs={12} md={2}>
                    <FormControl fullWidth variant="outlined">
                      <MuiTelInput
                        id="phone"
                        label="Téléphone"
                        name="phone"
                        className="fullwidth"
                        focusOnSelectCountry={true}
                        defaultCountry="TN"
                        value={phone}
                        onChange={(value) =>
                          setFormData({ ...formData, phone: value })
                        }
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} md={2}>
                    <div className="labeledInput">
                      <Button type="submit" variant="contained">
                        {loadingApi ? (
                          <CircularProgress
                            size="25px"
                            sx={{ color: "white" }}
                          />
                        ) : (
                          "modifier"
                        )}
                      </Button>
                    </div>
                  </Grid>
                </Grid>
              </form>

              <Typography
                variant="h4"
                sx={{
                  color: "#283487",
                  fontWeight: "bold",
                  fontSize: "1.5rem",
                }}
              >
                Accès
              </Typography>
              <div className={styles.container}>
                {loadingMembers ? (
                  <div className="spinner">
                    <CircularProgress />
                  </div>
                ) : (
                  <>
                    {members.map((member) => {
                      return (
                        <div key={member._id} className={styles.bloc}>
                          <div className={styles.card}>
                            <img
                              alt={member.name}
                              src={
                                member.user.avatar && member.user.avatar !== ""
                                  ? member.user.avatar
                                  : "/" + "./icons/profile2.svg"
                              }
                            />
                            <p>{member.user.name}</p>
                            <div className={styles.controls}>
                              <Tooltip title="modifier">
                                <IconButton
                                  onClick={() => {
                                    setMemberData({ ...member.user });
                                    setAction("EDIT");
                                  }}
                                >
                                  <SettingsIcon color="primary" />
                                </IconButton>
                              </Tooltip>
                              &nbsp;
                              {member.isActive ? (
                                <Tooltip title="bloquer">
                                  <IconButton
                                    onClick={() => {
                                      setMember(member);
                                      setAction("BLOCK");
                                    }}
                                  >
                                    <BlockIcon color="fourth" />
                                  </IconButton>
                                </Tooltip>
                              ) : (
                                <Tooltip title="activer">
                                  <IconButton
                                    onClick={() => {
                                      setMember(member);
                                      setAction("ACTIVATE");
                                    }}
                                  >
                                    <CheckIcon color="success" />
                                  </IconButton>
                                </Tooltip>
                              )}
                              <Tooltip title="supprimer">
                                <IconButton
                                  onClick={() => {
                                    setMember(member);
                                    setAction("DELETE");
                                  }}
                                >
                                  <DeleteOutlineIcon color="error" />
                                </IconButton>
                              </Tooltip>
                            </div>
                          </div>
                        </div>
                      );
                    })}
                  </>
                )}
                <div className={styles.bloc}>
                  <div className={styles.add}>
                    <Tooltip title="nouveau membre">
                      <IconButton onClick={() => setAction("ADD")}>
                        <AddCircleIcon
                          sx={{
                            color: "#00000021",
                            fontSize: "50px",
                            cursor: "pointer",
                          }}
                        />
                      </IconButton>
                    </Tooltip>
                  </div>
                </div>
              </div>
            </Box>
          </>
        )}
      </>
    </>
  );
}

export default Establishment;
