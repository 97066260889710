import React, { useRef } from "react";
import { IconButton, Tooltip } from "@mui/material";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import styles from "../../../styles/admin/Schedule.module.css";

import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from "@mui/material";

const ScheduleTable = ({ sessions, renderRow, setAction, printRef }) => {
  console.log("sessions", sessions);
  return (
    <div className={styles.tableContainer}>
      <div ref={printRef} className="printBody">
        <TableContainer
          component={Paper}
          sx={{
            width: "100%",
            overflowX: "auto",
            "@media (max-width: 600px)": {
              width: "100vw",
            },
          }}
        >
          <Table ref={printRef} aria-label="responsive table">
            <TableHead>
              <TableRow>
                <TableCell>Date</TableCell>
                <TableCell>Lundi</TableCell>
                <TableCell>Mardi</TableCell>
                <TableCell>Mercredi</TableCell>
                <TableCell>Jeudi</TableCell>
                <TableCell>Vendredi</TableCell>
                <TableCell>Samedi</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {sessions ? (
                sessions.map((row, index) => (
                  <TableRow key={index}>{renderRow(row)}</TableRow>
                ))
              ) : (
                <TableRow>
                  <TableCell colSpan={7} style={{ textAlign: "center" }}>
                    Aucune donnée
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
    </div>
  );
};

export default ScheduleTable;
