import React, { useState, useEffect } from "react";
import {
  Button,
  Dialog,
  Typography,
  CircularProgress,
  Box,
} from "@mui/material";
import { useSelector } from "react-redux";
import Menu from "../../../components/Menu";
import StudentFilter from "./Filter";
import StudentTable from "./Table";
import { useStudentApi } from "./studentApi";
import PeopleAltIcon from "@mui/icons-material/PeopleAlt";
import { useNavigate } from "react-router-dom";
import NotifyStudent from "../notifications/NotifyStudent";

export default function ClassroomStudents() {
  const navigate = useNavigate();
  const { userInfo } = useSelector((state) => state.auth);
  const { currentClassroom } = useSelector((state) => state.dashboard);
  const [page, setPage] = useState(1);
  const [name, setName] = useState("");
  const [phone, setPhone] = useState("");
  const [students, setStudents] = useState([]);
  const [student, setStudent] = useState({});
  const [action, setAction] = useState("");
  const [loading, setLoading] = useState(true);
  const [totalStudents, setTotalStudents] = useState(0);
  const { fetchStudents, notifyStudent } = useStudentApi();
  const [selected, setSelected] = useState([]);
  const [loadingForm, setLoadingForm] = useState(false);
  const [resetNotif, setResetNotif] = useState(false);
  const [notification, setNotification] = useState({
    subject: "",
    message: "",
  });

  useEffect(() => {
    const loadStudents = async () => {
      setLoading(true);
      const data = await fetchStudents({
        id: userInfo.establishment._id,
        classroom: currentClassroom._id,
        page,
        phone,
        name,
      });
      setStudents(data.students);
      setTotalStudents(data.totalStudents);
      setLoading(false);
    };

    if (userInfo && currentClassroom) loadStudents();
  }, [currentClassroom, userInfo, page, name, phone]);

  return (
    <>
      <Dialog
        open={action === "NOTIFY"}
        onClose={() => setAction("")}
        fullWidth
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: "1em",
            padding: "2em",
          }}
        >
          <NotifyStudent
            loadingForm={loadingForm}
            student={student}
            notification={notification}
            selected={selected}
            setSelected={setSelected}
            setNotification={setNotification}
            setAction={setAction}
            resetNotif={resetNotif}
            onSubmit={notifyStudent}
          />
        </Box>
      </Dialog>

      <Menu />
      <Box maxWidth="100%" margin="auto">
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            flexWrap: "wrap",
          }}
        >
          <div
            style={{
              display: "flex",

              alignItems: "center",
              gap: "1em",
            }}
          >
            <PeopleAltIcon style={{ fontSize: "2.2rem", color: "#283487" }} />
            <h3 style={{ fontSize: "2rem", color: "#283487" }}>
              {" "}
              Liste des élèves
            </h3>
          </div>

          {totalStudents >= currentClassroom.capacity ? (
            <Typography
              variant="body1"
              sx={{
                color: "red",
                backgroundColor: "#ffe6e6",
                padding: "8px 16px",
                borderRadius: "4px",
                fontWeight: "bold",
                textAlign: "center",
                margin: "1em 0",
              }}
            >
              La classe est complète, veuillez augmenter la limite pour pouvoir
              ajouter des élèves !
            </Typography>
          ) : (
            <Button
              variant="contained"
              color="primary"
              onClick={() => navigate("/gestion-scolaire/eleves/add")}
            >
              Ajouter
            </Button>
          )}
        </div>

        <StudentFilter
          name={name}
          setName={setName}
          phone={phone}
          setPhone={setPhone}
        />
        {loading ? (
          <CircularProgress />
        ) : (
          <StudentTable
            currentClassroom={currentClassroom}
            students={students}
            setStudent={setStudent}
            setFormData={setStudent}
            setAction={setAction}
          />
        )}
      </Box>
    </>
  );
}
