import LocalPrintshopIcon from "@mui/icons-material/LocalPrintshop";
import { CircularProgress, Modal } from "@mui/material";
import axios from "axios";
import moment from "moment";
import { useSnackbar } from "notistack";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import ReactToPrint from "react-to-print";
import styles from "../../../styles/admin/StudentCard.module.css";
import AccountBoxIcon from "@mui/icons-material/AccountBox";
import Receipt from "../../../components/Receipt";
import {
  Avatar,
  Box,
  Card,
  CardContent,
  Grid,
  Typography,
} from "@mui/material";

function ProfileCard(props) {
  const { enqueueSnackbar } = useSnackbar();
  const printRef = useRef();
  const { userInfo } = useSelector((state) => state.auth);
  const { currentSeason } = useSelector((state) => state.dashboard);
  const [student, setStudent] = useState({});
  const [loadingCard, setLoadingCard] = useState(true);
  const [action, setAction] = useState("");
  const dispatch = useDispatch();
  const [deposit, setDeposit] = useState({
    establishment: userInfo?.establishment?._id,
    season: "",
    student: userInfo?._id,
    label: "",
    monthIndex: 0,
    amount: 0,
    method: "",
    multiple: 0,
  });

  const fetchStudent = async () => {
    const url =
      userInfo?.user?.role === "STUDENT"
        ? `/student/getbyid/${userInfo?._id}`
        : `/user/getbyid/${userInfo?.user._id}`;
    try {
      const { data } = await axios.get(url);
      setStudent(data);
      setLoadingCard(false);
    } catch (error) {
      enqueueSnackbar(error.message, { variant: "error" });
      setLoadingCard(false);
    }
  };

  const resetDeposit = () => {
    setDeposit({
      establishment: userInfo?.establishment?._id,
      season: currentSeason?._id,
      student: userInfo?._id,
      label: "",
      monthIndex: 0,
      amount: 0,
      method: "",
      multiple: 0,
    });
  };

  useEffect(() => {
    fetchStudent();
  }, [currentSeason]);

  const renderStudentProfile = () => (
    <section ref={printRef} className={styles.card}>
      <div className={`${styles.print} dontprint`}>
        <ReactToPrint
          trigger={() => (
            <LocalPrintshopIcon
              onClick={() => window.print()}
              style={{ cursor: "pointer" }}
              color="primary"
            />
          )}
          content={() => printRef.current}
        />
      </div>
      <div className={styles.row}>
        <AccountBoxIcon
          style={{ fontSize: "50px", marginRight: "10px", color: "#283487" }}
        />
        <h1 style={{ fontSize: "30px", fontWeight: "bold", color: "#283487" }}>
          Profil de l'élève
        </h1>
      </div>
      <div className={styles.row}>
        <div className={styles.col50}>
          <h1>
            Nom et prénom:&nbsp;<a>{student?.user?.name}</a>
          </h1>
          <h1>
            Date de naissance:&nbsp;<a>{student?.birthday}</a>
          </h1>
          <h1>
            Sexe:&nbsp;<a>{student?.sex}</a>
          </h1>
          <h1>
            Adresse:&nbsp;<a>{student?.address}</a>
          </h1>
        </div>
        <div className={styles.col50}>
          <h1>
            Classe:&nbsp;
            <a>{student?.classroom?.level + " " + student?.classroom?.group}</a>
          </h1>
          <h1>
            Inscrit le:&nbsp;
            <a>{moment(student?.createdAt).format("YYYY-MM-DD")}</a>
          </h1>
          <h1>
            Tarif scolarité par mois:&nbsp;<a>{student?.classroom?.costs} dt</a>
          </h1>
        </div>
      </div>
      <div className={styles.hr} />
      <div className={styles.row}>
        <div className={styles.col50}>
          <h1>
            Nom père:&nbsp;<a>{student?.fatherName}</a>
          </h1>
          <h1>
            Profession père:&nbsp;<a>{student?.fatherProfession}</a>
          </h1>
          <h1>
            Téléphone père:&nbsp;<a>{student?.fatherPhone}</a>
          </h1>
          <h1>
            Nom mère:&nbsp;<a>{student?.motherName}</a>
          </h1>
          <h1>
            Profession mère:&nbsp;<a>{student?.motherProfession}</a>
          </h1>
          <h1>
            Téléphone mère:&nbsp;<a>{student?.motherPhone}</a>
          </h1>
        </div>
        <div className={styles.col50}>
          <h1>
            Nb. frères et sœurs:&nbsp;<a>{student?.siblings}</a>
          </h1>
          <h1>
            Nb. frères et sœurs étudiants:&nbsp;
            <a>{student?.siblingsStudents}</a>
          </h1>
          <h1>
            Situation familiale:&nbsp;<a>{student?.maritalStatus}</a>
          </h1>
          <h1>
            Compagnie autorisée:&nbsp;
            {student?.authorizedCompany.map((name, i) => (
              <a key={i}>{name},&nbsp;</a>
            ))}
          </h1>
          <h1>
            Orphelin:&nbsp;<a>{student?.orphin ? "oui" : "non"}</a>
          </h1>
        </div>
      </div>
    </section>
  );

  const renderBasicProfile = () => (
    <Card
      ref={printRef}
      sx={{ maxWidth: 500, margin: "auto", padding: 3, boxShadow: 3 }}
    >
      <CardContent>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={12}>
            <Avatar
              alt="User Avatar"
              src="./icons/profile2.svg"
              sx={{ width: 100, height: 100 }}
            />
          </Grid>
          <Grid item xs={12} sm={12}>
            <Typography
              variant="h5"
              component="div"
              fontWeight="bold"
              gutterBottom
            >
              {student?.name}
            </Typography>
            <Typography variant="body1" color="text.secondary">
              {student?.email}
            </Typography>
          </Grid>

          <Grid item xs={12}>
            <Box sx={{ display: "flex", justifyContent: "space-between" }}>
              <Typography variant="body1">
                <strong>Rôle:</strong> {student?.role}
              </Typography>
            </Box>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );

  return (
    <>
      <Modal
        open={action !== ""}
        onClose={() => {
          setAction("");
          resetDeposit();
        }}
      >
        <div
          className={action === "SHOW_RECEIPT" ? "modal medium" : "modal small"}
        >
          {action === "SHOW_RECEIPT" && (
            <Receipt
              setAction={setAction}
              deposit={deposit}
              resetDeposit={resetDeposit}
              student={student}
            />
          )}
        </div>
      </Modal>
      <div className={styles.container}>
        {loadingCard ? (
          <div className="spinner">
            <CircularProgress size="30px" />
          </div>
        ) : userInfo?.user?.role === "STUDENT" ? (
          renderStudentProfile()
        ) : (
          renderBasicProfile()
        )}
      </div>
    </>
  );
}

export default ProfileCard;
