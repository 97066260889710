import React from "react";
import SubjectForm from "./SubjectForm";

function Add(props) {
  const {
    formData,
    setFormData,
    resetForm,
    setAction,
    addSubject,
    teachers,
    loadingForm,
  } = props;

  return (
    <SubjectForm
      formData={formData}
      setFormData={setFormData}
      setAction={setAction}
      resetForm={resetForm}
      handleSubmit={addSubject}
      teachers={teachers}
      loadingForm={loadingForm}
      title="Ajouter une matière"
    />
  );
}

export default Add;
