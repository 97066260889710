// Rooms.js
import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  Pagination,
} from "@mui/material";
import { useSelector } from "react-redux";
import { useSnackbar } from "notistack";
import { fetchRoomsAPI, addRoomAPI, editRoomAPI } from "../../../Api/roomsAPI";
import FilterRooms from "./Filter";
import RoomsTable from "./Table";
import AddRoom from "./AddRoom";
import EditRoom from "./EditRoom";
import MeetingRoomIcon from "@mui/icons-material/MeetingRoom";
const Rooms = () => {
  const { enqueueSnackbar } = useSnackbar();
  const { userInfo } = useSelector((state) => state.auth);
  const { currentSeason } = useSelector((state) => state.dashboard);

  const [page, setPage] = useState(1);
  const [count, setCount] = useState(1);
  const [type, setType] = useState("");
  const [spots, setSpots] = useState(0);
  const [rooms, setRooms] = useState([]);
  const [action, setAction] = useState("");
  const [room, setRoom] = useState(null);
  const [loading, setLoading] = useState(false);
  const [loadingForm, setLoadingForm] = useState(false);
  const [formData, setFormData] = useState({
    name: "",
    type: "",
    spots: "",
    available: true,
    establishment: userInfo?.establishment?._id,
  });

  const fetchRooms = async () => {
    setLoading(true);
    try {
      const data = await fetchRoomsAPI(
        userInfo?.establishment?._id,
        page,
        type,
        spots
      );
      setRooms(data.rooms);
      setCount(data.count);
    } catch (error) {
      enqueueSnackbar("Error fetching rooms", { variant: "error" });
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (userInfo && currentSeason) {
      fetchRooms();
    }
  }, [userInfo, page, type, spots, currentSeason]);

  const onEditRoom = (room) => {
    setRoom(room);
    setFormData(room);
    setAction("EDIT");
  };

  return (
    <Box maxWidth="100%" margin="auto">
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <div
          style={{
            display: "flex",
            alignItems: "center",
            gap: "1em",
          }}
        >
          <MeetingRoomIcon style={{ fontSize: "2.2rem", color: "#283487" }} />
          <h3 style={{ fontSize: "2rem", color: "#283487" }}>
            {" "}
            Liste des salles
          </h3>
        </div>
        <Button
          variant="contained"
          color="primary"
          onClick={() => setAction("ADD")}
        >
          Ajouter
        </Button>
      </div>

      <FilterRooms
        type={type}
        setType={setType}
        spots={spots}
        setSpots={setSpots}
      />

      {loading ? (
        <div className="spinner">
          <CircularProgress />
        </div>
      ) : (
        <RoomsTable rooms={rooms} onEdit={onEditRoom} />
      )}

      <Box margin="30px 0" display="flex" justifyContent="center">
        <Pagination
          count={count}
          color="primary"
          page={page}
          onChange={(_, value) => setPage(value)}
        />
      </Box>

      <Dialog open={action !== ""} onClose={() => setAction("")}>
        {action === "ADD" ? (
          <AddRoom
            formData={formData}
            setFormData={setFormData}
            setAction={setAction}
            addRoom={() => addRoomAPI(formData).then(fetchRooms)}
          />
        ) : action === "EDIT" ? (
          <EditRoom
            formData={formData}
            setFormData={setFormData}
            setAction={setAction}
            editRoom={() => editRoomAPI(formData).then(fetchRooms)}
          />
        ) : null}
      </Dialog>
    </Box>
  );
};

export default Rooms;
