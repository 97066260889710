import React from "react";
import { Dialog, CircularProgress } from "@mui/material";
import AddRow from "./AddRow";
import AddDeposit from "./AddDeposit";
import EditDeposit from "./EditDeposit";
import DeleteDeposit from "./DeleteDeposit";
import Receipt from "./Receipt";
import DepositTable from "./DepositTable";
import Card from "./Card";
import styles from "../../../styles/admin/StudentCard.module.css";
import { useLogic } from "./LogicDeposit";
import IconButton from "@mui/material/IconButton";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import PaymentsIcon from "@mui/icons-material/Payments";

function StudentCard() {
  const {
    student,
    deposits,
    loadingCard,
    loadingDeposits,
    loadingForm,
    label,
    action,
    payment,
    setAction,
    setLabel,
    setPayment,
    addRow,
    addPayment,
    editPayment,
    deletePayment,
    renderDeposits,
    resetPayment,
  } = useLogic();
  console.log("action", action);

  return (
    <>
      <Dialog
        open={action !== ""}
        onClose={() => {
          setAction("");
          setLabel("");
          resetPayment();
        }}
      >
        <div
          className={
            action === "SHOW_RECEIPT" ? "modal medium" : "modal medium"
          }
          style={{ maxHeight: "100%" }}
        >
          {action === "ADD_ROW" ? (
            <AddRow
              setAction={setAction}
              label={label}
              setLabel={setLabel}
              addRow={addRow}
              loadingForm={loadingForm}
              title="Ajouter un paiement"
            />
          ) : action === "ADD_DEPOSIT" ? (
            <AddDeposit
              setAction={setAction}
              payment={payment}
              setPayment={setPayment}
              resetPayment={resetPayment}
              addPayment={addPayment}
              loadingForm={loadingForm}
            />
          ) : action === "EDIT_DEPOSIT" ? (
            <EditDeposit
              setAction={setAction}
              payment={payment}
              setPayment={setPayment}
              resetPayment={resetPayment}
              editPayment={editPayment}
              loadingForm={loadingForm}
            />
          ) : action === "DELETE_DEPOSIT" ? (
            <DeleteDeposit
              setAction={setAction}
              payment={payment}
              setPayment={setPayment}
              resetPayment={resetPayment}
              deletePayment={deletePayment}
              loadingForm={loadingForm}
            />
          ) : action === "SHOW_RECEIPT" ? (
            <Receipt
              setAction={setAction}
              payment={payment}
              resetPayment={resetPayment}
              student={student}
            />
          ) : null}
        </div>
      </Dialog>
      <>
        <div className={styles.container}>
          {loadingCard ? (
            <div className="spinner">
              <CircularProgress size="30px" />
            </div>
          ) : (
            <Card student={student} />
          )}
          <div
            style={{
              display: "flex",
              alignItems: "center",
              marginBottom: "1em",
              gap: "1em",
            }}
          >
            <PaymentsIcon style={{ fontSize: "2.2rem", color: "#283487" }} />
            <h3 style={{ fontSize: "2rem", color: "#283487" }}>Paiements</h3>
          </div>
          {loadingDeposits ? (
            <div className="spinner">
              <CircularProgress size="30px" />
            </div>
          ) : (
            <>
              <DepositTable
                deposits={deposits}
                setAction={setAction}
                setPayment={setPayment}
                renderDeposits={renderDeposits}
              />
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  marginTop: "1em",
                  alignItems: "center",
                }}
              >
                <IconButton
                  color="primary"
                  onClick={() => setAction("ADD_ROW")}
                >
                  <AddCircleOutlineIcon />
                </IconButton>
              </div>
            </>
          )}
        </div>
      </>
    </>
  );
}

export default StudentCard;
