import React, { useState } from "react";
import FormComponent from "./FormComponent";

function AddStudent(props) {
  const {
    establishment,
    classrooms,
    addStudent,
    setAction,
    formData,
    setFormData,
  } = props;

  const [loadingForm, setLoadingForm] = useState(false);

  return (
    <FormComponent
      formData={formData}
      setFormData={setFormData}
      loadingForm={loadingForm}
      handleSubmit={addStudent}
      title="Ajouter un étudiant"
      buttonText="Ajouter"
      establishment={establishment}
      classrooms={classrooms}
      setAction={setAction}
    />
  );
}

export default AddStudent;
