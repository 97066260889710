import React from "react";
import { IconButton, Tooltip, TableRow, TableCell } from "@mui/material";
import BlockIcon from "@mui/icons-material/Block";
import CheckIcon from "@mui/icons-material/Check";
import SettingsIcon from "@mui/icons-material/Settings";

const StudentRow = ({ student, setStudent, setFormData, setAction }) => {
  console.log("student", student);

  const handleEdit = () => {
    setStudent(student);
    setFormData({
      ...student,
      ...student.user,
      _id: student._id,
      classroom: student.classroom?._id,
    });
    setAction("EDIT");
  };

  const handleToggleActive = () => {
    setStudent(student);
    student.isActive ? setAction("BLOCK") : setAction("ACTIVATE");
  };

  return (
    <TableRow>
      <TableCell>{student.user.name}</TableCell>
      <TableCell>{student.user.email}</TableCell>
      <TableCell>{student.user.phone}</TableCell>
      <TableCell>
        {student.classroom
          ? `${student.classroom.level} ${student.classroom.group}`
          : "Non Attribué"}
      </TableCell>
      <TableCell>
        <Tooltip title="modifier">
          <IconButton
            onClick={handleEdit}
            sx={{ color: "#283487" }}
            color="primary"
          >
            <SettingsIcon />
          </IconButton>
        </Tooltip>
        <Tooltip title={student.isActive ? "bloquer" : "activer"}>
          <IconButton onClick={handleToggleActive}>
            {student.isActive ? (
              <BlockIcon sx={{ color: "red" }} />
            ) : (
              <CheckIcon sx={{ color: "green" }} />
            )}
          </IconButton>
        </Tooltip>
      </TableCell>
    </TableRow>
  );
};

export default StudentRow;
