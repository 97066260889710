import axios from "axios";
import { useSnackbar } from "notistack";
import { getError } from "../../../config/config";

export const useStudentApi = () => {
  const { enqueueSnackbar } = useSnackbar();

  const fetchStudents = async (filters) => {
    try {
      const { data } = await axios.post("/student/getbyclassroom", filters);
      return data;
    } catch (error) {
      enqueueSnackbar(getError(error), { variant: "error" });
      throw error;
    }
  };

  const addStudent = async (formData) => {
    try {
      const { data } = await axios.post("/student/add", formData);
      enqueueSnackbar(data.message, { variant: "success" });
      return data;
    } catch (error) {
      enqueueSnackbar(getError(error), { variant: "error" });
      throw error;
    }
  };

  const editStudent = async (formData) => {
    try {
      const { data } = await axios.put("/student/edit", formData);
      enqueueSnackbar(data.message, { variant: "success" });
      return data;
    } catch (error) {
      enqueueSnackbar(getError(error), { variant: "error" });
      throw error;
    }
  };

  const notifyStudent = async (notification) => {
    try {
      const { data } = await axios.post("/notification/send", notification);
      enqueueSnackbar(data.message, { variant: "success" });
      return data;
    } catch (error) {
      enqueueSnackbar(getError(error), { variant: "error" });
      throw error;
    }
  };

  return { fetchStudents, addStudent, editStudent, notifyStudent };
};
