import React from "react";
import { Button, CircularProgress } from "@mui/material";

function TeacherAction({
  loadingForm,
  setAction,
  actionHandler,
  teacher,
  actionType,
}) {
  const isActivate = actionType === "ACTIVATE";

  return (
    <form onSubmit={actionHandler}>
      <h1>
        {isActivate ? "Activer l'enseignant" : "Bloquer l'enseignant"}:&nbsp;
        <span
          style={{
            textTransform: "capitalize",
          }}
        >
          {teacher.user.name}
        </span>
      </h1>
      <div className="row">
        {loadingForm ? (
          <CircularProgress size="35px" />
        ) : (
          <>
            <Button type="submit" variant="contained">
              {isActivate ? "Activer" : "Bloquer"}
            </Button>
            &nbsp;
            <Button
              type="button"
              onClick={() => setAction("")}
              variant="outlined"
            >
              Annuler
            </Button>
          </>
        )}
      </div>
    </form>
  );
}

export default TeacherAction;
