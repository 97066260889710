import React from "react";
import { Button, CircularProgress, Typography, Box } from "@mui/material";

function DeleteNote(props) {
  const { deleteNote, setAction, note, setNote, loadingForm } = props;

  return (
    <form onSubmit={deleteNote}>
      <Typography variant="h6">Supprimer la note ({note.type}) ?</Typography>
      <Box mt={2}>
        {loadingForm ? (
          <CircularProgress size={32} />
        ) : (
          <Box display="flex" gap={2}>
            <Button variant="contained" color="error" type="submit">
              Supprimer
            </Button>
            <Button
              variant="outlined"
              onClick={() => {
                setAction("");
                setNote({});
              }}
            >
              Annuler
            </Button>
          </Box>
        )}
      </Box>
    </form>
  );
}

export default DeleteNote;
