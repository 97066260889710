import { Button, Box, Typography, Divider } from "@mui/material";
import React, { useRef } from "react";
import { useSelector } from "react-redux";
import moment from "moment";
import { getMonth } from "../../../config/config";
import ReactToPrint from "react-to-print";

function Receipt(props) {
  const printRef = useRef();
  const { setAction, resetWithdrawal, withdrawal, teacher } = props;
  const { currentSeason } = useSelector((state) => state.dashboard);
  const { userInfo } = useSelector((state) => state.auth);

  return (
    <Box
      ref={printRef}
      p={3}
      sx={{
        width: "100%",
        maxWidth: "600px",
        margin: "0 auto",
        borderRadius: "8px",

        "& .receipt-header": {
          marginBottom: 3,
          borderBottom: "2px solid #EBE9E9",
          paddingBottom: 2,
        },
        "& .receipt-title": {
          textAlign: "center",
          marginBottom: 3,
          color: "black",
          fontWeight: "bold",
        },
        "& .divider": {
          my: 3,
          borderColor: "#EBE9E9",
        },
        "& .receipt-footer": {
          textAlign: "right",
          marginTop: 3,
          borderTop: "2px solid #EBE9E9",
          paddingTop: 2,
        },
        "& .btn-row": {
          display: "flex",
          justifyContent: "flex-end",
          gap: 1,
          marginTop: 3,
        },
        "& .dontprint": {
          display: "none",
        },
      }}
    >
      <Box className="receipt-header">
        <Typography
          variant="h4"
          gutterBottom
          sx={{
            textAlign: "center",
            fontWeight: "bold",
            marginBottom: 3,
          }}
        >
          {userInfo.establishment.type} {userInfo.establishment.name}
        </Typography>
        <Typography variant="h6">
          Adresse: {userInfo.establishment.address}
        </Typography>
        <Typography variant="h6">
          Email: {userInfo.establishment.email}
        </Typography>
        <Typography variant="h6">
          Téléphone: {userInfo.establishment.phone}
        </Typography>
        <Typography variant="h6">
          Année scolaire: {currentSeason.year}
        </Typography>
        <Typography variant="h6">
          Agent: {withdrawal.admin.user.name}
        </Typography>
      </Box>

      <Typography className="receipt-title" variant="h4">
        {withdrawal.label} pour le mois {getMonth(withdrawal.monthIndex)}
      </Typography>

      <Typography variant="h6">Enseignant: {teacher.user.name}</Typography>
      <Typography variant="h6">Montant: {withdrawal.amount} DT</Typography>
      <Typography variant="h6">
        Modalité de paiement: {withdrawal.method}
      </Typography>
      <Typography variant="h6">
        Date de paiement: {moment(withdrawal.createdAt).format("DD-MM-yyyy")}
      </Typography>

      <Box className="receipt-footer">
        <Typography variant="h6">Bénéficiaire: {teacher.user.name}</Typography>
        <Typography variant="h6">Signature: ___________________</Typography>
        <Typography variant="h6">
          {userInfo.establishment.address} le {moment().format("DD-MM-yyyy")}
        </Typography>
      </Box>

      <Box className="btn-row">
        <ReactToPrint
          trigger={() => <Button variant="contained">Imprimer</Button>}
          content={() => printRef.current}
        />
        <Button
          onClick={() => {
            resetWithdrawal();
            setAction("");
          }}
          variant="outlined"
        >
          Annuler
        </Button>
      </Box>
    </Box>
  );
}

export default Receipt;
