import {
  Autocomplete,
  Button,
  CircularProgress,
  TextField,
  Grid,
  Box,
  Typography,
} from "@mui/material";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";
import moment from "moment";
import React from "react";

function AddExam(props) {
  const { addExam, setAction, exam, setExam, rooms, subjects, loadingForm } =
    props;

  return (
    <Box component="form" onSubmit={addExam} sx={{ padding: 2 }}>
      <Typography variant="h4" gutterBottom>
        Ajouter un examen
      </Typography>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6}>
          <Typography variant="body1" gutterBottom>
            Heure de début
          </Typography>
          <TimePicker
            inputFormat="HH:mm"
            ampm={false}
            value={exam.start}
            onChange={(val) =>
              setExam({ ...exam, start: moment(val, "HH:mm") })
            }
            renderInput={(params) => (
              <TextField
                required
                size="small"
                sx={{
                  width: "100%",
                  "& .MuiOutlinedInput-notchedOutline": {
                    borderRadius: "5px",
                    border: "1px solid #1d255e",
                  },
                }}
                {...params}
              />
            )}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <Typography variant="body1" gutterBottom>
            Heure de fin
          </Typography>
          <TimePicker
            inputFormat="HH:mm"
            ampm={false}
            value={exam.end}
            onChange={(val) => setExam({ ...exam, end: moment(val, "HH:mm") })}
            renderInput={(params) => (
              <TextField
                required
                size="small"
                sx={{
                  width: "100%",
                  "& .MuiOutlinedInput-notchedOutline": {
                    borderRadius: "5px",
                    border: "1px solid #1d255e",
                  },
                }}
                {...params}
              />
            )}
          />
        </Grid>
        <Grid item xs={12}>
          <Typography variant="body1" gutterBottom>
            Matière
          </Typography>
          <Autocomplete
            size="small"
            sx={{ width: "100%" }}
            value={subjects.find((s) => s._id === exam.subject)}
            options={subjects.map((s) => ({ ...s, label: s.name }))}
            onChange={(e, val) =>
              setExam({ ...exam, subject: val ? val._id : "" })
            }
            renderInput={(params) => (
              <TextField
                size="small"
                sx={{
                  width: "100%",
                  "& .MuiOutlinedInput-notchedOutline": {
                    borderRadius: "5px",
                    border: "1px solid #283487",
                  },
                }}
                {...params}
                required
              />
            )}
          />
        </Grid>
        <Grid item xs={12}>
          <Typography variant="body1" gutterBottom>
            Salle
          </Typography>
          <Autocomplete
            size="small"
            sx={{ width: "100%" }}
            value={rooms.find((r) => r._id === exam.room)}
            options={rooms.map((r) => ({ ...r, label: r.name }))}
            onChange={(e, val) =>
              setExam({ ...exam, room: val ? val._id : "" })
            }
            renderInput={(params) => (
              <TextField
                size="small"
                sx={{
                  width: "100%",
                  "& .MuiOutlinedInput-notchedOutline": {
                    borderRadius: "5px",
                    border: "1px solid #283487",
                  },
                }}
                {...params}
                required
              />
            )}
          />
        </Grid>
        <Grid item xs={12}>
          {loadingForm ? (
            <CircularProgress size="32px" />
          ) : (
            <Box sx={{ display: "flex", gap: 2 }}>
              <Button variant="contained" type="submit">
                Ajouter
              </Button>
              <Button
                variant="outlined"
                type="button"
                onClick={() => {
                  setAction("");
                }}
              >
                Annuler
              </Button>
            </Box>
          )}
        </Grid>
      </Grid>
    </Box>
  );
}

export default AddExam;
