import React from "react";
import {
  IconButton,
  Tooltip,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Paper,
} from "@mui/material";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import moment from "moment";

import SettingsIcon from "@mui/icons-material/Settings";

const ExamTable = ({
  exams,
  setAction,
  setExam,

  currentClassroom,
  exam,
  printRef,
  userInfo,
}) => (
  <TableContainer component={Paper} ref={printRef} style={{ padding: "2em" }}>
    <Table>
      <TableHead>
        <TableRow>
          <TableCell
            style={{
              position: "relative",
              fontWeight: "bold",
              backgroundColor: "#f5f5f5",
            }}
          >
            Date
          </TableCell>
          <TableCell style={{ fontWeight: "bold" }}>Examens</TableCell>
          {userInfo.user.role === "ADMIN" ||
          userInfo.user.role === "SUPER-ADMIN" ? (
            <>
              <TableCell style={{ fontWeight: "bold" }}>Actions</TableCell>
            </>
          ) : null}
        </TableRow>
      </TableHead>
      <TableBody>
        {exams.map((row) => (
          <TableRow key={row.date}>
            <TableCell
              style={{
                width: "20%",
                backgroundColor: "#F5F3F3",
                color: "#333 !important",
                padding: "10px",
                verticalAlign: "middle",
                textAlign: "center",
              }}
            >
              <p>{moment(row.date).format("dddd")}</p>
              <p>{moment(row.date).format("DD-MM-yyyy")}</p>
            </TableCell>
            <TableCell
              style={{
                width: "80%",
                backgroundColor: "#fff",
                padding: "10px",
                border: "1px solid #e0e0e0",
              }}
            >
              {row.exams.map((ex, i) => (
                <div
                  key={i}
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    padding: "5px",
                  }}
                >
                  <div
                    style={{
                      flex: "1",
                      padding: "2em",
                      textAlign: "center",
                      color: "#fff",
                      fontWeight: "bold",
                      marginRight: "2em",
                      backgroundColor: "#283487",
                    }}
                  >
                    {ex.timing || "Non défini"}
                  </div>
                  <div
                    style={{
                      flex: "1",
                      padding: "1.5em",
                      textAlign: "center",
                      color: "#fff",
                      backgroundColor: "#283487",
                    }}
                  >
                    <p style={{ margin: 0 }}>
                      Matière: {ex.subject?.name || "Non défini"}
                    </p>
                    <p style={{ margin: 0 }}>
                      Salle: {ex.room?.name || "Non défini"}
                    </p>
                  </div>
                </div>
              ))}
              {userInfo.user.role === "ADMIN" ||
              userInfo.user.role === "SUPER-ADMIN" ? (
                <div>
                  <Tooltip title="Ajouter un examen" sx={{ color: "green" }}>
                    <IconButton
                      size="small"
                      onClick={() => {
                        setExam({
                          ...exam,
                          date: row.date,
                          classroom: currentClassroom?._id,
                        });
                        setAction("ADD_EXAM");
                      }}
                    >
                      <AddCircleOutlineIcon />
                    </IconButton>
                  </Tooltip>
                </div>
              ) : null}
            </TableCell>
            {userInfo.user.role === "ADMIN" ||
            userInfo.user.role === "SUPER-ADMIN" ? (
              <>
                {/* <TableCell
                  style={{
                    width: "10%",
                    backgroundColor: "#fff",
                    padding: "10px",
                    border: "1px solid #e0e0e0",
                  }}
                >
                  <Chip
                    label={
                      row.exams.every((ex) => ex.isPublished)
                        ? "Publié"
                        : "Non publié"
                    }
                    color={
                      row.exams.every((ex) => ex.isPublished)
                        ? "primary"
                        : "secondary"
                    }
                  />
                </TableCell> */}

                <TableCell
                  style={{
                    width: "20%",
                    backgroundColor: "#fff",
                    padding: "10px",
                    border: "1px solid #e0e0e0",
                  }}
                >
                  {row.exams.map((ex, i) => (
                    <div key={i} style={{ display: "flex", gap: "10px" }}>
                      <Tooltip title="Modifier l'examen" color="secondary">
                        <IconButton
                          size="small"
                          onClick={() => {
                            setExam({
                              ...ex,
                              start: moment(ex.timing.split(" - ")[0], "hh:mm"),
                              end: moment(ex.timing.split(" - ")[1], "hh:mm"),
                            });
                            setAction("EDIT_EXAM");
                          }}
                          color="primary"
                        >
                          <SettingsIcon />
                        </IconButton>
                      </Tooltip>

                      <Tooltip title="Supprimer l'examen" sx={{ color: "red" }}>
                        <IconButton
                          size="small"
                          onClick={() => {
                            setExam(ex);
                            setAction("DELETE_EXAM");
                          }}
                        >
                          <DeleteOutlineIcon />
                        </IconButton>
                      </Tooltip>
                    </div>
                  ))}
                </TableCell>
              </>
            ) : null}
          </TableRow>
        ))}
      </TableBody>
    </Table>
  </TableContainer>
);

export default ExamTable;
