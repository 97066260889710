import { Button, CircularProgress, TextField, Box } from "@mui/material";
import React from "react";

function AddRow(props) {
  const { addRow, setAction, label, setLabel, loadingForm, title } = props;

  return (
    <>
      <form onSubmit={addRow}>
        <Box mb={2}>
          <TextField
            label="Titre de paiement"
            variant="outlined"
            fullWidth
            required
            value={label}
            onChange={(e) => setLabel(e.target.value)}
          />
        </Box>
        <Box display="flex" gap={1} mt={2}>
          {loadingForm ? (
            <CircularProgress size="33px" />
          ) : (
            <>
              <Button type="submit" variant="contained" color="primary">
                Ajouter
              </Button>
              <Button
                type="button"
                variant="outlined"
                color="primary"
                onClick={() => {
                  setAction("");
                  setLabel("");
                }}
              >
                Annuler
              </Button>
            </>
          )}
        </Box>
      </form>
    </>
  );
}

export default AddRow;
