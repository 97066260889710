import React, { useEffect, useState } from "react";
import {
  IconButton,
  Tooltip,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  CircularProgress,
  Pagination,
} from "@mui/material";
import { useSnackbar } from "notistack";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import DownloadIcon from "@mui/icons-material/Download";
import NotificationsActiveIcon from "@mui/icons-material/NotificationsActive";
import moment from "moment";
import { getError } from "../../../config/config";

const ROLES = {
  ADMIN: "ADMIN",
  SUPER_ADMIN: "SUPER-ADMIN",
};

const StudentNotifications = () => {
  const [page, setPage] = useState(1);
  const [count, setCount] = useState(1);
  const [notifications, setNotifications] = useState([]);
  const { userInfo } = useSelector((state) => state.auth);
  const [loadingNotif, setLoadingNotif] = useState(true);
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();

  const fetchNotifications = async () => {
    const role = userInfo?.user?.role;
    const establishmentId = userInfo?.establishment?._id;
    const userId = userInfo?.user?._id;
    const api =
      role === ROLES.ADMIN || role === ROLES.SUPER_ADMIN
        ? `/notification/get/sent/${establishmentId}`
        : `/notification/get/${userId}`;

    try {
      const { data } = await axios.get(api);
      dispatch({ type: "UPDATE_NOTIFICATIONS", payload: data.count });
      setNotifications(data.notifications);
    } catch (error) {
      enqueueSnackbar(getError(error), { variant: "error" });
    } finally {
      setLoadingNotif(false);
    }
  };

  const download = (notification) => {
    const [type, file] = notification.documents[0].split(",");
    const linkSource = `${type},${file}`;
    const downloadLink = document.createElement("a");
    downloadLink.href = linkSource;
    downloadLink.download = file;
    downloadLink.click();
  };

  useEffect(() => {
    fetchNotifications();
  }, [userInfo]);

  const isUserAdminOrSuperAdmin =
    userInfo?.user?.role === ROLES.ADMIN ||
    userInfo?.user?.role === ROLES.SUPER_ADMIN;

  const headers = [
    "Date",
    ...(isUserAdminOrSuperAdmin ? ["Expéditeur", "Destinataire"] : []),
    "Sujet",
    "Message",
    "Documents",
  ];

  return (
    <div style={{ padding: "1em" }}>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          marginBottom: "1em",
          gap: "1em",
        }}
      >
        <NotificationsActiveIcon
          style={{ fontSize: "2.2rem", color: "#283487" }}
        />
        <h3 style={{ fontSize: "2rem", color: "#283487" }}>Notifications</h3>
      </div>

      {loadingNotif ? (
        <div className="spinner">
          <CircularProgress size="30px" />
        </div>
      ) : (
        <TableContainer
          component={Paper}
          style={{
            maxWidth: "100%",
            overflowX: "auto",
            backgroundColor: "#fff",
          }}
        >
          <Table>
            <TableHead>
              <TableRow>
                {headers.map((header, index) => (
                  <TableCell
                    key={index}
                    style={{
                      backgroundColor: "#283487",
                      color: "#fff",
                      fontWeight: "bold",
                    }}
                  >
                    {header}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {notifications.length > 0 ? (
                notifications.map((row, index) => (
                  <TableRow key={index}>
                    <TableCell>
                      {moment(row?.createdAt).format("DD-MM-yyyy")}
                    </TableCell>

                    {isUserAdminOrSuperAdmin && (
                      <>
                        <TableCell>{row?.sender?.name}</TableCell>
                        <TableCell>{row?.receiver?.name}</TableCell>
                      </>
                    )}

                    <TableCell>
                      {row?.subject ? row?.subject : "Aucun sujet"}
                    </TableCell>
                    <TableCell>{row?.message}</TableCell>
                    <TableCell>
                      {row?.documents.length > 0 ? (
                        <>
                          {row.documents[0].split(",")[0].includes("pdf") ? (
                            <>
                              <span>Télécharger le PDF</span>
                              <Tooltip title="Télécharger le PDF">
                                <IconButton
                                  onClick={() => download(row)}
                                  style={{ color: "#1E90FF" }}
                                >
                                  <DownloadIcon />
                                </IconButton>
                              </Tooltip>
                            </>
                          ) : row.documents[0]
                              .split(",")[0]
                              .includes("image") ? (
                            <>
                              <span>Télécharger l'image</span>
                              <Tooltip title="Télécharger l'image">
                                <IconButton
                                  onClick={() => download(row)}
                                  style={{ color: "#1E90FF" }}
                                >
                                  <DownloadIcon />
                                </IconButton>
                              </Tooltip>
                            </>
                          ) : (
                            "_"
                          )}
                        </>
                      ) : (
                        "_"
                      )}
                    </TableCell>
                  </TableRow>
                ))
              ) : (
                <TableRow>
                  <TableCell
                    colSpan={headers.length}
                    style={{ textAlign: "center" }}
                  >
                    Aucune notification
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      )}
      <section
        style={{
          display: "flex",
          justifyContent: "center",
          marginTop: "1em",
        }}
      >
        <Pagination
          onChange={(e, page) => setPage(page)}
          page={page}
          count={count}
          color="primary"
        />
      </section>
    </div>
  );
};

export default StudentNotifications;
