import { Button, CircularProgress, TextField, Box } from "@mui/material";
import React from "react";

function EditDeposit(props) {
  const {
    editPayment,
    setAction,
    payment,
    setPayment,
    resetPayment,
    loadingForm,
  } = props;

  return (
    <form onSubmit={editPayment}>
      <Box mb={2}>
        <TextField
          label="Montant"
          type="number"
          step="any"
          min={1}
          required
          fullWidth
          value={payment?.amount}
          onChange={(e) => setPayment({ ...payment, amount: e.target.value })}
        />
      </Box>
      <Box mb={2}>
        <TextField
          label="Modalité de paiement"
          type="text"
          required
          fullWidth
          value={payment?.method}
          onChange={(e) => setPayment({ ...payment, method: e.target.value })}
        />
      </Box>
      {loadingForm ? (
        <CircularProgress size="33px" />
      ) : (
        <Box display="flex" justifyContent="space-between" mt={2}>
          <Button type="submit" variant="contained">
            Modifier
          </Button>
          <Button
            type="button"
            variant="outlined"
            onClick={() => {
              setAction("");
              resetPayment();
            }}
          >
            Annuler
          </Button>
        </Box>
      )}
    </form>
  );
}

export default EditDeposit;
