import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import PaymentsIcon from "@mui/icons-material/Payments";
import { Skeleton, Typography } from "@mui/material";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import styles from "../../../../styles/admin/Wallet.module.css";

function Wallet(props) {
  const [wallet, setWallet] = useState({ deposits: 0, withdrawals: 0 });
  const [loading, setLoading] = useState(true);
  const { userInfo } = useSelector((state) => state.auth);
  const { currentSeason } = useSelector((state) => state.dashboard);
  const { withdrawals, fournitures } = props.refresh;

  const fetchWalletData = async () => {
    const { data } = await axios.get(
      `/stats/wallet/${userInfo.establishment._id}/${currentSeason._id}`
    );
    setWallet(data);
    setLoading(false);
  };

  useEffect(() => {
    fetchWalletData();
  }, [currentSeason, withdrawals, fournitures]);

  return (
    <>
      {loading ? (
        <div className="spinner">
          <Skeleton
            variant="rectangular"
            sx={{ borderRadius: "15px" }}
            width="100%"
            height="260px"
          />
        </div>
      ) : (
        <div className={styles.wallet}>
          <PaymentsIcon
            sx={{
              color: "#283487",
              width: "100px",
              height: "100px",
              position: "absolute",
              top: "20px",
              right: "20px",
            }}
          />
          <div className={styles.row}>
            <h1>Solde portfeuille:</h1>&nbsp;&nbsp;
            <h1 style={{ fontWeight: "500" }}>
              {wallet.deposits - (wallet.fournitures + wallet.autres)}&nbsp;DT
            </h1>
            {wallet.deposits - (wallet.fournitures + wallet.autres) > 0 ? (
              <ArrowDropUpIcon
                style={{
                  color: "green",
                  width: "30px",
                  height: "30px",
                  marginTop: "-20px",
                }}
              />
            ) : (
              <ArrowDropDownIcon
                style={{
                  color: "red",
                  width: "30px",
                  height: "30px",
                  marginTop: "-20px",
                }}
              />
            )}
          </div>
          <div className={styles.row}>
            <Typography
              style={{ fontSize: "1rem", color: "#283487", fontWeight: "400" }}
            >
              Encaissement:&nbsp; <span style={{ color: "#008000" }}>+</span>
              {wallet.deposits}
              &nbsp;DT
            </Typography>
          </div>
          <div className={styles.row}>
            <Typography
              style={{ fontSize: "1rem", color: "#283487", fontWeight: "400" }}
            >
              Décaissement:&nbsp;
              <span style={{ color: "rgb(255,0,0)" }}>-</span>{" "}
              {wallet.fournitures + wallet.autres}&nbsp;DT
            </Typography>

            <Typography
              style={{ fontSize: "1rem", color: "#283487", fontWeight: "400" }}
            >
              ( Fourniture:&nbsp; {wallet.fournitures}&nbsp;DT
            </Typography>
            <Typography
              style={{ fontSize: "1rem", color: "#283487", fontWeight: "400" }}
            >
              / autres:&nbsp; {wallet.autres}&nbsp;DT)
            </Typography>
          </div>
          <br />
          <hr />
          <div className={styles.row}>
            <Typography style={{ fontSize: "1rem", color: "#283487" }}>
              Ce Mois:
            </Typography>
            &nbsp;&nbsp;
            <Typography style={{ fontSize: "1rem", color: "#283487" }}>
              Encaissement:&nbsp;
            </Typography>
            <Typography style={{ fontSize: "1rem", color: "#283487" }}>
              {wallet.thisMonthDeposits}&nbsp;DT
            </Typography>
            <Typography style={{ fontSize: "1rem", color: "#283487" }}>
              &nbsp;/&nbsp;
            </Typography>
            <Typography style={{ fontSize: "1rem", color: "#283487" }}>
              Décaissement:&nbsp;
            </Typography>
            <Typography style={{ fontSize: "1rem", color: "#283487" }}>
              {wallet.thisMonthWithdrawals}&nbsp;DT
            </Typography>
          </div>
        </div>
      )}
    </>
  );
}

export default Wallet;
